import * as yup from 'yup'
import { Box, Typography } from '@mui/material'
import { UpdateYourInformationBlock } from './UpdateYourInformationBlock'
import { TextFieldQuestions } from './constructors/TextFieldQuestions'
import { ChangeCredentials } from './constructors/ChangeCredentials'
import { TextFieldRadioQuestions } from './constructors/TextFieldRadioQuestions'
import { RadioFieldQuestions } from './constructors/RadioFieldQuestions'
import { RadioFieldNewQuestions } from './constructors/RadioFieldNewQuestions'
import { TextNumberFieldQuestions } from './constructors/TextNumberFieldQuestions'
import { CheckBoxQuestion } from './constructors/CheckBoxQuestion'
import { RadioTextFieldQuestions } from './constructors/RadioTextFieldQuestions'
import { RadioFieldCoborrowerQuestions } from './constructors/RadioFieldCoborrowerQuestions'

interface IUpdateLinks {
  questions?: any
  type?: string
}

const QE1 = {
  label: 'Your Login Credentials',
  current: 'QE1',
  inputs: [
    {
      value: 'password',
      text: 'Password'
    }
  ],
  changeCredentials: true,
  constructor: ChangeCredentials,
  validation: {
    password: yup.string().required('')
  }
}

const QE2 = {
  label: 'Your Contact Information',
  popupLabel: 'Who do I have the pleasure of speaking with?',
  current: 'QE2',
  inputs: [
    {
      value: 'firstName',
      text: 'First Name'
    },
    {
      value: 'lastName',
      text: 'Last Name'
    },
    {
      value: 'emailAddress',
      text: 'Email'
    },
    {
      value: 'cellPhone',
      text: 'Mobile',
      mask: true
    }
  ],
  constructor: TextFieldQuestions,
  validation: {
    firstName: yup.string().required(''),

    lastName: yup.string().required(''),

    cellPhone: yup
      .string()
      .typeError('')
      .matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        'Please enter a valid phone number'
      )
      .required(''),

    emailAddress: yup
      .string()
      .email('Please enter a valid email address')
      .required('')
  }
}

const QE3 = {
  label: 'Your Target Move Date',
  popupLabel: 'When would you like to be in your new home?',
  current: 'QE3',
  inputs: [
    {
      value: 'moveDate',
      text: 'Enter date'
    }
  ],
  formName: 'moveRange',
  answers: [
    {
      value: '1',
      text: '1 – 3 Months'
    },
    {
      value: '2',
      text: '4 – 6 Months'
    },
    {
      value: '3',
      text: '7 – 9 Months'
    },
    {
      value: '4',
      text: '9 + Months'
    }
  ],
  constructor: TextFieldRadioQuestions
}

const QE4 = {
  label: 'Co Borrower data',
  popupLabel: 'Who will be on the loan with you?',
  current: 'QE4',
  inputs: [
    {
      value: 'coFirstName',
      text: 'First Name'
    },
    {
      value: 'coLastName',
      text: 'Last Name'
    }
  ],
  constructor: TextFieldQuestions,
  validation: {
    coFirstName: yup.string().required(''),
    coLastName: yup.string().required('')
  }
}

const QE5 = {
  label: 'Marital Status',
  popupLabel: 'In order to determine what types of loans you may qualify for, we`ll need to know your marital status',
  current: 'QE5',
  formName: 'maritalStatus',
  answers: [
    {
      value: '1',
      text: 'Married'
    },
    {
      value: '0',
      text: 'Not married'
    },
    {
      value: '3',
      text: 'Separated'
    }
  ],
  constructor: RadioFieldNewQuestions,
  validation: {
    maritalStatus: yup
      .string()
      .default('')
      .test('maritalStatus', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required('')
  }
}

const QE6 = {
  label: 'Monthly Mortgage Payment',
  popupLabel: 'What is the maximum you want to spend monthly on your mortgage payment?',
  current: 'QE6',
  inputs: [
    {
      value: 'monthlyPaymentAmount',
      text: 'Maximum Monthly Payment '
    }
  ],
  constructor: TextNumberFieldQuestions,
  validation: {
    monthlyPaymentAmount: yup
      .string()
      .required('')
      .nullable()
  }
}

const QE7 = {
  label: 'Available Funds',
  popupLabel: 'How much money will you have available to purchase your new home?',
  current: 'QE7',
  inputs: [
    {
      value: 'homeSavings',
      text: 'Enter Amount Available '
    }
  ],
  constructor: TextNumberFieldQuestions,
  validation: {
    homeSavings: yup
      .string()
      .required('')
      .nullable()
  }
}

const QE8 = {
  label: 'Monthly Income',
  popupLabel: 'What is your total monthly income from all sources before any taxes or deductions?',
  current: 'QE8',
  inputs: [
    {
      value: 'monthlyIncome',
      text: 'Enter Total Monthly Income'
    }
  ],
  coInputs: [
    {
      value: 'coMonthlyIncome',
      text: 'Enter Total Monthly Income'
    }
  ],
  constructor: TextNumberFieldQuestions,
  validation: {
    monthlyIncome: yup
      .string()
      .nullable()
      .required('')
  }
}

const QE9 = {
  label: 'Monthly Expenses',
  popupLabel: 'Now let’s look at your expenses. Enter your monthly payment for any that apply...?',
  current: 'QE9',
  inputs: [
    {
      value: 'autoExpense',
      text: 'Auto Payments'
    },
    {
      value: 'creditCardExpense',
      text: 'Credit Cards'
    },
    {
      value: 'studentLoans',
      text: 'Student Loans'
    },
    {
      value: 'otherExpenses',
      text: 'Any Other'
    }
  ],
  coInputs: [
    {
      value: 'coAutoExpense',
      text: 'Auto Payments'
    },
    {
      value: 'coCreditCardExpense',
      text: 'Credit Cards'
    },
    {
      value: 'coStudentLoans',
      text: 'Student Loans'
    },
    {
      value: 'coOtherExpenses',
      text: 'Any Other'
    }
  ],
  constructor: TextNumberFieldQuestions
}

const QE10 = {
  label: 'Employment History',
  popupLabel: 'Lenders will analyze your employment history. Check any below that apply to you.',
  current: 'QE10',
  answers: [
    {
      value: 'employmentGapsFlag',
      text: 'Employment gaps in the past 24 months'
    },
    {
      value: 'changedJobsFlag',
      text: 'Changed jobs in the past 12 months'
    },
    {
      value: 'selfEmployedFlag',
      text: ' I’m Self-employed'
    },
    {
      value: 'noIncomeDocumentationFlag',
      text: 'Cannot document my income'
    },
    {
      value: 'commissionsBonusFlag',
      text: 'Receive commissions or bonus income'
    },
    {
      value: 'noneHistoryFlag',
      text: 'None of the above'
    }
  ],
  co_answers: [
    {
      value: 'coEmploymentGapsFlag',
      text: 'Employment gaps in the past 24 months'
    },
    {
      value: 'coChangedJobsFlag',
      text: 'Changed jobs in the past 12 months'
    },
    {
      value: 'coSelfEmployedFlag',
      text: ' I’m Self-employed'
    },
    {
      value: 'coNoIncomeDocumentationFlag',
      text: 'Cannot document my income'
    },
    {
      value: 'coCommissionsBonusFlag',
      text: 'Receive commissions or bonus income'
    },
    {
      value: 'coNoneHistoryFlag',
      text: 'None of the above'
    }
  ],
  constructor: CheckBoxQuestion
}

const QE11 = {
  label: 'Credit Score',
  popupLabel: 'Your credit score plays a major role in both your approvability odds as well as the interest rate.\n' +
    'What do you think your credit score is?',
  formName: 'creditScoreRange',
  co_formName: 'coCreditScoreRange',
  current: 'QE11',
  answers: [
    {
      value: '1',
      text: 'Less than 500'
    },
    {
      value: '2',
      text: '500 - 580'
    },
    {
      value: '3',
      text: '580 - 620'
    },
    {
      value: '4',
      text: '620 - 680'
    },
    {
      value: '5',
      text: '680 - 720'
    },
    {
      value: '6',
      text: 'Above 720'
    }
  ],
  co_answers: [
    {
      value: '1',
      text: 'Less than 500'
    },
    {
      value: '2',
      text: '500 - 580'
    },
    {
      value: '3',
      text: '580 - 620'
    },
    {
      value: '4',
      text: '620 - 680'
    },
    {
      value: '5',
      text: '680 - 720'
    },
    {
      value: '6',
      text: 'Above 720'
    }
  ],
  constructor: RadioFieldNewQuestions
}

const QE12 = {
  label: 'Payment History & Taxes',
  popupLabel: 'Lenders pay close attention to items on your report as well. Check any that apply.',
  current: 'QE12',
  answers: [
    {
      value: 'latePaymentsFlag',
      text: 'Late payments in last 12 months'
    },
    {
      value: 'collectionsFlag',
      text: 'Collections in last 12 months'
    },
    {
      value: 'lateStudentLoanFlag',
      text: 'Late student loan payments'
    },
    {
      value: 'lateHousingPaymentsFlag',
      text: 'Late housing or lease payments'
    },
    {
      value: 'taxLienJudgementForeclosureFlag',
      text: 'Any tax liens, judgements, or foreclosures in the past 4 years'
    },
    {
      value: 'bankruptcyFlag',
      text: 'Bankruptcy in the past 2 years'
    },
    {
      value: 'taxesNotCurrentFlag',
      text: 'Not current on filing taxes'
    },
    {
      value: 'nonePaymentFlag',
      text: 'None of the above'
    }
  ],
  co_answers: [
    {
      value: 'coLatePaymentsFlag',
      text: 'Late payments in last 12 months'
    },
    {
      value: 'coCollectionsFlag',
      text: 'Collections in last 12 months'
    },
    {
      value: 'coLateStudentLoanFlag',
      text: 'Late student loan payments'
    },
    {
      value: 'coLateHousingPaymentsFlag',
      text: 'Late housing or lease payments'
    },
    {
      value: 'coTaxLienJudgementForeclosureFlag',
      text: 'Any tax liens, judgements, or foreclosures in the past 4 years'
    },
    {
      value: 'coBankruptcyFlag',
      text: 'Bankruptcy in the past 2 years'
    },
    {
      value: 'coTaxesNotCurrentFlag',
      text: 'Not current on filing taxes'
    },
    {
      value: 'coNonePaymentFlag',
      text: 'None of the above'
    }
  ],
  constructor: CheckBoxQuestion
}

const QE13 = {
  label: 'Living Situation',
  popupLabel: 'Good work, you’re almost done. Now, let’s take a quick look at your current living situation…',
  current: 'QE13',
  formName: 'livingSituation',
  co_formName: 'coLivingSituation',
  answers: [
    {
      value: '1',
      text: 'I rent'
    },
    {
      value: '2',
      text: 'I live rent free'
    },
    {
      value: '3',
      text: 'I own a home'
    }
  ],
  co_answers: [
    {
      value: '1',
      text: 'Rents'
    },
    {
      value: '2',
      text: 'Lives rent free'
    },
    {
      value: '3',
      text: 'Owns a home'
    },
    {
      value: '4',
      text: 'Lives with me'
    }
  ],
  inputs: [
    {
      value: 'housingPaymentAmount',
      text: 'Enter Monthly Housing Payment'
    }
  ],
  co_inputs: [
    {
      value: 'coHousingPaymentAmount',
      text: 'Enter Monthly Housing Payment'
    }
  ],
  subTitle1: 'My monthly housing payment is',
  subTitle2: 'Co borrower\'s current living situation is …',
  subTitle3: 'Co borrower\'s monthly housing payment is (only if does not live with you)',
  constructor: RadioTextFieldQuestions
}

const QE14 = {
  label: 'Student loans',
  popupLabel: 'Are any of your student loans in default?',
  formName: 'studentLoanDefault',
  co_formName: 'coStudentLoanDefault',
  current: 'QE14',
  answers: [
    {
      value: 'studentLoanDefaultFlag',
      text: 'Yes'
    },
    {
      value: 'studentLoanRemediationFlag',
      text: 'Yes, but on remediation plan'
    },
    {
      value: 'studentLoanDefaultNoneFlag',
      text: 'No'
    }
  ],
  co_answers: [
    {
      value: 'coStudentLoanDefaultFlag',
      text: 'Yes'
    },
    {
      value: 'coStudentLoanRemediationFlag',
      text: 'Yes, but on remediation plan'
    },
    {
      value: 'coStudentLoanDefaultNoneFlag',
      text: 'No'
    }
  ],
  constructor: RadioFieldQuestions
}

const QE15 = {
  label: 'Tax details',
  popupLabel: 'You said you had a tax lien, judgement or foreclosure.Please check all that apply.',
  current: 'QE15',
  formName: 'taxDetails',
  co_formName: 'coTaxDetails',
  answers: [
    {
      value: 'taxLienFlag',
      text: 'Tax lien within the past 24 months'
    },
    {
      value: 'foreclosure24MonthsFlag',
      text: 'Foreclosure within past 24 months'
    },
    {
      value: 'foreclosure36MonthsFlag',
      text: 'Foreclosure within past 36 months'
    },
    {
      value: 'foreclosure48MonthsFlag',
      text: 'Foreclosure within past 48 months'
    },
    {
      value: 'judgementFlag',
      text: 'Judgement'
    }
  ],
  co_answers: [
    {
      value: 'coTaxLienFlag',
      text: 'Tax lien within the past 24 months'
    },
    {
      value: 'coForeclosure24MonthsFlag',
      text: 'Foreclosure within past 24 months'
    },
    {
      value: 'coForeclosure36MonthsFlag',
      text: 'Foreclosure within past 36 months'
    },
    {
      value: 'coForeclosure48MonthsFlag',
      text: 'Foreclosure within past 48 months'
    },
    {
      value: 'coJudgementFlag',
      text: 'Judgement'
    }
  ],
  constructor: RadioFieldQuestions
}

const QE16 = {
  label: 'Veteran Status',
  popupLabel: 'Let’s see of you qualify for special veteran programs.',
  current: 'QE16',
  formName: 'veteranFlag',
  co_formName: 'coVeteranFlag',
  answers: [
    {
      value: '1',
      text: 'I’m a Veteran'
    },
    {
      value: '0',
      text: 'I’m Not a Veteran'
    }
  ],
  co_answers: [
    {
      value: '1',
      text: 'Yes'
    },
    {
      value: '0',
      text: 'No'
    }
  ],
  constructor: RadioFieldNewQuestions
}

const QE17 = {
  label: 'Source of Funds',
  popupLabel: 'What is the source of your funds? Check any that apply.',
  current: 'QE17',
  formName: 'homeSavingsDetails',
  answers: [
    {
      value: 'homeSavingsCheckingFlag',
      text: 'Checking / Savings'
    },
    {
      value: 'homeSavings401Flag',
      text: '401K'
    },
    {
      value: 'homeSavingsStocksFlag',
      text: 'Stocks / Crypto'
    },
    {
      value: 'homeSavingsGiftFlag',
      text: 'Gift Funds'
    },
    {
      value: 'homeSavingsOtherFlag',
      text: 'Other'
    }
  ],
  constructor: CheckBoxQuestion
}

const QE18 = {
  label: 'Borrower(s) on Loan',
  popupLabel: 'Who will be on the loan application with you?',
  current: 'QE18',
  borrowerLoans: true,
  formName: 'coBorrowerFlag',
  answers: [
    {
      value: '0',
      text: 'Just Me'
    },
    {
      value: '1',
      text: 'My Spouse'
    },
    {
      value: '2',
      text: 'Someone Else'
    }
  ],
  constructor: RadioFieldCoborrowerQuestions
}

const updateLinks: IUpdateLinks[] = [
  {
    questions: [
      QE2
    ]
  },
  {
    questions: [
      QE1
    ]
  },
  {
    questions: [
      QE5
    ]
  },
  {
    questions: [
      QE16
    ]
  },
  {
    questions: [
      QE13
    ]
  },
  {
    questions: [
      QE10
    ]
  },
  {
    questions: [
      QE3
    ]
  },
  {
    questions: [
      QE18,
      QE4,
      QE8,
      QE9,
      QE10,
      QE11,
      QE12,
      QE14,
      QE15,
      QE13,
      QE16
    ],
    type: 'borrowerLoans'
  },
  {
    questions: [
      QE6
    ]
  },
  {
    questions: [
      QE7
    ]
  },
  {
    questions: [
      QE17
    ]
  },
  {
    questions: [
      QE8
    ]
  },
  {
    questions: [
      QE9
    ]
  },
  {
    questions: [
      QE11
    ]
  },
  {
    questions: [
      QE12,
      QE14,
      QE15
    ],
    type: 'paymentHistory'
  }
]

export const UpdateYourInformation = () => {
  return (
    <>
      <Box
        sx={ {
          display: 'flex',
          maxWidth: '768px',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: '#fff',
          padding: '0 20px',
          margin: '0 auto',
          borderRadius: '3px'
        } }
        color="primary"
      >
        <Typography variant='h5' marginBottom='40px' marginTop='40px' textAlign='center' color='#999'>
          You can edit your information and we will recalculate your Success Plan
        </Typography>
        <Box
          sx={ {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: '#fff',
            padding: '25px 20px',
            margin: '0 auto 60px',
            border: '1px solid rgb(237,31,138)',
            borderRadius: '2px',
            boxShadow: '10px 5px 5px #ccc'
          } }
        >
          {
            updateLinks.map((link, index) => {
              return <UpdateYourInformationBlock
                key={ index }
                questions={ link.questions }
                type={ link.type }
              />
            })
          }
        </Box>
      </Box>
    </>
  )
}
