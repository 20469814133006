import axios from 'axios'
import services from '../../app/services'

interface IUserAuth {
  firstName: string;
  lastName: string;
  emailAddress: string;
  cellPhone: string;
  urlIdentifier?: string | null;
}

export const userCreateQuery = (params: IUserAuth) => {
  return axios.post<string>(`${services.member.api}`, params)
}

interface IChangePassword {
  password: string;
  memberId: number,
  emailAddress: string
  serviceTypeId: string
  userTypeId: string
}

export const userChangePasswordQuery = (params: IChangePassword) => {
  return axios.patch<string>(`${services.member.api}/Password/${params.memberId}?CallerId=${params.memberId}&CallerTypeId=1`, params)
}

interface IForgotYourPassword {
  email: string
}

export const userForgotYourQuery = (params: IForgotYourPassword) => {
  return axios.get<string>(`${services.member.api}/Password/Recovery?email=${params.email}`)
}

interface ILogin {
  email: string;
  password: string;
}

export const userLoginQuery = (params: ILogin) => {
  return axios.post<string>(`${services.auth.api}/Login`, params)
}

interface ISaveMemberDetail {
  memberId: number;
  coBorrowerId?: number;
  lastQuestionCompleted: number;
  maritalStatus?: number;
  refiPropertyValue?: number;
  refiPropertyOwe?: number;
  refiInterestRate?: number;
  refiLoanType?: number;
  refiCreditScoreRange?: number;
  refiAddress?: string;
  refiCity?: string;
  refiStateId?: string;
  refiZipCode?: string;
  moveDate?: string;
  moveRange?: number;
  monthlyPaymentAmount?: number;
  monthlyIncome?: number;
  homeSavings?: number;
  homeSavingsCheckingFlag?: boolean;
  homeSavings401Flag?: boolean;
  homeSavingsStocksFlag?: boolean;
  homeSavingsGiftFlag?: boolean;
  homeSavingsOtherFlag?: boolean;
  autoExpense?: number;
  creditCardExpense?: number;
  studentLoans?: number;
  otherExpenses?: number;
  employmentGapsFlag?: boolean;
  changedJobsFlag?: boolean;
  selfEmployedFlag?: boolean;
  noIncomeDocumentationFlag?: boolean;
  commissionsBonusFlag?: boolean;
  creditScoreRange?: number;
  latePaymentsFlag?: boolean;
  collectionsFlag?: boolean;
  lateStudentLoanFlag?: boolean;
  lateHousingPaymentsFlag?: boolean;
  taxLienJudgementForeclosureFlag?: boolean;
  taxLienFlag?: boolean;
  foreclosure24MonthsFlag?: boolean;
  foreclosure36MonthsFlag?: boolean;
  foreclosure48MonthsFlag?: boolean;
  judgementFlag?: boolean;
  bankruptcyFlag?: boolean;
  taxesNotCurrentFlag?: boolean;
  studentLoanDefaultFlag?: boolean;
  studentLoanRemediationFlag?: boolean;
  livingSituation?: number;
  housingPaymentAmount?: number;
  veteranFlag?: boolean;
  liveCity1?: string;
  liveState1?: string;
  liveCity2?: string;
  liveState2?: string;
  liveCity3?: string;
  liveState3?: string;
  coMonthlyIncome?: number;
  coAutoExpense?: number;
  coCreditCardExpense?: number;
  coStudentLoans?: number;
  coOtherExpenses?: number;
  coEmploymentGapsFlag?: boolean;
  coChangedJobsFlag?: boolean;
  coSelfEmployedFlag?: boolean;
  coNoIncomeDocumentationFlag?: boolean;
  coCommissionsBonusFlag?: boolean;
  coCreditScoreRange?: number;
  coLatePaymentsFlag?: boolean;
  coCollectionsFlag?: boolean;
  coLateStudentLoanFlag?: boolean;
  coLateHousingPaymentsFlag?: boolean;
  coTaxLienJudgementForeclosureFlag?: boolean;
  coTaxLienFlag?: boolean;
  coForeclosure24MonthsFlag?: boolean;
  coForeclosure36MonthsFlag?: boolean;
  coForeclosure48MonthsFlag?: boolean;
  coJudgementFlag?: boolean;
  coBankruptcyFlag?: boolean;
  coTaxesNotCurrentFlag?: boolean;
  coStudentLoanDefaultFlag?: boolean;
  coStudentLoanRemediationFlag?: boolean;
  coLivingSituation?: number;
  coHousingPaymentAmount?: number;
  coMaritalStatus?: number;
  coVeteranFlag?: boolean;
  schoolAgeChildrenFlag?: boolean;
  propertyComments?: string;
  lastUpdatedByUserId: number;
  createdDate: string;
  lastUpdatedDate: string;
  coBorrowerFlag: boolean;
}

export const initialMemberDetailUpdateParams: ISaveMemberDetail = {
  memberId: 0,
  coBorrowerId: 0,
  coBorrowerFlag: false,
  lastQuestionCompleted: 0,
  maritalStatus: 0,
  refiPropertyValue: 0,
  refiPropertyOwe: 0,
  refiInterestRate: 0,
  refiLoanType: 0,
  refiCreditScoreRange: 0,
  refiAddress: '',
  refiCity: '',
  refiStateId: '',
  refiZipCode: '',
  moveDate: '2022-07-15T09:08:29.730',
  moveRange: 0,
  monthlyPaymentAmount: 0,
  monthlyIncome: 0,
  homeSavings: 0,
  homeSavingsCheckingFlag: false,
  homeSavings401Flag: false,
  homeSavingsStocksFlag: false,
  homeSavingsGiftFlag: false,
  homeSavingsOtherFlag: false,
  autoExpense: 0,
  creditCardExpense: 0,
  studentLoans: 0,
  otherExpenses: 0,
  employmentGapsFlag: false,
  changedJobsFlag: false,
  selfEmployedFlag: false,
  noIncomeDocumentationFlag: false,
  commissionsBonusFlag: false,
  creditScoreRange: 0,
  latePaymentsFlag: false,
  collectionsFlag: false,
  lateStudentLoanFlag: false,
  lateHousingPaymentsFlag: false,
  taxLienJudgementForeclosureFlag: false,
  taxLienFlag: false,
  foreclosure24MonthsFlag: false,
  foreclosure36MonthsFlag: false,
  foreclosure48MonthsFlag: false,
  judgementFlag: false,
  bankruptcyFlag: false,
  taxesNotCurrentFlag: false,
  studentLoanDefaultFlag: false,
  studentLoanRemediationFlag: false,
  livingSituation: 0,
  housingPaymentAmount: 0,
  veteranFlag: false,
  liveCity1: '',
  liveState1: '',
  liveCity2: '',
  liveState2: '',
  liveCity3: '',
  liveState3: '',
  coMonthlyIncome: 0,
  coAutoExpense: 0,
  coCreditCardExpense: 0,
  coStudentLoans: 0,
  coOtherExpenses: 0,
  coEmploymentGapsFlag: false,
  coChangedJobsFlag: false,
  coSelfEmployedFlag: false,
  coNoIncomeDocumentationFlag: false,
  coCommissionsBonusFlag: false,
  coCreditScoreRange: 0,
  coLatePaymentsFlag: false,
  coCollectionsFlag: false,
  coLateStudentLoanFlag: false,
  coLateHousingPaymentsFlag: false,
  coTaxLienJudgementForeclosureFlag: false,
  coTaxLienFlag: false,
  coForeclosure24MonthsFlag: false,
  coForeclosure36MonthsFlag: false,
  coForeclosure48MonthsFlag: false,
  coJudgementFlag: false,
  coBankruptcyFlag: false,
  coTaxesNotCurrentFlag: false,
  coStudentLoanDefaultFlag: false,
  coStudentLoanRemediationFlag: false,
  coLivingSituation: 0,
  coHousingPaymentAmount: 0,
  coMaritalStatus: 0,
  coVeteranFlag: false,
  schoolAgeChildrenFlag: false,
  propertyComments: '',
  lastUpdatedByUserId: 0,
  createdDate: '2022-07-15T11:44:05.552Z',
  lastUpdatedDate: '2022-07-15T11:44:05.552Z'
}

export const memberDetailUpdateQuery = (params: ISaveMemberDetail = initialMemberDetailUpdateParams) => {
  return axios.put<string>(`${services.member.api}/Detail/${params.memberId}?CallerId=${params.memberId}&CallerTypeId=1`, params)
}

interface ISaveMember {
  memberId: number;
  permissionLevel: number;
  communicationServicesId: string;
  serviceType: number;
  languageId: number;
  isActiveFlag: boolean;
  memberScore: number;
  sourceId: number;
  homeQualStaffId: number;
  homeQualStaffAssignedDate: string;
  lenderId: number;
  lenderAssignedDate: string;
  agentId: number;
  agentAssignedDate: string;
  revenue: number;
  closeDate: string;
  rebateAmount: number;
  propertyValue: number;
  fundedDate: string;
  lastIpAddress: string;
  VerseActivatedFlag: boolean;
  memberStatusId: number;
  nameTitleId: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  stateId: string;
  zipCode: string;
  emailAddress: string;
  cellPhone: number;
  TextOptInFlag: boolean;
  communicationPreferenceId: number;
  communicationOptOutStatusId: number;
  memberAge: number;
  loginName: string;
  password: string;
  failedSignInCount: number;
  passwordResetToken: string;
  passwordResetFlag: boolean;
  passwordLastUpdatedDate: string;
  coRelationshipId: number;
  coNameTitleId: string;
  coFirstName: string;
  coLastName: string;
  coAddress: string;
  coCity: string;
  coStateId: string;
  coZipCode: string;
  coCommunicationPreferenceId: number;
  coCommunicationOptOutStatusId: number;
  memberCoBorrowerId: number;
}

export const initialMemberUpdateParams: ISaveMember = {
  memberId: 0,
  permissionLevel: 0,
  communicationServicesId: '',
  serviceType: 0,
  languageId: 0,
  isActiveFlag: true,
  memberScore: 0,
  sourceId: 0,
  homeQualStaffId: 0,
  homeQualStaffAssignedDate: '2022-07-18T06:27:15.482Z',
  lenderId: 0,
  lenderAssignedDate: '2022-07-18T06:27:15.482Z',
  agentId: 0,
  agentAssignedDate: '2022-07-18T06:27:15.482Z',
  revenue: 0,
  closeDate: '2022-07-18T06:27:15.482Z',
  rebateAmount: 0,
  propertyValue: 0,
  fundedDate: '2022-07-18T06:27:15.482Z',
  lastIpAddress: '',
  VerseActivatedFlag: true,
  memberStatusId: 0,
  nameTitleId: '',
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  stateId: '',
  zipCode: '',
  emailAddress: '',
  cellPhone: 0,
  TextOptInFlag: true,
  communicationPreferenceId: 0,
  communicationOptOutStatusId: 0,
  memberAge: 0,
  loginName: '',
  password: '',
  failedSignInCount: 0,
  passwordResetToken: '',
  passwordResetFlag: false,
  passwordLastUpdatedDate: '2022-07-18T06:27:15.482Z',
  coRelationshipId: 0,
  coNameTitleId: '',
  coFirstName: '',
  coLastName: '',
  coAddress: '',
  coCity: '',
  coStateId: '',
  coZipCode: '',
  coCommunicationPreferenceId: 0,
  coCommunicationOptOutStatusId: 0,
  memberCoBorrowerId: 0
}

export const memberUpdateQuery = (params: ISaveMember = initialMemberUpdateParams) => {
  return axios.put<string>(`${services.member.api}/${params.memberId}?CallerId=${params.memberId}&CallerTypeId=1`, params)
}

export const userBuyHomeCreateQuery = (params: IUserAuth) => {
  return axios.post<string>(`${services.memberBuyHome.api}`, params)
}

export const userBuyHomeGetQuery = (id: number) => {
  return axios.get<string>(`${services.memberBuyHome.api}/${id}?CallerId=${id}&CallerTypeId=1`)
}

export const userBuyHomePutQuery = (params: ISaveMember = initialMemberUpdateParams) => {
  return axios.put<string>(`${services.memberBuyHome.api}?CallerId=${params.memberId}&CallerTypeId=1`, params)
}

export const userFindLenderCreateQuery = (params: IUserAuth) => {
  return axios.post<string>(`${services.memberFindLender.api}`, params)
}

export const userFindLenderGetQuery = (id: number) => {
  return axios.get<string>(`${services.memberFindLender.api}/${id}?CallerId=${id}&CallerTypeId=1`)
}

export const userFindLenderPutQuery = (params: ISaveMember = initialMemberUpdateParams) => {
  return axios.put<string>(`${services.memberFindLender.api}?CallerId=${params.memberId}&CallerTypeId=1`, params)
}

export const userFindAgentCreateQuery = (params: IUserAuth) => {
  return axios.post<string>(`${services.memberFindAgent.api}`, params)
}

export const userFindAgentGetQuery = (id: number) => {
  return axios.get<string>(`${services.memberFindAgent.api}/${id}?CallerId=${id}&CallerTypeId=1`)
}

export const userFindAgentPutQuery = (params: ISaveMember = initialMemberUpdateParams) => {
  return axios.put<string>(`${services.memberFindAgent.api}?CallerId=${params.memberId}&CallerTypeId=1`, params)
}

export const userRefinanceCreateQuery = (params: IUserAuth) => {
  return axios.post<string>(`${services.memberRefinance.api}`, params)
}

export const userRefinanceGetQuery = (id: number) => {
  return axios.get<string>(`${services.memberRefinance.api}/${id}?CallerId=${id}&CallerTypeId=1`)
}

export const userRefinancePutQuery = (params: ISaveMember = initialMemberUpdateParams) => {
  return axios.put<string>(`${services.memberRefinance.api}?CallerId=${params.memberId}&CallerTypeId=1`, params)
}

interface IRefinanceDetailPutParams {
  memberId: number
  lastQuestionCompleted: number
  refiPropertyValue: number
  refiPropertyOwe: number
  refiInterestRate: number
  refiLoanType: number
  refiCreditScoreRange: number
  refiAddress: string
  refiCity: string
  refiStateId: string
  refiZipCode: string
  coBorrowerFlag: boolean
  lastUpdatedByUserId: number
}

export const initialRefinanceUpdateParams: IRefinanceDetailPutParams = {
  memberId: 0,
  lastQuestionCompleted: 0,
  refiPropertyValue: 0,
  refiPropertyOwe: 0,
  refiInterestRate: 0,
  refiLoanType: 0,
  refiCreditScoreRange: 0,
  coBorrowerFlag: false,
  refiAddress: '',
  refiCity: '',
  refiStateId: '',
  refiZipCode: '',
  lastUpdatedByUserId: 0
}

export const userRefinanceDetailPutQuery = (params: IRefinanceDetailPutParams = initialRefinanceUpdateParams) => {
  return axios.put<string>(`${services.memberRefinance.api}/Detail?CallerId=${params.memberId}&CallerTypeId=1`, params)
}

export const userSpeakWithAdvisorQuery = (params: IUserAuth) => {
  return axios.post<string>(`${services.memberSpeakAdvisor.api}`, params)
}

export const userSpeakWithAdvisorGetQuery = (id: number) => {
  return axios.get<string>(`${services.memberSpeakAdvisor.api}/${id}?CallerId=${id}&CallerTypeId=1`)
}

export const userSpeakWithAdvisorPutQuery = (params: ISaveMember = initialMemberUpdateParams) => {
  return axios.put<string>(`${services.memberSpeakAdvisor.api}?CallerId=${params.memberId}&CallerTypeId=1`, params)
}

interface IGetLastQuestionCompleted {
  memberId: number;
}

export const getLastQuestionCompleted = (params: IGetLastQuestionCompleted) => {
  return axios.get(`${services.member.api}/Detail/LastQuestion/${params.memberId}?CallerId=${params.memberId}&CallerTypeId=1`)
}

export const getMemberDetailValues = (params: IGetLastQuestionCompleted) => {
  return axios.get(`${services.member.api}/Detail/${params.memberId}?CallerId=${params.memberId}&CallerTypeId=1`)
}

export const getMemberValues = (params: IGetLastQuestionCompleted) => {
  return axios.get(`${services.member.api}/${params.memberId}?CallerId=${params.memberId}&CallerTypeId=1`)
}

export const getReferenceState = (memberId: string | null) => {
  return axios.get(`${services.api.api}/ReferenceState?CallerId=${memberId}&CallerTypeId=1`)
}

export const getSystemGlobals = () => {
  return axios.get(`${services.systemGlobal.api}`)
}

export const getSystemGlobalsPhone = () => {
  return axios.get(`${services.systemGlobal.api}/FreePhone`)
}

export interface IGetSuccessPlanValues {
  MemberId: number
  LoanType?: string | number
  MonthlyPayment?: string | number
}

export const getSuccessPlanValues = (params: IGetSuccessPlanValues) => {
  return axios.get(`${services.plan.api}/${params.MemberId}?CallerId=${params.MemberId}&CallerTypeId=1`, { params })
}

interface IUpdateMonthlyPayment {
  memberId: number
  monthlyPaymentAmount: string
}

export const updateMonthlyPayment = (params: IUpdateMonthlyPayment) => {
  return axios.patch<string>(`${services.member.api}/Detail/MonthlyPayment/${params.memberId}?CallerId=${params.memberId}&CallerTypeId=1`, params)
}

interface IGetDesignHomeValues {
  memberId: number
}

export const getDesignHomeValues = (params: IGetDesignHomeValues) => {
  return axios.get<string>(`${services.designHome.api}/${params.memberId}?CallerId=${params.memberId}&CallerTypeId=1`)
}

interface IDesignHome {
  memberId: any
  propertyTypeAnyFlag: boolean
  propertyTypeSingleFamilyFlag: boolean
  propertyTypeCondoFlag: boolean
  propertyTypeTownhomeFlag: boolean
  typeOfHomeAnyFlag: boolean
  typeOfHomeNewConstructionFlag: boolean
  typeOfHomeExistingHomeFlag: boolean
  storiesAnyFlag: boolean
  storiesSingleFamilyFlag: boolean
  storiesMultiFlag: boolean
  bedroomsAnyFlag: boolean
  bedrooms1Flag: boolean
  bedrooms2Flag: boolean
  bedrooms3Flag: boolean
  bedrooms4Flag: boolean
  bedrooms5Flag: boolean
  bathroomsAnyFlag: boolean
  bathrooms1Flag: boolean
  bathrooms1HalfFlag: boolean
  bathrooms2Flag: boolean
  bathrooms3Flag: boolean
  bathrooms4Flag: boolean
  insideFeaturesOpenFloorplanFlag: boolean
  insideFeaturesDiningRoomFlag: boolean
  insideFeaturesFamilyRoomFlag: boolean
  insideFeaturesOfficeFlag: boolean
  insideFeaturesBasementFlag: boolean
  insideFeaturesHardwoodFloorsFlag: boolean
  insideFeaturesFireplaceFlag: boolean
  insideFeaturesEnergyEfficientFlag: boolean
  insideFeaturesDisabilityFeaturesFlag: boolean
  outsideFeaturesDeckFlag: boolean
  outsideFeaturesPatioFlag: boolean
  outsideFeaturesPoolFlag: boolean
  outsideFeaturesSpaHotTubFlag: boolean
  outsideFeaturesLargeYardFlag: boolean
  outsideFeaturesFencedYardFlag: boolean
  garageAnyFlag: boolean
  garage1Flag: boolean
  garage2Flag: boolean
  garage3Flag: boolean
  garage4Flag: boolean
  garageNoneFlag: boolean
  designComments: string | null
  createdByUserId: any
  createdDate: string | null
  lastUpdatedByUserId: any
  lastUpdatedDate: string | null
}

export const designHomeUpdate = (params: IDesignHome) => {
  return axios.put(`${services.designHome.api}/${params.memberId}?CallerId=${params.memberId}&CallerTypeId=1`, params)
}

interface IGetBuyingChecklistValues {
  memberId: number
}

export const getBuyingChecklistValues = (params: IGetBuyingChecklistValues) => {
  return axios.get<string>(`${services.buyingChecklist.api}/${params.memberId}?CallerId=${params.memberId}&CallerTypeId=1`)
}

interface IBuyingChecklist {
  memberId: number | null
  preQualifyReviewSuccessFlag: boolean
  resolveIssuesFlag: boolean
  determineAffordFlag: boolean
  dreamHomeFlag: boolean
  mortgageProcessPreApprovedFlag: boolean
  submitDocsFlag: boolean
  determineLoanTypeFlag: boolean
  viewHomesGetAgentFlag: boolean
  tourHomesFlag: boolean
  offerAcceptedFlag: boolean
  homeInspectionFlag: boolean
  optionFeeFlag: boolean
  reviewContractFlag: boolean
  preClosingPurchaseContractFlag: boolean
  appraisalFlag: boolean
  insuranceFlag: boolean
  closingDisclosureFlag: boolean
  claimRebateFlag: boolean
  walkthroughFlag: boolean
  utilitiesFlag: boolean
  closingDayindentificationFlag: boolean
  wireFundsFlag: boolean
  signContractsFlag: boolean
  documentsSecureFlag: boolean
  receiveKeysFlag: boolean
  movingInReviewChecklistFlag: boolean
  createdByUserId: number | null
  createdDate: string | null
  lastUpdatedByUserId: number | null
  lastUpdatedDate: string | null
}

export const buyingChecklistValuesUpdate = (params: IBuyingChecklist) => {
  return axios.put(`${services.buyingChecklist.api}/${params.memberId}?CallerId=${params.memberId}&CallerTypeId=1`, params)
}

export const getAuthUserPackage = (memberId: string | null) => {
  return axios.get(`${services.auth.api}/UserPackage`)
}

export const getAuthUserPackageByMemberId = (memberId: string) => {
  return axios.get(`${services.auth.api}/UserPackage/${memberId}?CallerId=${memberId}&CallerTypeId=1`)
}

interface IGetQualificationIssue {
  memberId: number
}

export const getQualificationIssue = (params: IGetQualificationIssue) => {
  return axios.get(`${services.qualificationIssue.api}/${params.memberId}?CallerId=${params.memberId}&CallerTypeId=1`)
}

interface IGetQualificationIssuePost {
  memberId: number
}

export const postQualificationIssue = (params: IGetQualificationIssuePost) => {
  return axios.post(`${services.qualificationIssue.api}/${params.memberId}?CallerId=${params.memberId}&CallerTypeId=1`)
}

interface IGetQualificationIssuePatch {
  memberId: number
  issueId: number
  issueResolvedFlag: boolean
}

export const patchQualificationIssue = (params: IGetQualificationIssuePatch) => {
  return axios.patch(`${services.qualificationIssue.api}/Resolved/${params.memberId}?CallerId=${params.memberId}&CallerTypeId=1`, params)
}

interface IGetClaimRebate {
  memberId: number
}

export const getClaimRebate = (params: IGetClaimRebate) => {
  return axios.get(`${services.claimRebate.api}/${params.memberId}?CallerId=${params.memberId}&CallerTypeId=1`, { params })
}

interface IPatchClaimRebate {
  purchasePrice: number
  closingDate: string
  lenderChange: string
  agentChange: string
}

export const patchClaimRebate = (params: IPatchClaimRebate, memberId: number) => {
  return axios.patch(`${services.claimRebate.api}/${memberId}?CallerId=${memberId}&CallerTypeId=1`, params)
}

interface IGetSuccessTeam {
  memberId: number
}

export const getSuccessTeam = (params: IGetSuccessTeam) => {
  return axios.get(`${services.successTeam.api}/${params.memberId}?CallerId=${params.memberId}&CallerTypeId=1`, { params })
}

export const patchCoBorrowerReRoll = (memberId: number) => {
  return axios.patch(`${services.member.api}/Detail/CoBorrower/${memberId}?CallerId=${memberId}&CallerTypeId=1`)
}

export const getFile = (fileName: string) => {
  return axios.get(`${services.file.api}?fileName=${fileName}`)
}

export const chatInit = () => {
  return axios.post('/Api/Acs/Chat/Public')
}

export const postChatIdentity = (memberId: string) => {
  return axios.post(`/Api/Acs/Chat/Identity/Member/${memberId}`)
}

export const getChatIdentity = (identityId: string) => {
  return axios.get(`/Api/Acs/Chat/Identity?identityId=${identityId}`)
}

export interface IGetChatThread {
  memberId: number
  accessToken: string
  homeQualStaffId: number
}

export const getChatThread = (params: IGetChatThread) => {
  return axios.get(`/Api/Acs/Chat/ChatThread/MemberWithAdvisor/${params.memberId}/${params.homeQualStaffId}?accessToken=${params.accessToken}`)
}

export interface IPostChatThread {
  identityId: string
  memberId: number
  accessToken: string
  memberName: string
  homeQualStaffId: number
}

export const postChatThread = (params: IPostChatThread) => {
  return axios.post('/Api/Acs/Chat/ChatThread/MemberWithAdvisor', params)
}

export const getChatMemberData = (memberId: string, isReopen: boolean) => {
  return axios.get(`/Api/Acs/Chat/Member/${memberId}?isReopen=${isReopen}`)
}

export const getChatVisitorData = (identityId: string | null, threadId: string | null) => {
  return axios.get(`/Api/Acs/Chat/Visitor?identityId=${identityId}&threadId=${threadId}`)
}

export const closeMemberChatQuery = (memberId: string, identityId: string | null, threadId: string | null, memberName: string = 'Name') => {
  return axios.post(`/Api/Acs/Chat/ClosingNotification/Member/${memberId}/${threadId}/${identityId}`, { memberName: memberName })
}

export const closeVisitorChatQuery = (identityId: string | null, threadId: string | null) => {
  return axios.post(`/Api/Acs/Chat/ClosingNotification/Visitor/${threadId}/${identityId}`)
}

export const sendWelcomeMessage = (memberId: string) => {
  return axios.post(`/Api/Member/Communication/Welcome/${memberId}?CallerId=${memberId}&CallerTypeId=1`)
}
