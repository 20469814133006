import React from 'react'
import { HeaderDivider } from '../../../shared/styles'
import { SuccessButton } from './Sections/SuccessButton'
import { SolutionOdds } from './Sections/SolutionOdds/SolutionOdds'
import { GetLenderLetter } from './Sections/GetLenderLetter/GetLenderLetter'
import { DesignHome } from './Sections/DesignHome/DesignHome'
import { GetYourAgent } from './Sections/GetYourAgent/GetYourAgent'
import { YourHomeBuyingChecklist } from './Sections/YourHomeBuyingChecklist/YourHomeBuyingChecklist'
import { ClaimYourRebate } from './Sections/ClaimYourRebate/ClaimYourRebate'
import { YourSuccessTeam } from './Sections/YourSuccessTeam/YourSuccessTeam'
import { YourHomeAffordability } from './Sections/YourHomeAffordability/YourHomeAffordability'
import { UpdateYourInformation } from './Sections/UpdateYourInformation/UpdateYourInformation'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { Title } from './Sections/Title'
import { styled } from '@mui/material/styles'

const sections = [
  {
    title: 'Your Home Affordability',
    element: <YourHomeAffordability/>
  },
  {
    title: `HomeQual's Solutions 
      to Improve Your Odds`,
    element: <SolutionOdds/>
  },
  {
    title: 'Get Your Lender Pre-Approval Letter',
    element: <GetLenderLetter/>
  },
  {
    title: 'Design Your Dream Home',
    element: <DesignHome/>
  },
  {
    title: 'Get Your Real Estate Agent and View Homes',
    element: <GetYourAgent/>
  },
  {
    title: 'Your Home Buying Checklist',
    element: <YourHomeBuyingChecklist/>
  },
  {
    title: 'Claim Your Rebate',
    element: <ClaimYourRebate/>
  },
  {
    title: 'Your HomeQual Success Team',
    element: <YourSuccessTeam/>
  },
  {
    title: 'Update Your Information',
    element: <UpdateYourInformation/>
  }
]

const AccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    margin: '0 !important'
  },
  '& .Mui-expanded': {
    margin: '0 !important'
  }
}))

export const SuccessPlan = () => {
  const [expanded, setExpanded] = React.useState<string | false>('panel0')
  const handleAccordionChange = (panel: string) => (event: any, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
    setTimeout(() => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const offsetTop = event.target.getBoundingClientRect().top + scrollTop
      window.scrollTo({
        top: offsetTop - 70,
        behavior: 'smooth'
      })
    }, 400)
  }

  return (
    <>
      <HeaderDivider/>
      <Title/>
      {
        sections.map((section, index) => {
          return (<Accordion
              sx={ {
                margin: '0 !important',
                padding: '0 !important'
              } }
              expanded={ expanded === `panel${index}` }
              onChange={ handleAccordionChange(`panel${index}`) }
              TransitionProps={ {
                timeout: 200
              } }
            >
              <AccordionSummaryStyled
                aria-controls={ 'panel' + index + 'd-content' }
                id={ 'panel' + index + 'd-header' }
                sx={ {
                  margin: '0 !important',
                  padding: '0 !important'
                } }
              >
                <SuccessButton
                  key={ index }
                  title={section.title}
                  expanded={ expanded === `panel${index}` }
                />
              </AccordionSummaryStyled>
              <AccordionDetails
                sx={ {
                  margin: '0 !important',
                  padding: '0 !important',
                  minHeight: '100px'
                } }
              >
                { expanded === `panel${index}` && section.element}
              </AccordionDetails>
            </Accordion>
          )
        })
      }
    </>
  )
}
