import React from 'react'
import styled from 'styled-components'
import { buyFirstHomeTips } from '../../../../assets/images/articles'
import { Typography } from '@mui/material'
import { GreyTextContainer, HeaderDivider, QualButton, TextContainer } from '../../../../shared/styles'
import { ContactUs } from '../../Dashboard/Sections/ContactUs'
import { useNavigate } from 'react-router-dom'

const ContentImage = styled.div`
  position        : relative;
  display         : flex;
  justify-content : flex-start;
  align-items     : center;
  height          : 20vh;
  padding         : 10vh 0 14vh 3vw;
  background      : url(${buyFirstHomeTips}) center center no-repeat border-box;
  background-size : cover;
`

export const RealEstate = () => {
  const navigate = useNavigate()

  return (
    <>
      <HeaderDivider/>
      <ContentImage>
        {/* text */ }
      </ContentImage>

      <TextContainer>
        <Typography variant="h5" fontWeight="bold" mb={ 2 }>
          Get matched with the right real estate agent
        </Typography>

        <Typography sx={ {
          fontSize: '22px',
          fontWeight: 300,
          '@media(max-width: 768px)': {
            fontSize: '20px'
          }
        } } textAlign="left">
          HomeQual can match you with a real estate agent who has an imtimate knowledge of the neighborhoods you are interested in,
          advise you on critical purchase decisions like market condition and if the homes you like are properly priced, and find you the perfect home to meet you exact needs.
        </Typography>
      </TextContainer>

      <GreyTextContainer color={ 'black' } style={ { padding: '30px 40px' } }>
        <Typography sx={ {
          fontSize: '22px',
          fontWeight: 300,
          display: 'block',
          '@media(max-width: 768px)': {
            fontSize: '20px'
          }
        } } textAlign="left">
          Using a Home<span style={ { color: 'rgb(237,31,138)' } }>Qual</span> endorsed agent puts you in control and gives you
          the competitive edge you need!
        </Typography>
      </GreyTextContainer>

      <TextContainer>
        <QualButton
          sx={ { borderRadius: '30px', margin: '0 auto' } }
          onClick={() => {
            navigate('/find_agent/Q1')
          } }
        >
          <Typography variant="h5">Find Me an Agent</Typography>
        </QualButton>
      </TextContainer>

      <ContactUs/>
    </>
  )
}
