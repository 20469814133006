import { Grid, Radio, TextField, Typography } from '@mui/material'
import { CSSProperties, FC, memo, useEffect, useState } from 'react'
import { FormikValues } from 'formik/dist/types'
import { FormikProps } from 'formik'
import { IWrapper } from '../Wrapper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import NumberFormat from 'react-number-format'
import InputMask from 'react-input-mask'

export interface basicData {
  formik: FormikProps<any>
  wrapperProps: IWrapper
}

interface IInputField {
  formik: FormikValues
  item: { text: string, value: string, mask?: boolean }
  index?: number
  mainstyles?: CSSProperties
  labelText?: string
  noPadding?: boolean
}

export const InputNumberField: FC<IInputField> = memo(({ formik, item, index, mainstyles, labelText, noPadding }) => {
  const styles = makeStyles(theme => ({
    notchedOutline: {
      borderColor: '#ccc !important'
    }
  }))

  const classes = styles()

  return (
      <NumberFormat
          thousandSeparator={true}
          allowNegative={false}
          customInput={TextField}
          sx={ {
            minWidth: '100px',
            maxWidth: '600px',
            paddingRight: '100px',
            margin: '10px 0 5px 0',
            '@media(max-width: 768px)': {
              paddingRight: noPadding ? '0' : '10vw'
            },
            ...mainstyles
          } }
          autoFocus={ index === 0 }
          variant="outlined"
          onChange={ formik.handleChange }
          color="primary"
          allowLeadingZeros={ false }
          onBlur={ formik.handleBlur }
          value={ formik.values[item.value] || '' }
          name={ item.value }
          autoComplete='no'
          InputProps={ {
            style: { fontSize: '1.4rem', fontWeight: 'bold' },
            classes: {
              notchedOutline: classes.notchedOutline
            }
          } }
          InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
          helperText={ formik.touched[item.value] &&
          <Typography component="span"
                      color="primary"
                      sx={ { fontSize: '1rem' } }>{ formik.errors[item.value] }
          </Typography> }
          label={
            <Typography variant="h6" color="#999" sx={ {
              fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
            } }>
              { labelText || item.text }
            </Typography> }
      />
  )
})

export const InputField: FC<IInputField> = memo(({ formik, item, index, mainstyles, labelText }) => {
  const styles = makeStyles(theme => ({
    notchedOutline: {
      borderColor: '#ccc !important'
    }
  }))

  const classes = styles()
  const formatChars = {
    f: '[1-9]',
    o: '[0-9]'
  }
  return (
    <>
    {
      !item.mask
        ? <TextField autoFocus={index === 0}
                 variant="outlined"
                 onChange={formik.handleChange}
                 color="primary"
                 onBlur={formik.handleBlur}
                 value={formik.values[item.value] === null ? '' : formik.values[item.value]}
                 name={item.value}
                 autoComplete='no'
                 InputProps={{
                   style: { fontSize: '1.4rem', fontWeight: 'bold' },
                   classes: {
                     notchedOutline: classes.notchedOutline
                   }
                 }}
                 InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
                 sx={{
                   minWidth: '100px',
                   maxWidth: '600px',
                   paddingRight: '100px',
                   margin: '10px 0 10px 0',
                   '@media(max-width: 768px)': {
                     paddingRight: '10vw'
                   },
                   ...mainstyles
                 }}
                 error={formik.touched[item.value] && Boolean(formik.errors[item.value])}
                 helperText={formik.touched[item.value] &&
                 <Typography component="span"
                             color="primary"
                             sx={{ fontSize: '1rem' }}>{ formik.errors[item.value] }
                 </Typography>}
                 label={
                   <Typography variant="h6" color="#999" sx={{
                     fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
                   }}>
                     {labelText || item.text}
                   </Typography>}
      />
        : <InputMask
          autoFocus={index === 0}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          autoComplete='no'
          mask="(foo) ooo-oooo"
          formatChars={ formatChars }
          value={ formik.values[item.value] === null ? '' : formik.values[item.value] }
          disabled={false}
        >
          {() =>
            <TextField
              variant="outlined"
              name={item.value}
              InputProps={{
                style: { fontSize: '1.4rem', fontWeight: 'bold' },
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
              sx={{
                minWidth: '100px',
                maxWidth: '600px',
                paddingRight: '100px',
                margin: '10px 0 10px 0',
                '@media(max-width: 768px)': {
                  paddingRight: '10vw'
                },
                ...mainstyles
              }}
              error={formik.touched[item.value] && Boolean(formik.errors[item.value])}
              helperText={formik.touched[item.value] &&
              <Typography component="span"
                          color="primary"
                          sx={{ fontSize: '1rem' }}>{ formik.errors[item.value] }
              </Typography>}
              label={
                <Typography variant="h6" color="#999" sx={{
                  fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
                }}>
                  {labelText || item.text}
                </Typography>}
            />
          }
        </InputMask>
    }
    </>
  )
})

interface IRadioField {
  formik: FormikValues
  index?: number
  formName: string
  itemText?: string
  answers: { text: string, value: string }[]
  nomarginLeft?: boolean,
  className?: string
  onClick?: any
}

export const RadioField: FC<IRadioField> = memo(({
  formik,
  formName,
  nomarginLeft,
  answers,
  className,
  onClick
}) => {
  const [value, setValue] = useState<string | boolean | number>('')
  const handleCurrentAnswer = (event: any, checked: boolean, item: string) => {
    setValue(item)
    formik.handleChange(event, checked)
    if (onClick) {
      onClick()
    }
  }
  useEffect(() => {
    setValue(formik.values[formName])
  }, [formName, answers, formik.values])
  return (
    <Grid
      className={className}
      container direction="column"
      sx={ {
        marginLeft: '200px',
        '@media(max-width: 768px)': {
          marginLeft: nomarginLeft ? '0' : '20vw'
        }
      } }
    >
      { answers.map((item, index) => (
        <Grid container key={ index } sx={ { margin: '5px 0' } }>
          <Radio
            checked={
              value === item.value ||
              value === +item.value ||
              ((value === true && +item.value === 1) || (value === false && +item.value === 0)) || false
            }
            id={ item.text + index }
            onChange={ (event, checked) => handleCurrentAnswer(event, checked, item.value) }
            value={ item.value }
            name={ formName }
            sx={ { transform: 'scale(1.3)', marginRight: '15px' } }
          />

          <label htmlFor={ item.text + index }>
            <Typography variant="h6">{ item.text }</Typography>
          </label>
        </Grid>
      )) }
    </Grid>
  )
})
