import { basicData, InputField } from '../shared'
import { FC, memo, useState, useEffect } from 'react'
import { Wrapper } from '../../Wrapper'
import { Autocomplete, Grid, TextField, Typography } from '@mui/material'
import { ICreateAutoCompleteQuestion } from '../../../questions'
import { getReferenceState } from '../../../../../../shared/user/userQueries'
import makeStyles from '@material-ui/core/styles/makeStyles'

interface IProps extends basicData, ICreateAutoCompleteQuestion {
}

interface IState {
  stateId: string
  description: string
}

export const CreateAutoCompleteQuestion: FC<IProps> = memo(({
  inputs,
  formik,
  formName,
  wrapperProps
}) => {
  const styles = makeStyles(theme => ({
    notchedOutline: {
      borderColor: '#ccc !important',
      borderWidth: '1px important'
    }
  }))
  const classes = styles()
  const [states, setStates] = useState([])

  useEffect(() => {
    if (states.length === 0) {
      getReferenceState(formik.values.memberId)
        .then((response: any) => {
          const referenceStates = response.data.map((state: IState) => {
            return state.stateId
          })
          setStates(referenceStates)
        })
        .catch((err: any) => {
          console.error(err)
        })
    }
  }, [states])

  return (
    <Wrapper { ...wrapperProps }>
      <Grid container direction="column" wrap="nowrap" sx={ {
        paddingLeft: '100px',
        '@media(max-width: 768px)': {
          paddingLeft: '10vw'
        }
      } } pr={ 2 }>
        { inputs.map((item, index: number) => (
          <Grid key={ index }>
            <Typography variant="h5" mt={ 5 } mb={2}>Option { index + 1 }</Typography>
            <Grid container alignItems="center" justifyContent="space-between" width="100%" wrap="nowrap">
              <Grid maxWidth="500px" mt={ '0px' } container alignSelf="start" boxSizing='border-box'>
                <InputField
                  formik={ formik }
                  item={ item }
                  mainstyles={ { width: '100%', paddingRight: 0, paddingBottom: 1, marginTop: '8px' } }
                />
              </Grid>
              <Grid container flexShrink={ 1 } alignSelf="start" boxSizing='border-box'>
                <Autocomplete
                  id={ `${formName}[1]` }
                  onChange={ (event, value) => formik.setFieldValue(`${formName}${index + 1}`, value) }
                  options={ states }
                  fullWidth
                  value={ formik.values[`${formName}${index + 1}`] }
                  autoSelect={ true }
                  renderOption={ (option: any) => (
                    <Typography { ...option } style={ {
                      fontSize: '1rem'
                    } }>{ option.key }</Typography>
                  ) }
                  sx={ {
                    paddingLeft: '5px',
                    height: '100%',
                    boxSizing: 'border-box',
                    maxWidth: '250px'
                  } }
                  renderInput={ (params) => (
                    <TextField { ...params }
                       variant="outlined"
                       InputLabelProps={ {
                         sx: {
                           '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 }
                         }
                       } }
                       inputProps={ {
                         ...params.inputProps as any,
                         style: { fontSize: '1.4rem', fontWeight: 'bold' }
                       } }
                       InputProps={ {
                         ...params.InputProps as any,
                         style: { paddingTop: 8, paddingRight: 50 },
                         classes: {
                           notchedOutline: classes.notchedOutline
                         }
                       } }
                       name={`${formName}${index + 1}`}
                       label={ <Typography variant="h6" color="#999" sx={{
                         fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
                       }}>
                         {
                           'State'
                         }
                       </Typography> }
                       sx={ { mt: 1 } }/>) }
                />
              </Grid>
            </Grid>
          </Grid>
        )) }
      </Grid>

    </Wrapper>
  )
})
