import { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Diagrams } from '../SuccessPlan/Diagrams'
import useCurrentData from '../../../../../shared/hooks/useCurrentData'
import { getSuccessPlanValues, IGetSuccessPlanValues } from '../../../../../shared/user/userQueries'
import { Box } from '@mui/material'

export const YourHomeAffordability = () => {
  const [chosenDiagramButton, setDiagramButton] = useState<number | string>(1)
  const [calculationValues, setCalculationValues] = useState<any>(false)
  const [monthlyPayment, setMonthlyPayment] = useState<number | string>(0)
  const handleDiagramButtonClick = (value: string) => {
    setDiagramButton(value)
    const params = {
      MemberId: currentData.memberId,
      LoanType: value,
      MonthlyPayment: monthlyPayment || 0
    }
    getSuccessPlanCalculates(params)
  }
  const handleMonthlyPaymentChange = (value: any) => {
    const params = {
      MemberId: currentData.memberId,
      LoanType: chosenDiagramButton || 1,
      MonthlyPayment: value || 0
    }
    getSuccessPlanCalculates(params)
  }
  const { currentData } = useCurrentData()
  const getSuccessPlanCalculates = (params: IGetSuccessPlanValues) => {
    getSuccessPlanValues(params)
      .then((response: any) => {
        setCalculationValues(response.data)
        setDiagramButton(response.data.loanType)
        const payment = Math.floor(response.data.newMonthlyPayment) || 0
        setMonthlyPayment(payment)
      })
      .catch((err: any) => {
        console.error(err)
      })
  }
  useEffect(() => {
    const initialParams = {
      MemberId: currentData.memberId
    }
    getSuccessPlanCalculates(initialParams)
  }, [currentData.memberId])

  return (
    <>
      {
        calculationValues
          ? <Diagrams
            calculationValues={ calculationValues }
            monthlyPayment={ monthlyPayment }
            setMonthlyPayment={ handleMonthlyPaymentChange }
            handleDiagramButtonClick={ handleDiagramButtonClick }
            chosenDiagramButton={ chosenDiagramButton }
          />
          : <Box
              sx={ {
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px',
                width: '100%'
              } }
              color="primary"
            >
              <CircularProgress/>
            </Box>
      }
    </>
  )
}
