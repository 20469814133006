import { basicData } from '../shared'
import { FC, memo, useEffect } from 'react'
import { Wrapper } from '../../Wrapper'
import { Checkbox, FormControlLabel, FormGroup, Typography, TextField } from '@mui/material'
import { ICreateRadio_CheckBox_Question } from '../../../questions'

interface IProps extends basicData, ICreateRadio_CheckBox_Question {
}

export const CreateCheckBoxQuestion: FC<IProps> = memo(({
  answers,
  formik,
  formName,
  wrapperProps

}) => {
  const isCheckedAnswer = answers.find((one: any) => {
    return formik.values[one.value]
  })
  useEffect(() => {
    formik.setFieldValue(formName, '1')
    if (!isCheckedAnswer) {
      answers.forEach((item) => {
        if (item.value.indexOf('None') !== -1) {
          formik.setFieldValue(item.value, true)
        }
      })
    }
  }, [isCheckedAnswer])
  const hiddenHandler = (event: any) => {
    formik.setFieldValue(formName, '1')
    if (event.target.name.indexOf('None') !== -1) {
      answers.forEach((item) => {
        formik.values[item.value] = false
      })
    } else {
      answers.forEach((item) => {
        if (item.value.indexOf('None') !== -1) {
          formik.values[item.value] = false
        }
      })
    }
  }
  return (
    <Wrapper { ...wrapperProps }>
      <>
        <TextField
          name={ formName }
          type="hidden"
          value=''
          onChange={ formik.handleChange }
        />
        <FormGroup
          onChange={ formik.handleChange }
          sx={ {
            paddingLeft: '200px',
            '@media(max-width: 768px)': {
              paddingLeft: '20vw'
            }
          } }
          onClick={ (event) => hiddenHandler(event) }
        >
          { answers && answers.map((item, index: number) => (
            <FormControlLabel control={ <Checkbox sx={ { marginRight: '15px' } }/> }
              label={ <Typography variant="h6"> { item.text } </Typography> }
              name={ item.value }
              key={ index }
              value={ true }
              color="primary"
              checked={
                (item.value === 'noneHistoryFlag' ||
                  item.value === 'nonePaymentFlag' ||
                  item.value === 'employmentGapsFlagNone') && !isCheckedAnswer
                  ? true
                  : formik.values[item.value] || false
              }
              sx={ { margin: '1vh 0', width: '80vw' } }/>)) }
        </FormGroup>
      </>
    </Wrapper>
  )
})
