import { FC, memo } from 'react'
import { Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material'

interface ICheckBoxQuestion {
  question: any
  handleChange: any
  allValues: any
}

export const CheckBoxQuestion: FC<ICheckBoxQuestion> = memo(({
  question,
  handleChange,
  allValues
}) => {
  const isCheckedAnswer = question.answers.find((one: any) => {
    return allValues[one.value]
  })
  const isCheckedCoAnswer = question.co_answers && question.co_answers.find((one: any) => {
    return allValues[one.value]
  })
  return (
    <>
    {
      !allValues.coBorrowerFlag || !question.co_answers
        ? <Grid container direction="column" wrap="nowrap">
          <FormGroup
            sx={ {
              paddingLeft: '200px',
              '@media screen and (max-width : 768px)': {
                paddingLeft: '20vw'
              }
            } }
            onChange={ (event) => {
              handleChange(event)
            } }
          >
            { question.answers && question.answers.map((item: any, index: number) => (
              <FormControlLabel
                control={ <Checkbox sx={ { marginRight: '15px' } }/> }
                label={ <Typography
                  sx={ {
                    fontSize: '20px',
                    '@media screen and (max-width : 600px)': {
                      fontSize: '16px'
                    }
                  } }
                > { item.text } </Typography> }
                name={ item.value }
                key={ index }
                value={ true }
                color="primary"
                checked={ (item.value === 'noneHistoryFlag' || item.value === 'nonePaymentFlag') &&
                !isCheckedAnswer
                  ? true
                  : allValues[item.value] || false
                }
                sx={ { maxWidth: '600px', margin: '1vh 0', width: '80vw' } }/>)) }
          </FormGroup>
        </Grid>
        : question.co_answers && <Grid container direction="column" wrap="nowrap" sx={ { padding: '0 3vw 0 3vw' } }>
          <Grid container>
              <Typography fontSize='23px' sx={ {
                maxWidth: '49px',
                marginRight: '20px',
                textAlign: 'left',
                fontWeight: 'bold',
                paddingLeft: '100px',
                '@media screen and (max-width : 768px)': {
                  paddingLeft: '10vw'
                }
              } }>
                  You
              </Typography>
              <Typography fontSize='23px' sx={ {
                width: 'auto',
                textAlign: 'left',
                fontWeight: 'bold',
                paddingLeft: '100px',
                '@media screen and (max-width : 768px)': {
                  paddingLeft: '10vw'
                }
              } }>
                Co-Borrower
              </Typography>
          </Grid>

          <FormGroup
            sx={ {
              paddingLeft: '100px',
              '@media screen and (max-width : 768px)': {
                paddingLeft: '10vw'
              }
            } }
            onChange={ (event) => {
              handleChange(event)
            } }
          >
              <TextField
                  name={ question.formName }
                  type="hidden"
                  value=''
              />
              <Grid container direction="column" wrap="nowrap">
                { question.answers.map((item: any, index: number) => (
                  <Grid container justifyContent="flex-start" wrap={ 'nowrap' } key={ index }>
                    <FormControlLabel control={ <Checkbox sx={ { transform: 'scale(1)' } }/> }
                                      label=""
                                      name={ item.value }
                                      value={ true }
                                      color="primary"
                                      checked={
                                        (item.value === 'noneHistoryFlag' ||
                                          item.value === 'nonePaymentFlag') && !isCheckedAnswer
                                          ? true
                                          : allValues[item.value] || false
                                      }
                                      sx={ { margin: '1vh 0', minWidth: '50px' } }/>
                    <FormControlLabel control={ <Checkbox sx={ { transform: 'scale(1)', marginRight: '15px' } }/> }
                                      label={
                                        <Typography
                                          sx={ {
                                            fontSize: '20px',
                                            '@media screen and (max-width : 600px)': {
                                              fontSize: '16px'
                                            }
                                          } }
                                          ml={ 1 }
                                        >
                                          { item.text }
                                        </Typography>
                                      }
                                      name={ question.co_answers[index].value }
                                      value={ true }
                                      checked={
                                        (question.co_answers[index].value === 'coNoneHistoryFlag' ||
                                          question.co_answers[index].value === 'coNonePaymentFlag') &&
                                          !isCheckedCoAnswer
                                          ? true
                                          : allValues[question.co_answers[index].value] || false
                                      }
                                      color="primary"
                                      sx={ { margin: '1vh 0', width: 'auto' } }/>
                  </Grid>
                )) }
              </Grid>
          </FormGroup>
      </Grid>
    }
    </>
  )
})
