import { FC, memo } from 'react'
import { basicData } from '../shared'
import { Wrapper } from '../../Wrapper'
import { Grid, Typography } from '@mui/material'
import { PieChart } from 'react-minimal-pie-chart'
import { basicTypes } from '../../../questions'
import { numberWithCommas } from '../../../../../../shared/functions'

interface IProps extends basicTypes, basicData {
}

export const CreateDiagramQuestion: FC<IProps> = memo(({
  formik,
  wrapperProps
}) => {
  const monthlyIncome = typeof formik.values.monthlyIncome === 'string'
    ? formik.values.monthlyIncome.replace(',', '')
    : formik.values.monthlyIncome

  const autoExpense = typeof formik.values.autoExpense === 'string'
    ? formik.values.autoExpense.replace(',', '')
    : formik.values.autoExpense

  const coMonthlyIncome = typeof formik.values.coMonthlyIncome === 'string'
    ? formik.values.coMonthlyIncome.replace(',', '')
    : formik.values.coMonthlyIncome

  const creditCardExpense = typeof formik.values.creditCardExpense === 'string'
    ? formik.values.creditCardExpense.replace(',', '')
    : formik.values.creditCardExpense

  const studentLoans = typeof formik.values.studentLoans === 'string'
    ? formik.values.studentLoans.replace(',', '')
    : formik.values.studentLoans

  const otherExpenses = typeof formik.values.otherExpenses === 'string'
    ? formik.values.otherExpenses.replace(',', '')
    : formik.values.otherExpenses

  const coAutoExpense = typeof formik.values.coAutoExpense === 'string'
    ? formik.values.coAutoExpense.replace(',', '')
    : formik.values.coAutoExpense

  const coCreditCardExpense = typeof formik.values.coCreditCardExpense === 'string'
    ? formik.values.coCreditCardExpense.replace(',', '')
    : formik.values.coCreditCardExpense

  const coStudentLoans = typeof formik.values.coStudentLoans === 'string'
    ? formik.values.coStudentLoans.replace(',', '')
    : formik.values.coStudentLoans

  const coOtherExpenses = typeof formik.values.coOtherExpenses === 'string'
    ? formik.values.coOtherExpenses.replace(',', '')
    : formik.values.coOtherExpenses

  const totalIncome = Number(monthlyIncome) +
    Number(coMonthlyIncome)
  const totalExpenses = Number(autoExpense) +
    Number(creditCardExpense) +
    Number(studentLoans) +
    Number(otherExpenses) +
    Number(coAutoExpense) +
    Number(coCreditCardExpense) +
    Number(coStudentLoans) +
    Number(coOtherExpenses) +
    Number(formik.values.monthlyPaymentAmount)

  const ratio = Math.ceil(totalExpenses / totalIncome * 100)

  const pieData = [
    {
      title: 'Monthly Income',
      value: totalIncome,
      color: '#818181'
    },
    {
      title: 'Monthly expenses',
      value: totalExpenses,
      color: 'rgb(237, 31, 138)'
    }
  ]

  return (
    <Wrapper { ...wrapperProps } >
      <Grid container direction="column" wrap="nowrap" alignItems="center" sx={ { marginTop: '30px', height: '100%' } }>
        <Typography variant="h5" component="span" sx={ {
          padding: '10px',
          color: '#000',
          fontWeight: 'bold',
          textAlign: 'center',
          minWidth: '200px',
          width: '80vw',
          maxWidth: '500px'
        } }> Debt to Income Ratio </Typography>

        <Grid container position={ 'relative' } sx={ { maxWidth: '250px' } }>
          <PieChart startAngle={ 270 }
                    paddingAngle={ 2 }
                    data={ pieData }
                    lineWidth={ 20 }
                    label={ (item) => item.dataIndex === 0 ? `${ratio}%` : null }
                    labelPosition={ 0 }
                    labelStyle={ { fill: 'rgb(237, 31, 138)', fontSize: '13px', fontWeight: 'bold' } }
                    radius={ 30 }/>
        </Grid>

        <Grid direction="column" width="70%" container maxWidth={ 400 }>
          <Grid container justifyContent="center" wrap={ 'nowrap' } mb={ 2 }>
            <Typography variant="h6">Monthly Income ${ numberWithCommas(totalIncome) }</Typography>
          </Grid>

          <Grid container justifyContent="center" wrap={ 'nowrap' }>
            <Typography
              variant="h6"
              justifyContent="space-between"
            >
              Monthly Expenses ${ numberWithCommas(totalExpenses) }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  )
})
