import React, { memo, useEffect, useState } from 'react'
import { NavigateFunction, useLocation } from 'react-router-dom'
import { Button, Grid, Typography } from '@mui/material'
import styled from 'styled-components'
import { QuestionKeyType } from '../questions'
import { FormikProps } from 'formik'
import useCurrentFormikValues from '../../../../shared/hooks/useCurrentFormikValues'
import useCurrentOtherSectionsData from '../../../../shared/hooks/useCurrentOtherSectionsData'
import {
  userChangePasswordQuery,
  userCreateQuery,
  userLoginQuery,
  userBuyHomeCreateQuery,
  userFindLenderCreateQuery,
  userFindAgentCreateQuery,
  userRefinanceCreateQuery,
  userSpeakWithAdvisorQuery,
  userRefinanceDetailPutQuery,
  patchCoBorrowerReRoll, sendWelcomeMessage, closeMemberChatQuery, closeVisitorChatQuery
} from '../../../../shared/user/userQueries'
import { toast } from 'react-toastify'
import axios from 'axios'
import {
  updateMemberDetail,
  getMemberDetailsValues,
  getMemberCurrentValues,
  updateMember,
  isQaSection,
  isBuyHomeSection,
  isFindLenderSection,
  isFindAgentSection,
  isRefinanceSection,
  isSpeakWithAdvisorSection
} from '../../../../shared/functions'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useShowChat from '../../../../shared/hooks/useShowChat'

const useStyles = makeStyles({
  userExistButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '50px'
  },
  userExistButton: {
    width: '100px'
  }
})

const ColoredButton = styled(({ ...props }) => <Button { ...props } variant="outlined"
 color="primary"
 type="button"
 disableRipple={ true }/>)`
  display          : block;
  width            : 80vw;
  min-width        : 200px;
  max-width        : 500px;
  padding          : 10px;
  border-radius    : 10px;
  background-color : white;
  animation-name   : none;
  transition       : 0.2s;

  &:active, &:focus {
    transition       : 0.1s;
    background-color : ${(props) => props.theme.colors.primary};
    color            : white
  }
`
const StyledForm = styled(({ ...props }) => <form { ...props }/>)`
  flex-grow      : 1;
  display        : flex;
  flex-direction : column;
`

export interface IWrapper {
  question: (state: {} | null) => string
  formik: FormikProps<any>,
  navigate: NavigateFunction,
  current: QuestionKeyType,
  next: QuestionKeyType,
  SetCurrentQuestionHandler: (
    next: QuestionKeyType,
    current: QuestionKeyType,
    formik: FormikProps<any>,
    navigate: NavigateFunction,
    additionalHandler?: any) => void,
  setSchema: (questionKey: QuestionKeyType) => void,
  description?: string
  qNumber: number
  status?: number
}

export const Wrapper: React.FC<IWrapper> = memo(({
  formik,
  navigate,
  children,
  current,
  next,
  SetCurrentQuestionHandler,
  setSchema,
  description,
  qNumber,
  status,
  question
}) => {
  const { saveCurrentFormikValues, currentFormikValues } = useCurrentFormikValues()
  const { currentOtherSectionsData, setCurrentOtherSectionsData } = useCurrentOtherSectionsData()
  const [isValidated, setIsValidated] = useState(false)
  const [isUserExist, setIsUserExist] = useState(false)
  const { pathname } = useLocation()
  const { setShowChat } = useShowChat()
  const classes = useStyles()
  useEffect(() => {
    setTimeout(() => {
      formik.validateForm()
        .then(() => {
          setIsValidated(formik.isValid)
        })
    }, 50)
    setSchema(current)
  }, [current, formik.submitCount, pathname, formik.values, formik.isValid])
  const createResponseHandle = (response: any) => {
    if (response.data.errorId > 0) {
      toast.error('Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.')
    } else {
      const values = {
        ...formik.values,
        memberId: response.data.memberId
      }
      formik.values = values
      formik.submitForm()
      setCurrentOtherSectionsData(values)
      navigate(`${next}`)
    }
  }
  const handleClick = async () => {
    const errors = await formik.validateForm()
    if (Object.keys(errors).length === 0) {
      SetCurrentQuestionHandler(next, current, formik, navigate)
      formik.setTouched({})
    }
    await formik.submitForm()
    if (current === 'Q1') {
      const params = {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        emailAddress: formik.values.emailAddress,
        cellPhone: formik.values.cellPhone
          .replace('-', '')
          .replace('(', '')
          .replace(')', '')
          .replace(' ', ''),
        urlIdentifier: sessionStorage.getItem('src') || null
      }
      if (isQaSection()) {
        userCreateQuery(params)
          .then((response: any) => {
            if (response.data.error && response.data.error.errorId === 101) {
              setIsUserExist(true)
            } else if (response.data.error && response.data.error.errorId > 0) {
              setIsUserExist(false)
              toast.error('Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.')
            } else {
              setIsUserExist(false)
              const values = {
                ...formik.values,
                memberId: response.data.entity.memberId,
                serviceTypeId: response.data.entity.serviceTypeId,
                userTypeId: response.data.entity.userTypeId
              }
              formik.values = values
              formik.submitForm()
              saveCurrentFormikValues(values)
              const loginParams = {
                email: formik.values.emailAddress,
                password: `${response.data.entity.memberId}`
              }
              userLoginQuery(loginParams)
                .then((response: any) => {
                  if (response.data.errorId > 0) {
                    toast.error('Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.')
                  } else {
                    sessionStorage.setItem('authToken', response.data.token)
                    axios.defaults.headers.common.authorization = `Bearer ${sessionStorage.authToken}`
                    updateMemberDetail(formik.values, response.data.memberId, false, qNumber)
                    setShowChat(false)
                    sendWelcomeMessage(response.data.memberId)
                      .then((response: any) => {
                      })
                      .catch((err: any) => {
                        console.error(err)
                      })
                    const identityId = sessionStorage.getItem('identityId')
                    const threadId = sessionStorage.getItem('threadId')
                    const authToken = sessionStorage.getItem('authToken')
                    if (identityId && threadId) {
                      if (authToken) {
                        closeMemberChatQuery(response.data.memberId, identityId, threadId)
                          .then((response: any) => {
                          })
                          .catch((err: any) => {
                            console.error(err)
                          })
                      } else {
                        closeVisitorChatQuery(identityId, threadId)
                          .then((response: any) => {
                          })
                          .catch((err: any) => {
                            console.error(err)
                          })
                      }
                      sessionStorage.removeItem('identityId')
                      sessionStorage.removeItem('threadId')
                    }
                    navigate(`${next}`)
                  }
                })
                .catch((err: any) => {
                  console.error(err)
                })
            }
          })
          .catch((err: any) => {
            toast.error('Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.')
            console.error(err)
          })
      } else if (isBuyHomeSection()) {
        userBuyHomeCreateQuery(params)
          .then((response: any) => {
            createResponseHandle(response)
          })
          .catch((err: any) => {
            toast.error('Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.')
            console.error(err)
          })
      } else if (isFindLenderSection()) {
        userFindLenderCreateQuery(params)
          .then((response: any) => {
            createResponseHandle(response)
          })
          .catch((err: any) => {
            toast.error('Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.')
            console.error(err)
          })
      } else if (isFindAgentSection()) {
        userFindAgentCreateQuery(params)
          .then((response: any) => {
            createResponseHandle(response)
          })
          .catch((err: any) => {
            toast.error('Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.')
            console.error(err)
          })
      } else if (isRefinanceSection()) {
        userRefinanceCreateQuery(params)
          .then((response: any) => {
            createResponseHandle(response)
          })
          .catch((err: any) => {
            toast.error('Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.')
            console.error(err)
          })
      } else if (isSpeakWithAdvisorSection()) {
        userSpeakWithAdvisorQuery(params)
          .then((response: any) => {
            createResponseHandle(response)
          })
          .catch((err: any) => {
            toast.error('Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.')
            console.error(err)
          })
      }
    }
    if (current === 'QP' && formik.values.password) {
      await changePassword()
    }
    if (current !== 'Q1') {
      const memberId = isQaSection() ? currentFormikValues.memberId : currentOtherSectionsData.memberId
      if (isQaSection()) {
        const isHaveStudentLoan = formik.values.lateStudentLoanFlag
        const isHaveTax = formik.values.taxLienJudgementForeclosureFlag
        const isCoHaveStudentLoan = formik.values.coLateStudentLoanFlag
        const isCoHaveTax = formik.values.taxLienJudgementForeclosureFlag
        if (current === 'Q17C' && !isHaveStudentLoan && !isHaveTax && !isCoHaveStudentLoan && !isCoHaveTax) {
          formik.setFieldValue('studentLoanDefault', '')
          formik.setFieldValue('coStudentLoanDefault', '')
          formik.setFieldValue('taxDetails', '')
          formik.setFieldValue('coTaxDetails', '')
          formik.setFieldValue('taxLienFlag', '')
          formik.setFieldValue('foreclosure24MonthsFlag', '')
          formik.setFieldValue('foreclosure36MonthsFlag', '')
          formik.setFieldValue('foreclosure48MonthsFlag', '')
          formik.setFieldValue('studentLoanDefaultFlag', '')
          formik.setFieldValue('studentLoanRemediationFlag', '')
          formik.setFieldValue('studentLoanDefaultNoneFlag', '')
          formik.setFieldValue('coTaxLienFlag', '')
          formik.setFieldValue('coForeclosure24MonthsFlag', '')
          formik.setFieldValue('coForeclosure36MonthsFlag', '')
          formik.setFieldValue('coForeclosure48MonthsFlag', '')
          formik.setFieldValue('coStudentLoanDefaultFlag', '')
          formik.setFieldValue('coStudentLoanRemediationFlag', '')
          formik.setFieldValue('coStudentLoanDefaultNoneFlag', '')
        } else if (current === 'Q17C' && !isHaveStudentLoan && !isCoHaveStudentLoan && (isHaveTax || isCoHaveTax)) {
          formik.setFieldValue('studentLoanDefault', '')
          formik.setFieldValue('coStudentLoanDefault', '')
          formik.setFieldValue('studentLoanDefaultFlag', '')
          formik.setFieldValue('studentLoanRemediationFlag', '')
          formik.setFieldValue('studentLoanDefaultNoneFlag', '')
          formik.setFieldValue('coStudentLoanDefaultFlag', '')
          formik.setFieldValue('coStudentLoanRemediationFlag', '')
          formik.setFieldValue('coStudentLoanDefaultNoneFlag', '')
        } else if (current === 'Q17' && !isHaveStudentLoan && !isHaveTax) {
          formik.setFieldValue('studentLoanDefault', '')
          formik.setFieldValue('taxDetails', '')
          formik.setFieldValue('coTaxDetails', '')
          formik.setFieldValue('taxLienFlag', '')
          formik.setFieldValue('foreclosure24MonthsFlag', '')
          formik.setFieldValue('foreclosure36MonthsFlag', '')
          formik.setFieldValue('foreclosure48MonthsFlag', '')
          formik.setFieldValue('studentLoanDefaultFlag', '')
          formik.setFieldValue('studentLoanRemediationFlag', '')
          formik.setFieldValue('studentLoanDefaultNoneFlag', '')
        } else if (current === 'Q17' && isHaveTax && !isHaveStudentLoan) {
          formik.setFieldValue('studentLoanDefault', '')
          formik.setFieldValue('studentLoanDefaultFlag', '')
          formik.setFieldValue('studentLoanRemediationFlag', '')
          formik.setFieldValue('studentLoanRemediationFlag', '')
          formik.setFieldValue('studentLoanDefaultNoneFlag', '')
        }
        getMemberDetailsValues(memberId)
          .then((response: any) => {
            return updateMemberDetail(formik.values, response.data.memberId, response.data, qNumber, formik)
              .then((response: any) => {
                if (current === 'Q3' && isQaSection() && !+formik.values.coBorrowerFlag) {
                  patchCoBorrowerReRoll(currentFormikValues.memberId)
                    .then((response: any) => {
                      console.log(response)
                    })
                    .catch((err: any) => {
                      console.error(err)
                    })
                }
              })
              .catch((err: any) => {
                console.error(err)
              })
          })
          .catch((err: any) => {
            console.error(err)
          })
        getMemberCurrentValues(memberId)
          .then((response: any) => {
            return updateMember(formik.values, response.data, formik)
          })
          .catch((err: any) => {
            console.error(err)
          })
      } else {
        const params = {
          memberId,
          lastQuestionCompleted: qNumber,
          refiPropertyValue: formik.values.refiPropertyValue && +(formik.values.refiPropertyValue.replace(/,/g, '')),
          refiPropertyOwe: formik.values.refiPropertyOwe && +(formik.values.refiPropertyOwe.replace(/,/g, '')),
          refiInterestRate: formik.values.refiInterestRate && +(formik.values.refiInterestRate.replace(/,/g, '')),
          refiLoanType: formik.values.refiLoanType && +(formik.values.refiLoanType.replace(/,/g, '')),
          refiCreditScoreRange: formik.values.refiCreditScoreRange && +(formik.values.refiCreditScoreRange.replace(/,/g, '')),
          refiAddress: formik.values.refiAddress,
          refiCity: formik.values.refiCity,
          coBorrowerFlag: !!+formik.values.coBorrowerFlag,
          coRelationshipId: +formik.values.coBorrowerFlag,
          refiStateId: formik.values.refiStateId,
          refiZipCode: formik.values.refiZipCode,
          lastUpdatedByUserId: memberId
        }
        userRefinanceDetailPutQuery(params)
          .then((response: any) => {
            navigate(`${next}`)
          })
          .catch((err: any) => {
            toast.error('Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.')
            console.error(err)
          })
      }
    }
  }

  const changePassword = async () => {
    const changePasswordParams = {
      memberId: currentFormikValues.memberId,
      password: formik.values.password,
      emailAddress: currentFormikValues.emailAddress,
      serviceTypeId: currentFormikValues.serviceTypeId,
      userTypeId: currentFormikValues.userTypeId
    }
    userChangePasswordQuery(changePasswordParams)
      .then((response: any) => {
        navigate(`${next}`)
        console.log(response)
      })
      .catch((err: any) => {
        toast.error('Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.')
        console.error(err)
      })
  }

  return (

    <Grid position={ 'relative' }
          container
          onKeyPress={ (key) => {
            if (key.charCode === 13 && document.getElementById('continueButton') !== null) {
              document.getElementById('continueButton')?.focus()
            }
          } }
          wrap={ 'nowrap' }
          direction="column"
          sx={ { minHeight: 'calc( 100vh - 60px)', maxWidth: '1000px', margin: '0 auto' } }>

      <Typography variant="h5" mb={ 2 } sx={ {
        fontWeight: 'bold',
        padding: '0 100px',
        marginTop: '25px',
        whiteSpace: 'pre-wrap',
        marginBottom: '35px',
        '@media(max-width: 768px)': {
          padding: '0 10vw'
        }
      } }>
        { question(formik.values) }
      </Typography>

      <StyledForm onSubmit={ formik.handleSubmit }>

        { children }
        {
          isUserExist
            ? <>
              <Typography sx={ {
                marginTop: '4vh',
                fontStyle: 'italic',
                padding: '0 100px',
                lineHeight: 1.3,
                fontSize: '18px',
                textAlign: 'center',
                fontWeight: '300',
                '@media(max-width: 768px)': {
                  padding: '0 10vw'
                }
              } }>
                A member account already exists for this email or phone number. Would you like to sign into your account?
              </Typography>
              <div className={classes.userExistButtonsWrapper}>
                <ColoredButton
                  className={classes.userExistButton}
                  id='continueButton'
                  onClick={
                    () => navigate('/login')
                  }
                >
                  <Typography variant="h5">
                    Yes
                  </Typography>
                </ColoredButton>
                <ColoredButton
                  className={classes.userExistButton}
                  id='continueButton'
                  onClick={
                    () => window.location.reload()
                  }
                >
                  <Typography variant="h5">
                    No
                  </Typography>
                </ColoredButton>
              </div>
            </>
            : description && <Typography sx={ {
              marginTop: '4vh',
              fontStyle: 'italic',
              padding: '0 100px',
              lineHeight: 1.3,
              fontSize: '18px',
              textAlign: 'center',
              fontWeight: '300',
              '@media(max-width: 768px)': {
                padding: '0 10vw'
              }
            } }>
            { description }
          </Typography>

        }

        { !isUserExist && (current !== 'Q23' || status === 3) && formik.isValid && isValidated && (
          <Grid container justifyContent="center" alignItems="center" sx={ {
            height: '50px', mt: { xs: '50px', sm: '50px' }, mb: '30px'
          } }>
            <ColoredButton
              id='continueButton'
              onClick={
                () => setTimeout(() => current !== 'Q23' ? handleClick() : navigate('/success'),
                  300
                )
              }
            >
              <Typography variant="h5">
                Continue
              </Typography>
            </ColoredButton>

          </Grid>
        ) }
      </StyledForm>
    </Grid>
  )
})
