import React, { useCallback, useEffect, useState } from 'react'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { getAppVersion } from './shared/version'
import { Header } from './app/Layout/Header'
import { ThemeProvider } from 'styled-components'
import { muiTheme } from './shared/theme/muiTheme'
import { theme } from './shared/theme/theme'
import { BaseStyles } from './shared/theme/baseStyles'
import { AppRoutes } from './shared/Routes'
import { useLocation, useSearchParams } from 'react-router-dom'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { FormikContext } from './shared/FormikContext'
import { DataContext } from './shared/DataContext'
import { OtherSectionsContext } from './shared/OtherSectionsContext'
import { ShowChatContext } from './shared/ShowChatContext'
import { QueryClientProvider } from 'react-query'
import { queryClient } from './QueryClient'
import { ToastContainer } from 'react-toastify'
import { ChatWrap } from './app/Modules/Chat/ChatWrap'
import axios from 'axios'
import ChatIcon from '@mui/icons-material/Chat'
import {
  getAuthUserPackage,
  getAuthUserPackageByMemberId
} from './shared/user/userQueries'
import {
  getMemberCurrentValues,
  getMemberDetailsValues,
  findGetParameter
} from './shared/functions'
import { IconButton } from '@mui/material'

const AUTH_TOKEN_KEY = 'authToken'
const MEMBER_PARAM_NAME = 'memberId'

window.addEventListener('unload', () => {
  const identityId = sessionStorage.getItem('identityId')
  const threadId = sessionStorage.getItem('threadId')
  const memberId = sessionStorage.getItem('memberId')
  const authToken = sessionStorage.getItem(AUTH_TOKEN_KEY)
  const headers = {
    type: 'application/json'
  }
  const blob = new Blob([JSON.stringify({ memberName: 'Name' })], headers)
  if (identityId && threadId) {
    if (authToken) {
      navigator.sendBeacon(`/Api/Acs/Chat/ClosingNotification/Member/${memberId}/${threadId}/${identityId}`,
        blob)
    } else {
      navigator.sendBeacon(`/Api/Acs/Chat/ClosingNotification/Visitor/${threadId}/${identityId}`)
    }
  }
})

const src = findGetParameter('src')
if (src) {
  sessionStorage.setItem('src', src)
}

function App () {
  const ScrollToTop = () => {
    const { pathname } = useLocation()
    useEffect(() => {
      if (pathname !== '/success') {
        window.scrollTo(0, 0)
      }
    }, [pathname])

    return null
  }

  const [showChat, setShowChat] = useState(false)
  const [currentFormikValues, setCurrentFormikValues] = useState({})
  const [currentData, setCurrentData] = useState<any>(false)
  const [currentOtherSectionsData, setCurrentOtherSectionsData] =
    useState(false)
  const saveCurrentFormikValues = (values: {}) => {
    setCurrentFormikValues(values)
  }
  const [searchParams, setSearchParams] = useSearchParams()
  const [isAuthChecked, setIsAuthChecked] = useState<false | any>(false)

  const setAxiosAuthHeader = (
    token: string | null = sessionStorage.getItem(AUTH_TOKEN_KEY)
  ) => {
    if (!token) {
      return
    }
    axios.defaults.headers.common.authorization = `Bearer ${token}`
  }

  const getAuth = useCallback(() => {
    const authToken = searchParams.get(AUTH_TOKEN_KEY)
    const memberId = searchParams.get(MEMBER_PARAM_NAME)

    if (authToken && memberId) {
      setAxiosAuthHeader(authToken)

      return getAuthUserPackageByMemberId(memberId).then((res) => {
        sessionStorage.setItem(AUTH_TOKEN_KEY, res.data.token)
        setAxiosAuthHeader()
        setSearchParams('')

        return res
      })
    }

    return getAuthUserPackage(memberId)
  }, [])

  setAxiosAuthHeader()

  useEffect(() => {
    if (!currentData) {
      getAuth()
        .then((response: any) => {
          if (response.data.memberId) {
            const memberId = response.data.memberId
            getMemberDetailsValues(memberId)
              .then((response: any) => {
                if (response.data.moveDate) {
                  const dateAr = response.data.moveDate.split('-')
                  response.data.moveDate =
                    dateAr[1] +
                    '/' +
                    dateAr[2].substring(0, 2) +
                    '/' +
                    dateAr[0]
                }
                const currentStateWithDetail = response.data
                getMemberCurrentValues(memberId)
                  .then((response: any) => {
                    const currentStateWithMember = {
                      ...currentStateWithDetail,
                      ...response.data
                    }
                    setCurrentData(currentStateWithMember)
                    setCurrentFormikValues(currentStateWithMember)
                    setCurrentOtherSectionsData(currentStateWithMember)
                  })
                  .catch((err: any) => {
                    console.error(err)
                  })
              })
              .catch((err: any) => {
                console.error(err)
              })
          } else {
            if (
              window.location.pathname === '/success' ||
              (window.location.pathname.indexOf('journey') !== -1 &&
                window.location.pathname.indexOf('Q1') === -1)
            ) {
              window.location.href = '/login'
            }
          }
        })
        .catch((err: any) => {
          if (
            window.location.pathname === '/success' ||
            (window.location.pathname.indexOf('journey') !== -1 &&
              window.location.pathname.indexOf('Q1') === -1)
          ) {
            window.location.href = '/login'
          }
          console.error(err)
        })
        .finally(() => {
          setIsAuthChecked(true)
        })

      return
    }
    setIsAuthChecked(true)
  }, [currentData])

  if (!isAuthChecked) {
    return null
  }

  return (
    <ThemeProvider theme={{ ...muiTheme, ...theme }}>
      <QueryClientProvider client={queryClient}>
        <MuiThemeProvider theme={muiTheme}>
          <HelmetProvider>
            <Helmet>
              <meta name="version" content={getAppVersion()} />
            </Helmet>
            <ScrollToTop />
            <ShowChatContext.Provider
              value={{ showChat, setShowChat }}
            >
              <OtherSectionsContext.Provider
                value={{ currentOtherSectionsData, setCurrentOtherSectionsData }}
              >
                <DataContext.Provider value={{ currentData, setCurrentData }}>
                  <FormikContext.Provider
                    value={{ currentFormikValues, saveCurrentFormikValues }}
                  >
                    <BaseStyles />
                    <Header />
                    <AppRoutes />
                    <ToastContainer />
                    {
                      showChat && <ChatWrap
                          memberId={currentData && currentData.memberId ? currentData.memberId : null}
                          closeHandler={setShowChat}
                      />
                    }
                    {
                      !showChat && <div style={{
                        position: 'fixed',
                        bottom: '8%',
                        right: '10px',
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        background: 'rgb(237,31,138)',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                      }}>
                        <IconButton
                          style={{ left: '7px', top: '2px' }}
                          edge="start"
                          color="secondary"
                          onClick={ () => { setShowChat(true) } }
                        >
                          <ChatIcon sx={ { transform: 'scale(1.4)' } }/>
                        </IconButton>
                      </div>
                    }
                  </FormikContext.Provider>
                </DataContext.Provider>
              </OtherSectionsContext.Provider>
            </ShowChatContext.Provider>
          </HelmetProvider>
        </MuiThemeProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App
