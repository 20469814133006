import { useEffect, useState } from 'react'
import { SolutionOddsBlock } from './SolutionOddsBlock'
import { Box, Typography } from '@mui/material'
import useCurrentData from '../../../../../shared/hooks/useCurrentData'
import { getQualificationIssue, postQualificationIssue } from '../../../../../shared/user/userQueries'
import CircularProgress from '@mui/material/CircularProgress'

export const SolutionOdds = () => {
  const { currentData } = useCurrentData()
  const [blocksValues, setBlocksValues] = useState<any>(false)
  useEffect(() => {
    if (!blocksValues && currentData.memberId) {
      const params = {
        memberId: currentData.memberId
      }
      postQualificationIssue(params)
        .then((response: any) => {
          const paramsGet = {
            memberId: currentData.memberId
          }
          getQualificationIssue(paramsGet)
            .then((response: any) => {
              setBlocksValues(response.data)
            })
            .catch((err: any) => {
              console.error(err)
            })
        })
        .catch((err: any) => {
          console.error(err)
        })
    }
  }, [currentData.memberId])
  return (
    <>
      {
        !blocksValues
          ? <Box
            sx={ {
              display: 'flex',
              justifyContent: 'center',
              marginTop: '50px',
              width: '100%'
            } }
            color="primary"
          >
            <CircularProgress/>
          </Box>
          : blocksValues.length > 0
            ? blocksValues.map((block: any, index: any) => {
              return <SolutionOddsBlock
              blocksValues={ blocksValues }
              setBlocksValues={ setBlocksValues }
              key={ index }
              { ...block }
            />
            })
            : blocksValues && <Box
              sx={ {
                display: 'flex',
                maxWidth: '800px',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: '#eee',
                padding: '20px',
                margin: '30px auto',
                borderRadius: '3px',
                '@media screen and (max-width : 800px)': {
                  margin: '30px 15px'
                }
              } }
              color="primary"
            >
              <Typography marginBottom='20px' variant="h5" textAlign="center" color="#666">
                Congratulations!
              </Typography>
              <Typography marginBottom='20px' variant="h6" textAlign="center" color="#666">
                Our initial analysis did not identify any major issues.
                Chat with your HomeQual Advisor to discuss the best ways to present yourself to a lender.
                You have one shot to make a great first impression so make it count
              </Typography>
            </Box>
      }
    </>
  )
}
