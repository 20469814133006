import React, { FC, memo } from 'react'
import { Grid, Radio, Typography } from '@mui/material'

interface IRadioFieldCoborrowerQuestions {
  question: any
  handleChange: any
  allValues: any
}

export const RadioFieldCoborrowerQuestions: FC<IRadioFieldCoborrowerQuestions> = memo(({
  question,
  handleChange,
  allValues
}) => {
  return (
    <>
      <Grid container direction="column" wrap="nowrap" sx={ { width: 'auto' } }>
        { question.answers.map((item: any, index: number) => (
          <Grid container key={ index } sx={ { margin: '5px 0' } }>
            <Radio
              onChange={ (event) => {
                handleChange(event)
              } }
              checked={
                (!allValues.coBorrowerFlag && item.value === '0') ||
                (allValues.coBorrowerFlag && allValues.maritalStatus === 1 && item.value === '1') ||
                (allValues.coBorrowerFlag && allValues.maritalStatus !== 1 && item.value === '2') || false
              }
              id={ item.text + index }
              value={ item.value }
              name={ question.formName }
              sx={ { transform: 'scale(1)', marginRight: '15px' } }
            />
            <label htmlFor={ item.text + index }>
              <Typography
                sx={ {
                  fontSize: '20px',
                  lineHeight: '37px',
                  '@media screen and (max-width : 600px)': {
                    fontSize: '16px'
                  }
                } }
              >{ item.text }</Typography>
            </label>
          </Grid>
        )) }
      </Grid>
    </>
  )
})
