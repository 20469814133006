import { FC, memo, useState, useEffect } from 'react'
import { basicData } from '../shared'
import { Wrapper } from '../../Wrapper'
import { CircularProgress, Grid, Typography } from '@mui/material'
import doneArrow from '../../../../../../assets/images/doneArrow.svg'
import { basicTypes } from '../../../questions'
import useCurrentFormikValues from '../../../../../../shared/hooks/useCurrentFormikValues'
import { setDataToState } from '../../../../../../shared/functions'
import useCurrentData from '../../../../../../shared/hooks/useCurrentData'
import useCurrentOtherSectionsData from '../../../../../../shared/hooks/useCurrentOtherSectionsData'

interface IProps extends basicTypes, basicData {
}

export const CreateEndQuestion: FC<IProps> = memo(({
  wrapperProps
}) => {
  const [status, setStatus] = useState(-1)
  const [dataIsSetted, setDataIsSetted] = useState(false)
  const { currentFormikValues } = useCurrentFormikValues()
  const items = [
    'Analyzing Financial',
    'Identifying Potential Issues',
    'Calculating Cash Reward',
    'Building Success Plan'
  ]
  const { setCurrentData } = useCurrentData()
  const { setCurrentOtherSectionsData } = useCurrentOtherSectionsData()
  useEffect(() => {
    if (!dataIsSetted) {
      setDataToState(currentFormikValues.memberId, setCurrentData)
      setDataToState(currentFormikValues.memberId, setCurrentOtherSectionsData)
      setDataIsSetted(true)
    }
  }, [])

  return (
    <Wrapper { ...wrapperProps } status={ status }>

      <Typography variant="h5" mb={ 2 } sx={ {
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '0 100px',
        marginTop: '25px',
        whiteSpace: 'pre-wrap',
        marginBottom: '35px',
        '@media(max-width: 768px)': {
          padding: '0 10vw'
        }
      } }>
        That’s it. Give me a second
        to crunch some numbers.
      </Typography>

      { items.map((item, index) => {
        if (index === status + 1) {
          setTimeout(() => setStatus(status + 1), 500)
        }

        return (
          <Grid container
                key={ index }
                direction="row"
                sx={ {
                  padding: '0 15%',
                  margin: '2vh auto',
                  maxWidth: '80%',
                  justifyContent: 'space-between',
                  '@media(max-width: 1024px)': {
                    padding: '0 10vw'
                  },
                  '@media(max-width: 600px)': {
                    maxWidth: '90%'
                  }
                } }
                alignItems="center"
                justifyContent="space-between"
                wrap="nowrap">

            <Typography variant="h6" color="primary" sx={ { verticalAlign: 'bottom', fontWeight: 'regular' } }>
              { item }
            </Typography>
            { status >= index
              ? <img src={ doneArrow } width={ 35 } height={ 35 } alt="arrow"/>
              : <CircularProgress color="primary" sx={ {
                marginLeft: '10px',
                minWidth: '35px',
                minHeight: '35px',
                maxWidth: '35px',
                maxHeight: '35px'
              } }/>
            }
          </Grid>
        )
      }) }
    </Wrapper>
  )
})
