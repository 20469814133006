import { useEffect, useState } from 'react'
import { Box, Typography, Link } from '@mui/material'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { UpdateYourInformationPopup } from './UpdateYourInformationPopup'
import useCurrentOtherSectionsData from '../../../../../shared/hooks/useCurrentOtherSectionsData'

interface IUpdateYourInformationBlock {
  questions?: any
  type?: string
}

const useStyles = makeStyles({
  row: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  label: {
    display: 'inline',
    fontSize: '23px',
    fontWeight: 'bold',
    marginBottom: '0',
    color: '#666',
    '@media(max-width: 600px)': {
      fontSize: '18px'
    },
    '@media(max-width: 400px)': {
      fontSize: '15px'
    },
    '@media(max-width: 340px)': {
      fontSize: '12px'
    }
  },
  link: {
    fontSize: '23px',
    top: '9px',
    width: 'auto',
    '@media(max-width: 600px)': {
      fontSize: '18px'
    },
    '@media(max-width: 400px)': {
      fontSize: '15px'
    },
    '@media(max-width: 340px)': {
      fontSize: '12px'
    }
  }
})

export const UpdateYourInformationBlock = (props: IUpdateYourInformationBlock) => {
  const classes = useStyles()
  const [showPopup, setShowPopup] = useState(false)
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0)
  const { questions, type } = props
  const question = questions[currentQuestionNumber]
  const [isLastQuestion, setIsLastQuestion] = useState((currentQuestionNumber + 1) >= questions.length)
  const { currentOtherSectionsData } = useCurrentOtherSectionsData()
  const [allValues, setAllValues] = useState(currentOtherSectionsData)
  useEffect(() => {
    const isHaveStudentLoan = allValues.lateStudentLoanFlag
    const isHaveTax = allValues.taxLienJudgementForeclosureFlag
    if (type === 'paymentHistory' && question.current === 'QE12' && !isHaveStudentLoan && !isHaveTax) {
      setIsLastQuestion(true)
    } else if (type === 'borrowerLoans' && question.current === 'QE18' && !allValues.coBorrowerFlag) {
      setIsLastQuestion(true)
    } else {
      setIsLastQuestion((currentQuestionNumber + 1) >= questions.length)
    }
  }, [allValues, currentQuestionNumber])

  const nextQuestionChoice = () => {
    const isHaveStudentLoan = allValues.lateStudentLoanFlag
    const isHaveTax = allValues.taxLienJudgementForeclosureFlag
    const isCoHaveStudentLoan = allValues.coLateStudentLoanFlag
    const isCoHaveTax = allValues.coTaxLienJudgementForeclosureFlag
    const newCurrentData = Object.assign({}, allValues)
    if (type === 'borrowerLoans') {
      if (question.current === 'QE12' && !isHaveStudentLoan && !isHaveTax && !isCoHaveStudentLoan && !isCoHaveTax) {
        newCurrentData.studentLoanDefault = ''
        newCurrentData.coStudentLoanDefault = ''
        newCurrentData.taxDetails = ''
        newCurrentData.coTaxDetails = ''
        setAllValues(newCurrentData)
        setCurrentQuestionNumber(currentQuestionNumber + 3)
      } else if (
        question.current === 'QE12' &&
          !isHaveStudentLoan &&
          !isCoHaveStudentLoan &&
          (isHaveTax || isCoHaveTax)) {
        newCurrentData.studentLoanDefault = ''
        newCurrentData.coStudentLoanDefault = ''
        setAllValues(newCurrentData)
        setCurrentQuestionNumber(currentQuestionNumber + 2)
      } else if (question.current === 'QE14' && !isHaveTax && !isCoHaveTax) {
        newCurrentData.taxDetails = ''
        newCurrentData.coTaxDetails = ''
        setAllValues(newCurrentData)
        setCurrentQuestionNumber(currentQuestionNumber + 2)
      } else {
        setCurrentQuestionNumber(currentQuestionNumber + 1)
      }
    } else if (type === 'paymentHistory') {
      if (question.current === 'QE12' && !isHaveStudentLoan && !isHaveTax && !isCoHaveStudentLoan && !isCoHaveTax) {
        newCurrentData.studentLoanDefault = ''
        newCurrentData.coStudentLoanDefault = ''
        newCurrentData.taxDetails = ''
        newCurrentData.coTaxDetails = ''
        newCurrentData.taxLienFlag = false
        newCurrentData.foreclosure24MonthsFlag = false
        newCurrentData.foreclosure36MonthsFlag = false
        newCurrentData.foreclosure48MonthsFlag = false
        newCurrentData.judgementFlag = false
        newCurrentData.studentLoanDefaultFlag = false
        newCurrentData.studentLoanRemediationFlag = false
        newCurrentData.studentLoanDefaultNoneFlag = false
        newCurrentData.coTaxLienFlag = false
        newCurrentData.coForeclosure24MonthsFlag = false
        newCurrentData.coForeclosure36MonthsFlag = false
        newCurrentData.coForeclosure48MonthsFlag = false
        newCurrentData.coJudgementFlag = false
        newCurrentData.coStudentLoanDefaultFlag = false
        newCurrentData.coStudentLoanRemediationFlag = false
        newCurrentData.coStudentLoanDefaultNoneFlag = false
        setAllValues(newCurrentData)
      } else if (
        question.current === 'QE12' &&
          !isHaveStudentLoan &&
          !isCoHaveStudentLoan &&
          (isHaveTax || isCoHaveTax)) {
        newCurrentData.studentLoanDefault = ''
        newCurrentData.coStudentLoanDefault = ''
        newCurrentData.studentLoanDefaultFlag = false
        newCurrentData.studentLoanRemediationFlag = false
        newCurrentData.studentLoanDefaultNoneFlag = false
        newCurrentData.coStudentLoanDefaultFlag = false
        newCurrentData.coStudentLoanRemediationFlag = false
        newCurrentData.coStudentLoanDefaultNoneFlag = false
        setAllValues(newCurrentData)
        setCurrentQuestionNumber(currentQuestionNumber + 2)
      } else if (question.current === 'QE12' && !isHaveStudentLoan && !isHaveTax) {
        newCurrentData.studentLoanDefault = ''
        newCurrentData.taxDetails = ''
        newCurrentData.coTaxDetails = ''
        newCurrentData.taxLienFlag = false
        newCurrentData.foreclosure24MonthsFlag = false
        newCurrentData.foreclosure36MonthsFlag = false
        newCurrentData.foreclosure48MonthsFlag = false
        newCurrentData.judgementFlag = false
        newCurrentData.studentLoanDefaultFlag = false
        newCurrentData.studentLoanRemediationFlag = false
        newCurrentData.studentLoanDefaultNoneFlag = false
        setAllValues(newCurrentData)
      } else if (question.current === 'QE12' && isHaveTax && !isHaveStudentLoan) {
        newCurrentData.studentLoanDefault = ''
        newCurrentData.studentLoanDefaultFlag = false
        newCurrentData.studentLoanRemediationFlag = false
        newCurrentData.studentLoanDefaultNoneFlag = false
        setAllValues(newCurrentData)
        setCurrentQuestionNumber(currentQuestionNumber + 2)
      } else {
        setCurrentQuestionNumber(currentQuestionNumber + 1)
      }
    } else {
      setCurrentQuestionNumber(currentQuestionNumber + 1)
    }
  }

  return (
    <>
      <Box
        sx={ {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: '#fff',
          position: 'relative',
          padding: '5px 15px',
          borderRadius: '5px',
          marginTop: '10px',
          marginBottom: '10px'
        } }
        color='primary'
      >
        <Typography className={ classes.row }>
          <span className={ classes.label }>{ questions[0].label }</span>
          <Link
            className={ classes.link }
            onClick={ () => {
              setAllValues(currentOtherSectionsData)
              setShowPopup(true)
            } }
          >
            Edit
          </Link>
        </Typography>
      </Box>
      {
        showPopup && <UpdateYourInformationPopup
          label={ question.label }
          question={ question }
          constructor={ question.constructor }
          popUpHandler={ isLastQuestion ? () => setShowPopup(false) : () => nextQuestionChoice()}
          setShowPopup={ setShowPopup }
          isLastQuestion={ isLastQuestion }
          setCurrentQuestionNumber={ setCurrentQuestionNumber }
          allValues={ allValues }
          setAllValues={ setAllValues }
        />
      }
    </>
  )
}
