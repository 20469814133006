import { FC, memo } from 'react'
import { Grid, Radio, TextField, Typography } from '@mui/material'
import NumberFormat from 'react-number-format'
import makeStyles from '@material-ui/core/styles/makeStyles'

interface IRadioTextFieldQuestions {
  question: any
  handleChange: any
  allValues: any
}

export const RadioTextFieldQuestions: FC<IRadioTextFieldQuestions> = memo(({
  question,
  handleChange,
  allValues
}) => {
  const styles = makeStyles(theme => ({
    notchedOutline: {
      borderColor: '#ccc !important'
    }
  }))
  const classes = styles()

  return (
    <Grid container direction="column" sx={ {
      paddingLeft: '100px',
      '@media screen and (max-width : 768px)': {
        paddingLeft: '10vw'
      }
    } }>
      <Grid container direction="column" wrap="nowrap" sx={ { width: 'auto' } }>
        { question.answers.map((item: any, index: number) => (
          <Grid container key={ index } sx={ { margin: '5px 0' } }>
            <Radio
              onChange={ (event) => {
                handleChange(event)
              } }
              checked={ allValues[question.formName] === item.value || allValues[question.formName] === +item.value}
              id={ item.text + index }
              value={ item.value }
              name={ question.formName }
              sx={ { transform: 'scale(1.3)', marginRight: '15px' } }
            />
            <label htmlFor={ item.text + index }>
              <Typography
                sx={ {
                  fontSize: '20px',
                  '@media screen and (max-width : 600px)': {
                    fontSize: '16px'
                  }
                } }
              >{ item.text }</Typography>
            </label>
          </Grid>
        )) }
      </Grid>

      <Typography sx={ {
        fontWeight: 'bold',
        marginTop: '15px',
        marginBottom: '10px',
        fontSize: '23px',
        '@media screen and (max-width : 600px)': {
          fontSize: '20px'
        }
      } }>
        { question.subTitle1 }
      </Typography>

      { question.inputs.map((item: any, index: number) => <NumberFormat
        thousandSeparator={true}
        onChange={ (event: any) => {
          handleChange(event)
        } }
        customInput={TextField}
        sx={ {
          minWidth: '100px', maxWidth: '600px', paddingRight: '10vw', margin: '10px 0 5px 0'
        } }
        autoFocus={ index === 0 }
        variant="outlined"
        color="primary"
        allowLeadingZeros={ false }
        value={ allValues[item.value] || '' }
        name={ item.value }
        autoComplete='no'
        InputProps={ {
          style: { fontSize: '1.4rem', fontWeight: 'bold' },
          classes: {
            notchedOutline: classes.notchedOutline
          }
        } }
        InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
        label={
          <Typography variant="h6" color="#999" sx={ {
            fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
          } }>
            { item.text }
          </Typography> }
      />) }
      {
        allValues.coBorrowerFlag && question.co_answers && <>
            <Typography fontSize='23px' sx={ {
              fontWeight: 'bold',
              marginTop: '15px',
              marginBottom: '10px',
              fontSize: '23px',
              '@media screen and (max-width : 600px)': {
                fontSize: '20px'
              }
            } }>
              { question.subTitle2 }
            </Typography>

            <Grid container direction="column" wrap="nowrap" sx={ { width: 'auto' } }>
              { question.co_answers.map((item: any, index: number) => (
                <Grid container key={ index } sx={ { margin: '5px 0' } }>
                  <Radio
                    onChange={ (event: any) => {
                      handleChange(event)
                    } }
                    checked={
                      allValues[question.co_formName] === item.value ||
                        allValues[question.co_formName] === +item.value
                    }
                    id={ item.text + index }
                    value={ item.value }
                    name={ question.co_formName }
                    sx={ { transform: 'scale(1.3)', marginRight: '15px' } }
                  />
                  <label htmlFor={ item.text + index }>
                    <Typography
                      sx={ {
                        fontSize: '20px',
                        '@media screen and (max-width : 600px)': {
                          fontSize: '16px'
                        }
                      } }
                    >{ item.text }</Typography>
                  </label>
                </Grid>
              )) }
            </Grid>

            <Typography fontSize='23px' sx={ {
              fontWeight: 'bold',
              marginTop: '15px',
              marginBottom: '10px',
              fontSize: '23px',
              '@media screen and (max-width : 600px)': {
                fontSize: '20px'
              }
            } }>
              { question.subTitle3 }
            </Typography>

          { question.co_inputs.map((item: any, index: number) =>
            <NumberFormat
              allowNegative={false}
              thousandSeparator={true}
              customInput={TextField}
              sx={ {
                minWidth: '100px', maxWidth: '600px', paddingRight: '10vw', margin: '10px 0 5px 0'
              } }
              autoFocus={ index === 0 }
              onChange={ (event: any) => {
                handleChange(event)
              } }
              variant="outlined"
              color="primary"
              allowLeadingZeros={ false }
              value={ allValues[item.value] || '' }
              name={ item.value }
              autoComplete='no'
              InputProps={ {
                style: { fontSize: '1.4rem', fontWeight: 'bold' },
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              } }
              InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
              label={
                <Typography variant="h6" color="#999" sx={ {
                  fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
                } }>
                  { item.text }
                </Typography> }
            />) }
        </>
      }

    </Grid>
  )
})
