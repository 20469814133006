import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  IconButton,
  List,
  ListItem,
  Popover,
  Toolbar,
  Typography,
  useScrollTrigger
} from '@mui/material'
import { SyntheticEvent, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import hqlighticon from '../../assets/images/HomeQualIcon/hqlogolight.png'
import useCurrentFormikValues from '../../shared/hooks/useCurrentFormikValues'
import { getLastQuestionCompleted } from '../../shared/user/userQueries'
import { getMemberCurrentValues, getMemberDetailsValues, navigateToCompletedQuestion } from '../../shared/functions'
import { questionsList } from '../Modules/QuestionsAndAnswers/questions'
import { toast } from 'react-toastify'

const MenuItems = styled(Typography)`
  font-size   : 15px;
  font-weight : 500;
`
const HqLogo = styled.div`
  background      : url(${hqlighticon}) left center no-repeat;
  width           : 175px;
  height          : 60px;
  background-size : contain;
  cursor          : pointer;

  @media screen and (min-width : 600px) {
    height : 64px;
  }
`

export const Header = () => {
  const { currentFormikValues } = useCurrentFormikValues()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const location = useLocation()
  const navigate = useNavigate()
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: window.innerHeight,
    target: window
  })

  const handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const myAccountHandler = (memberId: number) => {
    getLastQuestionCompleted({ memberId })
      .then((response: any) => {
        if (response.data.errorId > 0) {
          toast.error('Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.')
        } else {
          const lastQuestionCompleted = response.data.lastQuestionCompleted
          getMemberDetailsValues(memberId)
            .then((response: any) => {
              const currentStateWithDetail = response.data
              if (response.data.moveDate) {
                const dateAr = response.data.moveDate.split('-')
                response.data.moveDate = dateAr[1] + '/' + dateAr[2].substring(0, 2) + '/' + dateAr[0]
              }
              getMemberCurrentValues(memberId, true)
                .then((response: any) => {
                  const currentStateWithMember = { ...currentStateWithDetail, ...response.data }
                  if (lastQuestionCompleted === 23) {
                    if (location.pathname.includes('success')) {
                      window.location.reload()
                      window.scrollTo(0, 0)
                    } else {
                      navigate('/success')
                    }
                  } else {
                    navigateToCompletedQuestion(
                      questionsList,
                      currentStateWithMember,
                      lastQuestionCompleted,
                      navigate
                    )
                  }
                  handleClose()
                })
                .catch((err: any) => {
                  console.error(err)
                })
            })
            .catch((err: any) => {
              console.error(err)
            })
        }
      })
      .catch((err: any) => {
        console.error(err)
      })
  }
  const menuItems = [
    {
      text: 'Buy a Home',
      link: 'buy_home/Q1'
    },
    {
      text: 'Find a Lender',
      link: 'find_lender/Q1'
    },
    {
      text: 'Find a Real Estate Agent',
      link: 'find_agent/Q1'
    },
    {
      text: 'Refinance',
      link: 'refinance/Q1'
    },
    {
      text: 'Speak with an Advisor',
      link: 'speak_with_advisor/Q1'
    },
    {
      text: 'FAQs',
      link: 'FAQs'
    },
    {
      text: 'Resources',
      link: 'resources'
    },
    {
      text: 'My Account',
      link: 'my_account',
      handler: myAccountHandler
    }
  ]

  return (
    <AppBar position={
      location.pathname.includes('journey') ||
      location.pathname.includes('refinance') ||
      location.pathname.includes('buy_home') ||
      location.pathname.includes('find_lender') ||
      location.pathname.includes('find_agent') ||
      location.pathname.includes('login') ||
      location.pathname.includes('forgot_your_password') ||
      location.pathname.includes('speak_with_advisor')
        ? 'relative'
        : 'fixed'
    } sx={ {
      zIndex: 2,
      transition: 'background-color 1s linear',
      bgcolor: location.pathname === '/' ? trigger ? 'white' : 'transparent' : 'white'
    } } elevation={ 0 }>
      <Toolbar sx={ { transition: 'linear 0.5s' } }>
        <IconButton edge="start" color="primary" onClick={ handleClick }>
          <MenuIcon sx={ { transform: 'scale(1.4)' } }/>
        </IconButton>

        <Popover id={ 'menuButton' } open={ open } anchorEl={ anchorEl } onClose={ handleClose } anchorOrigin={ {
          vertical: 'bottom',
          horizontal: 'left'
        } }>
          <List>
            { menuItems.map((item, index) => (
              <ListItem key={ index } sx={ { margin: '0 10px' } }>
                <MenuItems sx={ { cursor: 'pointer' } } onClick={ () => {
                  let link: any = item.link
                  if (item.handler) {
                    if (currentFormikValues.memberId) {
                      item.handler(currentFormikValues.memberId)
                      return
                    } else {
                      link = '/login'
                    }
                  }
                  navigate(link)
                  handleClose()
                } }>{ item.text }
                </MenuItems>
              </ListItem>
            )) }
          </List>
        </Popover>

        <HqLogo onClick={ () => navigate('/') }/>
      </Toolbar>
    </AppBar>
  )
}
