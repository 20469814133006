import {
  getMemberDetailValues,
  getMemberValues,
  initialMemberDetailUpdateParams, initialMemberUpdateParams,
  memberDetailUpdateQuery, memberUpdateQuery,
  userSpeakWithAdvisorGetQuery, userRefinanceGetQuery,
  userSpeakWithAdvisorPutQuery, userFindLenderGetQuery,
  userFindLenderPutQuery, userFindAgentGetQuery,
  userFindAgentPutQuery, userBuyHomeGetQuery,
  userBuyHomePutQuery, userRefinancePutQuery
} from './user/userQueries'

const mediaMatchTablet = window.matchMedia('(max-width: 1200px)')
const mediaMatchMobile = window.matchMedia('(max-width: 500px)')

export const isTablet = mediaMatchTablet.matches

export const isMobile = mediaMatchMobile.matches

export const navigateToCompletedQuestion = (
  questionsList: any,
  data: any,
  lastQuestionCompleted: number,
  navigate: any
) => {
  const isAlone = data.coBorrowerFlag === '0'
  const isHaveStudentLoan = data.lateStudentLoanFlag
  const isHaveTax = data.taxLienJudgementForeclosureFlag
  const isCoHaveStudentLoan = data.coLateStudentLoanFlag
  const isCoHaveTax = data.taxLienJudgementForeclosureFlag
  const isSpouce = data.coBorrowerFlag
  if (lastQuestionCompleted === 4 && isSpouce) {
    navigate('/journey/Q5')
  } else if (lastQuestionCompleted === 5 && isAlone) {
    navigate('/journey/Q7')
  } else if (lastQuestionCompleted === 6 && isSpouce) {
    navigate('/journey/Q7')
  } else if (lastQuestionCompleted === 18 && !isHaveStudentLoan && !isHaveTax) {
    navigate('/journey/Q20')
  } else if (lastQuestionCompleted === 18 && isHaveTax && !isHaveStudentLoan) {
    navigate('/journey/Q19')
  } else if (lastQuestionCompleted === 19 && !isHaveTax) {
    navigate('/journey/Q20')
  } else if (lastQuestionCompleted === 30 && !isHaveStudentLoan && !isHaveTax && !isCoHaveStudentLoan && !isCoHaveTax) {
    navigate('/journey/Q20C')
  } else if (lastQuestionCompleted === 30 && !isHaveStudentLoan && !isCoHaveStudentLoan && (isHaveTax || isCoHaveTax)) {
    navigate('/journey/Q19C')
  } else if (lastQuestionCompleted === 31 && !isHaveTax && !isCoHaveTax) {
    navigate('/journey/Q20C')
  } else if (lastQuestionCompleted === 12 && !isAlone) {
    navigate('/journey/Q12C')
  } else {
    for (const k in questionsList) {
      const question = questionsList[k as keyof typeof questionsList]
      if (question.qNumber === lastQuestionCompleted + 1) {
        navigate(`/journey/${question.current}`)
        return
      }
    }
  }
}

export const getMemberDetailsValues = (memberId: number) => {
  return getMemberDetailValues({ memberId })
}

export const updateMemberDetail = (
  formikValues: any,
  memberId: number,
  currentMemberDetailValues: any,
  qNumber: number,
  formik: any = false
) => {
  const currentValues = currentMemberDetailValues || initialMemberDetailUpdateParams
  const newParams = Object.assign({}, currentValues)
  for (const key in newParams) {
    const keyName = key as keyof typeof newParams
    if ((
      keyName === 'monthlyPaymentAmount' ||
      keyName === 'homeSavings' ||
      keyName === 'monthlyIncome' ||
      keyName === 'coMonthlyIncome' ||
      keyName === 'autoExpense' ||
      keyName === 'creditCardExpense' ||
      keyName === 'studentLoans' ||
      keyName === 'otherExpenses' ||
      keyName === 'coAutoExpense' ||
      keyName === 'coCreditCardExpense' ||
      keyName === 'coStudentLoans' ||
      keyName === 'coOtherExpenses' ||
      keyName === 'housingPaymentAmount' ||
      keyName === 'coHousingPaymentAmount'
    ) && formikValues[keyName] && typeof formikValues[keyName] === 'string') {
      formikValues[keyName] = +(formikValues[keyName].replace(/,/g, ''))
    }
    if (formikValues[keyName] === '') {
      formikValues[keyName] = null
    }
    if (keyName in formikValues) copyField(keyName, newParams, formikValues)
  }
  let moveDate = formikValues.moveDate
  if (
    formikValues.moveDate &&
    typeof formikValues.moveDate === 'string' &&
    formikValues.moveDate.indexOf('/') !== -1
  ) {
    const moveDateAr = formikValues.moveDate.split('/')
    moveDate = moveDateAr[2] + '-' + moveDateAr[0] + '-' + moveDateAr[1]
  }
  if (!+formikValues.coBorrowerFlag) {
    formikValues.coMonthlyIncome = null
    formikValues.coAutoExpense = null
    formikValues.coCreditCardExpense = null
    formikValues.coStudentLoans = null
    formikValues.coOtherExpenses = null
    formikValues.coEmploymentGapsFlag = false
    formikValues.coChangedJobsFlag = false
    formikValues.coSelfEmployedFlag = false
    formikValues.coNoIncomeDocumentationFlag = false
    formikValues.coCommissionsBonusFlag = false
    formikValues.coCreditScoreRange = null
    formikValues.coLatePaymentsFlag = false
    formikValues.coCollectionsFlag = false
    formikValues.coLateStudentLoanFlag = false
    formikValues.coLateHousingPaymentsFlag = false
    formikValues.coTaxLienJudgementForeclosureFlag = false
    formikValues.coTaxLienFlag = false
    formikValues.coForeclosure24MonthsFlag = false
    formikValues.coForeclosure36MonthsFlag = false
    formikValues.coForeclosure48MonthsFlag = false
    formikValues.coJudgementFlag = false
    formikValues.coBankruptcyFlag = false
    formikValues.coTaxesNotCurrentFlag = false
    formikValues.coStudentLoanDefaultFlag = false
    formikValues.coStudentLoanRemediationFlag = false
    formikValues.coLivingSituation = null
    formikValues.coHousingPaymentAmount = null
    formikValues.coMaritalStatus = 0
    formikValues.coVeteranFlag = false
    formikValues.schoolAgeChildrenFlag = false
  }
  const memberUpdateDetailParams = {
    ...newParams,
    memberId,
    veteranFlag: !!+formikValues.veteranFlag,
    coVeteranFlag: !!+formikValues.coVeteranFlag,
    studentLoanDefaultFlag: formikValues.studentLoanDefault
      ? formikValues.studentLoanDefault === 'studentLoanDefaultFlag'
      : formikValues.studentLoanDefaultFlag,
    studentLoanRemediationFlag: formikValues.studentLoanDefault
      ? formikValues.studentLoanDefault === 'studentLoanRemediationFlag'
      : formikValues.studentLoanRemediationFlag,
    coStudentLoanDefaultFlag: formikValues.coStudentLoanDefault
      ? formikValues.coStudentLoanDefault === 'coStudentLoanDefaultFlag'
      : formikValues.coStudentLoanDefaultFlag,
    coStudentLoanRemediationFlag: formikValues.coStudentLoanDefault
      ? formikValues.coStudentLoanDefault === 'coStudentLoanRemediationFlag'
      : formikValues.coStudentLoanRemediationFlag,
    taxLienFlag: formikValues.taxDetails ? formikValues.taxDetails === 'taxLienFlag' : formikValues.taxLienFlag,
    foreclosure24MonthsFlag: formikValues.taxDetails
      ? formikValues.taxDetails === 'foreclosure24MonthsFlag'
      : formikValues.foreclosure24MonthsFlag,
    foreclosure36MonthsFlag: formikValues.taxDetails
      ? formikValues.taxDetails === 'foreclosure36MonthsFlag'
      : formikValues.foreclosure36MonthsFlag,
    foreclosure48MonthsFlag: formikValues.taxDetails
      ? formikValues.taxDetails === 'foreclosure48MonthsFlag'
      : formikValues.foreclosure48MonthsFlag,
    judgementFlag: formikValues.taxDetails
      ? formikValues.taxDetails === 'judgementFlag'
      : formikValues.judgementFlag,
    coTaxLienFlag: formikValues.coTaxDetails
      ? formikValues.coTaxDetails === 'coTaxLienFlag'
      : formikValues.coTaxLienFlag,
    coForeclosure24MonthsFlag: formikValues.coTaxDetails
      ? formikValues.coTaxDetails === 'coForeclosure24MonthsFlag'
      : formikValues.coForeclosure24MonthsFlag,
    coForeclosure36MonthsFlag: formikValues.coTaxDetails
      ? formikValues.coTaxDetails === 'coForeclosure36MonthsFlag'
      : formikValues.coForeclosure36MonthsFlag,
    coForeclosure48MonthsFlag: formikValues.coTaxDetails
      ? formikValues.coTaxDetails === 'coForeclosure48MonthsFlag'
      : formikValues.coForeclosure48MonthsFlag,
    coJudgementFlag: formikValues.coTaxDetails
      ? formikValues.coTaxDetails === 'coJudgementFlag'
      : formikValues.coJudgementFlag,
    moveDate,
    coBorrowerFlag: !!+formikValues.coBorrowerFlag,
    lastQuestionCompleted: qNumber
  }
  return memberDetailUpdateQuery(memberUpdateDetailParams)
    .then((response: any) => {
      if ((qNumber === 4 || qNumber === 23) && formik) {
        formik.resetForm({
          values: formikValues
        })
        formik.validateForm()
      }
      console.log(response)
    })
    .catch((err: any) => {
      console.error(err)
    })
}

export const getMemberCurrentValues = (memberId: number, isMyAccount: boolean = false) => {
  if (isQaSection() || isMyAccount) {
    return getMemberValues({ memberId })
  } else if (isBuyHomeSection()) {
    return userBuyHomeGetQuery(memberId)
  } else if (isFindLenderSection()) {
    return userFindLenderGetQuery(memberId)
  } else if (isFindAgentSection()) {
    return userFindAgentGetQuery(memberId)
  } else if (isRefinanceSection()) {
    return userRefinanceGetQuery(memberId)
  } else if (isSpeakWithAdvisorSection()) {
    return userSpeakWithAdvisorGetQuery(memberId)
  } else {
    return getMemberValues({ memberId })
  }
}

export const updateMember = (formikValues: any, currentMemberValues: any, formik: any = false) => {
  const currentValues = currentMemberValues || initialMemberUpdateParams
  const newMemberUpdateParams = Object.assign({}, currentValues)
  for (const key in newMemberUpdateParams) {
    const keyName = key as keyof typeof newMemberUpdateParams
    if ((
      keyName === 'cellPhone'
    ) && formikValues[keyName] && typeof formikValues[keyName] === 'string') {
      formikValues[keyName] = formikValues[keyName]
        .replace('-', '')
        .replace('(', '')
        .replace(')', '')
        .replace(' ', '')
    }
    if (
      !isSpeakWithAdvisorSection() &&
      !isFindLenderSection() &&
      !isFindAgentSection() &&
      !isRefinanceSection() &&
      !isBuyHomeSection()
    ) {
      if (keyName in formikValues) copyField(keyName, newMemberUpdateParams, formikValues)
    }
  }
  let closeDate = formikValues.closeDate
  if (
    formikValues.closeDate &&
    typeof formikValues.closeDate === 'string' &&
    formikValues.closeDate.indexOf('/') !== -1
  ) {
    const closeDateAr = formikValues.closeDate.split('/')
    closeDate = closeDateAr[2] + '-' + closeDateAr[0] + '-' + closeDateAr[1]
  }
  if (!+formikValues.coBorrowerFlag) {
    formikValues.coRelationshipId = 0
    formikValues.coNameTitleId = ''
    formikValues.coFirstName = ''
    formikValues.coLastName = ''
    formikValues.coAddress = ''
    formikValues.coCity = ''
    formikValues.coStateId = ''
    formikValues.coZipCode = ''
    formikValues.coEmailAddress = ''
    formikValues.coCellPhone = 0
    formikValues.coCommunicationPreferenceId = 0
    formikValues.coCommunicationOptOutStatusId = 0
    formikValues.memberCoBorrowerId = 0
  }
  const memberUpdateParams = {
    ...newMemberUpdateParams,
    memberId: currentValues.memberId,
    coBorrowerFlag: !!+formikValues.coBorrowerFlag,
    coRelationshipId: +formikValues.coBorrowerFlag,
    closeDate
  }
  if (isQaSection()) {
    return memberUpdateQuery(memberUpdateParams)
      .then((response: any) => {
        if (formik) {
          formik.resetForm({
            values: formikValues
          })
          formik.validateForm()
        }
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
      })
  } else if (isBuyHomeSection()) {
    return userBuyHomePutQuery(memberUpdateParams)
      .then((response: any) => {
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
      })
  } else if (isFindLenderSection()) {
    return userFindLenderPutQuery(memberUpdateParams)
      .then((response: any) => {
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
      })
  } else if (isFindAgentSection()) {
    return userFindAgentPutQuery(memberUpdateParams)
      .then((response: any) => {
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
      })
  } else if (isRefinanceSection()) {
    return userRefinancePutQuery(memberUpdateParams)
      .then((response: any) => {
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
      })
  } else if (isSpeakWithAdvisorSection()) {
    return userSpeakWithAdvisorPutQuery(memberUpdateParams)
      .then((response: any) => {
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
      })
  } else {
    return memberUpdateQuery(memberUpdateParams)
      .then((response: any) => {
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
      })
  }
}
export const setDataToState = (memberId: number, handle: any) => {
  getMemberDetailsValues(memberId)
    .then((response: any) => {
      const currentStateWithDetail = response.data
      if (response.data.moveDate) {
        const dateAr = response.data.moveDate.split('-')
        response.data.moveDate = dateAr[1] + '/' + dateAr[2].substring(0, 2) + '/' + dateAr[0]
      }
      getMemberCurrentValues(memberId)
        .then((response: any) => {
          const currentStateWithMember = { ...currentStateWithDetail, ...response.data }
          handle(currentStateWithMember)
        })
        .catch((err: any) => {
          console.error(err)
        })
    })
    .catch((err: any) => {
      console.error(err)
    })
}

export const copyField = <T extends {}>(k: keyof T, target: T, source: T) => {
  target[k] = source[k]
}

export const numberWithCommas = (number: number) => {
  if (!number) {
    return ''
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const isQaSection = () => {
  return window.location.pathname.indexOf('journey') !== -1
}
export const isBuyHomeSection = () => {
  return window.location.pathname.indexOf('buy_home') !== -1
}
export const isFindLenderSection = () => {
  return window.location.pathname.indexOf('find_lender') !== -1
}
export const isFindAgentSection = () => {
  return window.location.pathname.indexOf('find_agent') !== -1
}
export const isRefinanceSection = () => {
  return window.location.pathname.indexOf('refinance') !== -1
}
export const isSpeakWithAdvisorSection = () => {
  return window.location.pathname.indexOf('speak_with_advisor') !== -1
}

export const phoneFormat = (s: any) => {
  const stringNumb = s.toString()
  const phone = stringNumb.replace(/[^0-9]/g, '')
  return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, '($1) $2-$3$4')
}

export const findGetParameter = (parameterName: string) => {
  let result = null
  let tmp = []
  window.location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=')
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
    })
  return result
}
