import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Dashboard } from '../app/Modules/Dashboard/Dashboard'
import { QuestionsAndAnswers } from '../app/Modules/QuestionsAndAnswers/QuestionsAndAnswers'
import { PartnerWithUs } from '../app/Modules/SmallSections/PartnerWithUs/PartnerWithUs'
import { Careers } from '../app/Modules/SmallSections/Careers/Careers'
import { AboutUs } from '../app/Modules/SmallSections/AboutUs/AboutUs'
import { Lending } from '../app/Modules/SmallSections/Lending/Lending'
import { RealEstate } from '../app/Modules/SmallSections/RealEstate/RealEstate'
import { FAQ } from '../app/Modules/SmallSections/FAQ/FAQ'
import { NotFound } from '../app/Modules/NotFound/NotFound'
import { Resources } from '../app/Modules/Resources/Resources'
import { PrivacyPolicy } from '../app/Modules/PrivacyPolicy/PrivacyPolicy'
import { TermsOfUse } from '../app/Modules/TermsOfUse/TermsOfUse'
import { SuccessPlan } from '../app/Modules/SuccessPlan/SuccessPlan'
import { Login } from '../app/Modules/Login/Login'
import { ForgotYourPassword } from '../app/Modules/Login/ForgotYourPassword'
import {
  questionsList,
  refinanceQuestionsList,
  otherSectionsQuestionsList
} from '../app/Modules/QuestionsAndAnswers/questions'
import { QuestionsSchema, refinanceQuestionsSchema } from '../app/Modules/QuestionsAndAnswers/validation'

const mainRoutes = [
  {
    path: '/',
    element: <Dashboard/>
  },
  {
    path: 'buy_home/*',
    element: <QuestionsAndAnswers QuestionsSchema={ QuestionsSchema } questionsList={ otherSectionsQuestionsList }/>
  },
  {
    path: 'find_lender/*',
    element: <QuestionsAndAnswers QuestionsSchema={ QuestionsSchema } questionsList={ otherSectionsQuestionsList }/>
  },
  {
    path: 'find_agent/*',
    element: <QuestionsAndAnswers QuestionsSchema={ QuestionsSchema } questionsList={ otherSectionsQuestionsList }/>
  },
  {
    path: 'speak_with_advisor/*',
    element: <QuestionsAndAnswers QuestionsSchema={ QuestionsSchema } questionsList={ otherSectionsQuestionsList }/>
  },
  {
    path: 'journey/*',
    element: <QuestionsAndAnswers QuestionsSchema={ QuestionsSchema } questionsList={ questionsList }/>
  },
  {
    path: 'refinance/*',
    element: <QuestionsAndAnswers
        QuestionsSchema={ refinanceQuestionsSchema }
        questionsList={ refinanceQuestionsList }
      />
  },
  {
    path: '/partner_with_us',
    element: <PartnerWithUs/>
  },
  {
    path: '/careers',
    element: <Careers/>
  },
  {
    path: '/about_us',
    element: <AboutUs/>
  },
  {
    path: '/resources/*',
    element: <Resources/>
  },
  {
    path: '/FAQs',
    element: <FAQ/>
  },
  {
    path: '/privacy_policy',
    element: <PrivacyPolicy/>
  },
  {
    path: '/terms_of_Use',
    element: <TermsOfUse/>
  },
  {
    path: '/success',
    element: <SuccessPlan/>
  },
  {
    path: '/login',
    element: <Login/>
  },
  {
    path: '/forgot_your_password',
    element: <ForgotYourPassword/>
  },
  {
    path: '/lending',
    element: <Lending/>
  },
  {
    path: '/real_estate',
    element: <RealEstate/>
  },
  {
    path: '/*',
    element: <NotFound/>
  }
]

export const AppRoutes = () => {
  return <Routes>
    { mainRoutes.map((item, index) => <Route key={ index } path={ item.path } element={ item.element }/>) }
  </Routes>
}
