import React, { FC, memo } from 'react'
import { Box, Grid, TextField, Typography } from '@mui/material'
import NumberFormat from 'react-number-format'
import makeStyles from '@material-ui/core/styles/makeStyles'

interface ITextNumberFieldQuestions {
  question: any
  handleChange: any
  allValues: any
}

export const TextNumberFieldQuestions: FC<ITextNumberFieldQuestions> = memo(({
  question,
  handleChange,
  allValues
}) => {
  const styles = makeStyles(theme => ({
    notchedOutline: {
      borderColor: '#ccc !important'
    },
    box: {
      '@media(max-width: 500px)': {
        maxWidth: '100px !important'
      }
    }
  }))
  const classes = styles()

  return (
    <>
    {
      !allValues.coBorrowerFlag || !question.coInputs
        ? <Grid container direction="column" sx={ { paddingLeft: '10vw' } }>
          { question.inputs.map((item: any, index: number) => <NumberFormat
            thousandSeparator={true}
            allowNegative={false}
            customInput={TextField}
            onChange={ (event: any) => {
              handleChange(event)
            } }
            sx={ {
              minWidth: '100px', maxWidth: '600px', paddingRight: '10vw', margin: '10px 0 5px 0'
            } }
            autoFocus={ index === 0 }
            variant="outlined"
            color="primary"
            allowLeadingZeros={ false }
            value={ allValues[item.value] || '' }
            name={ item.value }
            autoComplete='no'
            InputProps={ {
              style: { fontSize: '1.4rem', fontWeight: 'bold' },
              classes: {
                notchedOutline: classes.notchedOutline
              }
            } }
            InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
            label={
              <Typography variant="h6" color="#999" sx={ {
                fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
              } }>
                { item.text }
              </Typography> }
          />)
          }
        </Grid>
        : question.coInputs && <Grid container direction="column" wrap="nowrap" sx={ { paddingLeft: '10vw' } }>
            <Grid container>
              <Typography variant="h6" sx={ {
                width: '200px',
                margin: '2vh 1vh 2vh 0',
                textAlign: 'left',
                fontWeight: 'bold'
              } }>
                You
              </Typography>
              <Typography variant="h6" sx={ {
                width: '200px',
                margin: '2vh 0 2vh 1vw',
                textAlign: 'left',
                fontWeight: 'bold'
              } }>
                Co-Borrower
              </Typography>
            </Grid>
            { question.inputs.map((item: any, index: number) => (
              <Grid key={ index } container justifyContent="flex-start" wrap="nowrap">
                <Box
                  className={classes.box}
                  sx={ { width: 'auto', maxWidth: '200px', marginRight: '1vw', boxSizing: 'border-box' } }
                >
                  <NumberFormat
                    thousandSeparator={true}
                    allowNegative={false}
                    customInput={TextField}
                    onChange={ (event: any) => {
                      handleChange(event)
                    } }
                    sx={ {
                      minWidth: '100px', maxWidth: '600px', margin: '10px 0 5px 0'
                    } }
                    autoFocus={ index === 0 }
                    variant="outlined"
                    color="primary"
                    allowLeadingZeros={ false }
                    value={ allValues[item.value] || '' }
                    name={ item.value }
                    autoComplete='no'
                    InputProps={ {
                      style: { fontSize: '1.4rem', fontWeight: 'bold' },
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    } }
                    InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
                    label={
                      <Typography variant="h6" color="#999" sx={ {
                        fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
                      } }>
                        Payments
                      </Typography> }
                  />
                </Box>
                <Box
                  sx={ { width: 'auto', maxWidth: '200px', boxSizing: 'border-box' } }
                  className={classes.box}
                >
                  <NumberFormat
                    thousandSeparator={true}
                    allowNegative={false}
                    customInput={TextField}
                    onChange={ (event: any) => {
                      handleChange(event)
                    } }
                    sx={ {
                      minWidth: '100px', maxWidth: '600px', margin: '10px 0 5px 0'
                    } }
                    autoFocus={ index === 0 }
                    variant="outlined"
                    color="primary"
                    allowLeadingZeros={ false }
                    value={ allValues[question.coInputs[index].value] || '' }
                    name={ question.coInputs[index].value }
                    autoComplete='no'
                    InputProps={ {
                      style: { fontSize: '1.4rem', fontWeight: 'bold' },
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    } }
                    InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
                    label={
                      <Typography variant="h6" color="#999" sx={ {
                        fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
                      } }>
                        Payments
                      </Typography> }
                  />
                </Box>

                <Box alignSelf="baseline" ml={ 2 } pt={ '1rem' }>
                  <Typography variant="h6" sx={ { fontSize: '1.3rem' } }>{ item.text }</Typography>
                </Box>
              </Grid>
            )) }
        </Grid>
    }
    </>
  )
})
