import React, { useEffect, useState } from 'react'
import { ChatContainer } from './ChatContainer'
import { getChatMemberData, getChatVisitorData } from '../../../shared/user/userQueries'

export interface IChatWrap {
  closeHandler: any
  memberId: string | null
}

export const ChatWrap = (props: IChatWrap) => {
  const { memberId, closeHandler } = props
  const [chatData, setChatData] = useState<any>('')
  const IS_REOPEN = 'isReopen'
  const IDENTITY_ID = 'identityId'
  const THREAD_ID = 'threadId'

  useEffect(() => {
    if (!chatData) {
      if (memberId) {
        const isReopen = !!sessionStorage.getItem(IS_REOPEN)
        getChatMemberData(memberId, isReopen)
          .then((response: any) => {
            setChatData(response.data)
            sessionStorage.setItem(IS_REOPEN, 'true')
            sessionStorage.setItem(IDENTITY_ID, response.data.identityId)
            sessionStorage.setItem(THREAD_ID, response.data.threadId)
          })
          .catch((err: any) => {
            console.error(err)
          })
      } else {
        const identityId = sessionStorage.getItem(IDENTITY_ID) || ''
        const threadId = sessionStorage.getItem(THREAD_ID) || ''
        getChatVisitorData(identityId, threadId)
          .then((response: any) => {
            setChatData(response.data)
            sessionStorage.removeItem(IS_REOPEN)
            sessionStorage.setItem(IDENTITY_ID, response.data.identityId)
            sessionStorage.setItem(THREAD_ID, response.data.threadId)
          })
          .catch((err: any) => {
            console.error(err)
          })
      }
    }
  }, [chatData])

  return (
    <>
      {
        chatData && <ChatContainer
            userIdentifier={chatData.identityId}
            token={chatData.accessToken}
            endpointUrl={chatData.endpointUrl}
            threadId={chatData.threadId}
            displayName=' '
            closeHandler={closeHandler}
        />
      }
    </>
  )
}
