import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Box, Button, Typography } from '@mui/material'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { TextFieldWithLink } from './TextFieldWithLink'
import { getClaimRebate, patchClaimRebate } from '../../../../../shared/user/userQueries'
import { getMemberCurrentValues, getMemberDetailsValues, numberWithCommas } from '../../../../../shared/functions'
import useCurrentOtherSectionsData from '../../../../../shared/hooks/useCurrentOtherSectionsData'

const useStyles = makeStyles({
  important: {
    color: 'red',
    fontWeight: 'bold'
  },
  underline: {
    textDecoration: 'underline'
  },
  saveButton: {
    marginBottom: '15px',
    fontWeight: 'bold',
    backgroundColor: 'rgb(237, 31, 138)',
    color: '#fff',
    border: '1px solid',
    minWidth: '300px',
    width: '50vw',
    maxWidth: '600px',
    padding: '15px 10px',
    borderRadius: '30px',
    textTransform: 'none',
    '&:hover, &:focus, &:disabled': {
      backgroundColor: '#fff',
      color: 'rgb(237, 31, 138)',
      border: '1px solid rgb(237, 31, 138)'
    }
  }
})

export const ClaimYourRebate = () => {
  const classes = useStyles()
  const [rebates, setRebates] = useState<any>(false)
  const { currentOtherSectionsData, setCurrentOtherSectionsData } = useCurrentOtherSectionsData()
  const [allValues, setAllValues] = useState(currentOtherSectionsData)
  const [firstRebatesState, setFirstRebatesState] = useState({ lenderChange: '', agentChange: '' })
  const [buttonTitle, setButtonTitle] = useState<string>('Save')
  const [buttonIsActive, setButtonIsActive] = useState<boolean>(true)
  const handleChange = (event: any) => {
    const newCurrentData = Object.assign({}, rebates)
    newCurrentData[event.target.name] = event.target.value
    setRebates(newCurrentData)
    setButtonTitle('Save')
    setButtonIsActive(true)
  }
  const fields = [
    {
      label: 'Lender',
      name: 'lender',
      value: rebates.lender || '',
      readOnly: !!allValues.lenderId && !allValues.lenderChange,
      helper: 'Change Lender',
      validation: {
        lender: yup.string().required('')
      },
      validationError: 'Lender is required field',
      isValid: rebates.lenderIsValid !== undefined ? rebates.lenderIsValid : true
    },
    {
      label: 'Agent',
      name: 'agent',
      value: rebates.agent || '',
      readOnly: !!allValues.agentId && !allValues.agentChange,
      helper: 'Change Agent',
      validation: {
        agent: yup.string().required('Agent is required field')
      },
      validationError: 'Agent is required field',
      isValid: rebates.agentIsValid !== undefined ? rebates.agentIsValid : true
    },
    {
      label: 'Purchase Price',
      name: 'purchasePrice',
      value: rebates.purchasePrice || '',
      number: true,
      validation: {
        purchasePrice: yup.string().test('purchasePrice', '', (value) => {
          if (value && typeof value === 'string') {
            return !!+value.replace(/,/g, '')
          }
          return true
        })
      },
      validationError: 'Purchase Price is required field',
      isValid: rebates.purchasePriceIsValid !== undefined ? rebates.purchasePriceIsValid : true
    },
    {
      label: 'Closing Date',
      name: 'closingDate',
      value: rebates.closingDate || '',
      mask: true,
      validation: {
        closingDate: yup.string().required('Closing Date is required field')
      },
      validationError: 'Closing Date is required field',
      isValid: rebates.closingDateIsValid !== undefined ? rebates.closingDateIsValid : true
    }
  ]

  const handleSave = () => {
    let isValid = true
    const newCurrentData = Object.assign({}, rebates)
    fields.forEach((field: any) => {
      if (field.validation) {
        for (const key in field.validation) {
          if (!field.validation[key].isValidSync(rebates[key])) {
            isValid = false
            newCurrentData[field.name + 'IsValid'] = false
          } else {
            newCurrentData[field.name + 'IsValid'] = true
          }
        }
      }
    })

    setRebates(newCurrentData)
    if (!isValid) {
      return
    }
    setButtonIsActive(false)
    const dateAr = rebates.closingDate.split('/')
    const date = dateAr[2] + '-' + dateAr[0] + '-' + dateAr[1]
    const saveParams = {
      purchasePrice: typeof rebates.purchasePrice === 'string' ? rebates.purchasePrice.replace(/,/g, '') : rebates.purchasePrice,
      closingDate: date,
      lenderChange: firstRebatesState.lenderChange === rebates.lender ? null : rebates.lender,
      agentChange: firstRebatesState.agentChange === rebates.agent ? null : rebates.agent
    }
    patchClaimRebate(saveParams, allValues.memberId)
      .then((response: any) => {
        setButtonTitle('Saved')
        setCurrentOtherSectionsData(allValues)
        const paramsGet = {
          memberId: allValues.memberId
        }
        getClaimRebate(paramsGet)
          .then((response: any) => {
            if (response.data.closingDate) {
              const dateAr = response.data.closingDate.split('-')
              response.data.closingDate = dateAr[1] + '/' + dateAr[2].substring(0, 2) + '/' + dateAr[0]
            }
            const newRebates = response.data
            const memberId = allValues.memberId
            getMemberDetailsValues(memberId)
              .then((response: any) => {
                if (response.data.moveDate) {
                  const dateAr = response.data.moveDate.split('-')
                  response.data.moveDate = dateAr[1] + '/' + dateAr[2].substring(0, 2) + '/' + dateAr[0]
                }
                const currentStateWithDetail = response.data
                getMemberCurrentValues(memberId)
                  .then((response: any) => {
                    const currentStateWithMember = { ...currentStateWithDetail, ...response.data }
                    setAllValues(currentStateWithMember)
                    setRebates(newRebates)
                  })
                  .catch((err: any) => {
                    console.error(err)
                  })
              })
              .catch((err: any) => {
                console.error(err)
              })
          })
          .catch((err: any) => {
            console.error(err)
          })
      })
      .catch((err: any) => {
        console.error(err)
      })
  }

  useEffect(() => {
    if (!rebates) {
      const paramsGet = {
        memberId: allValues.memberId
      }
      getClaimRebate(paramsGet)
        .then((response: any) => {
          if (response.data.closingDate) {
            const dateAr = response.data.closingDate.split('-')
            response.data.closingDate = dateAr[1] + '/' + dateAr[2].substring(0, 2) + '/' + dateAr[0]
          }
          setFirstRebatesState({
            lenderChange: response.data.lender,
            agentChange: response.data.agent
          })
          setRebates(response.data)
        })
        .catch((err: any) => {
          console.error(err)
        })
    }
  }, [rebates])

  return (
    <>
      <Box
        sx={ {
          display: 'flex',
          maxWidth: '768px',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: '#fff',
          padding: '0 20px',
          margin: '0 auto',
          borderRadius: '3px'
        } }
        color="primary"
      >
        <Typography
          variant='h5'
          fontWeight='bold'
          marginBottom='40px'
          marginTop='30px'
          textAlign='center'
          color='#666'
        >
          <span className={ classes.important }>IMPORTANT:</span> You must claim your rebate
          <span className={ classes.underline }> before</span> you close on your property.
        </Typography>
        <Typography variant='h5' marginBottom='30px' marginTop='40px' textAlign='center' color='#999'>
          To claim your rebate, enter the information below as soon as you are under contract.
          Simply enter the purchase price and your closing date and we'll calculate your exact
          rebate amount.
        </Typography>
        <Box
          sx={ { textAlign: 'center' } }>
          {
            fields.map((field, index) => {
              return <TextFieldWithLink handleChange={ handleChange } key={ index } { ...field }/>
            })
          }
        </Box>
        <Box
          sx={ {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#fff',
            position: 'relative',
            padding: '25px 10px',
            borderRadius: '5px',
            marginBottom: '40px'
          } }
          color='primary'
        >
          <Button
            onClick={ handleSave }
            type="submit"
            className={ classes.saveButton }
            size="large"
            disabled={ !buttonIsActive }
          >
            <Typography variant="h5">{ buttonTitle }</Typography>
          </Button>
        </Box>
        {
          (rebates.rebateEstimateLow > 0 || rebates.rebateEstimateHigh > 0) && <Box
              sx={ {
                display: 'flex',
                width: '90%',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: '#fff',
                padding: '10px 60px',
                margin: '30px auto 30px',
                border: '1px solid rgb(237,31,138)',
                borderRadius: '2px',
                boxShadow: '10px 5px 5px #ccc'
              } }
          >
              <Typography
                  fontSize='21px'
                  marginBottom='10px'
                  marginTop='10px'
                  textAlign='center'
                  color='#999'
              >
                  Your Estimated Rebate
              </Typography>
              <Typography
                  fontSize='40px'
                  fontWeight='bold'
                  marginBottom='30px'
                  marginTop='10px'
                  textAlign='center'
                  color='#000'
              >
                {
                  allValues.lenderChange || allValues.agentChange
                    ? '$0'
                    : `$${numberWithCommas(rebates.rebateEstimateLow)} - $${numberWithCommas(rebates.rebateEstimateHigh)}`
                }

              </Typography>
          </Box>
        }
        <Typography fontSize='16px' fontStyle='italic' marginBottom='40px' textAlign='center' color='#999'>
          Your rebate will be applied towards your closing costs. The rebate amount is dependant on lender
          guidelines and where available by law.
        </Typography>
      </Box>
    </>
  )
}
