import { useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { ChecklistStep } from './ChecklistStep'
import {
  getBuyingChecklistValues,
  buyingChecklistValuesUpdate
} from '../../../../../shared/user/userQueries'
import useCurrentData from '../../../../../shared/hooks/useCurrentData'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
  label: {
    position: 'absolute',
    fontSize: '14px',
    top: '-7px',
    left: '40px',
    background: '#fff',
    padding: '0 8px',
    color: '#999'
  },
  saveButton: {
    marginBottom: '15px',
    fontWeight: 'bold',
    backgroundColor: 'rgb(237, 31, 138)',
    color: '#fff',
    border: '1px solid',
    minWidth: '300px',
    width: '50vw',
    maxWidth: '600px',
    padding: '15px 10px',
    borderRadius: '30px',
    textTransform: 'none',
    '&:hover, &:focus, &:disabled': {
      backgroundColor: '#fff',
      color: 'rgb(237, 31, 138)',
      border: '1px solid rgb(237, 31, 138)'
    }
  }
})

export const YourHomeBuyingChecklist = () => {
  const classes = useStyles()
  const { currentData } = useCurrentData()
  const [homeBuyingChecklistValues, setHomeBuyingChecklistValues] = useState<any>(false)
  const [buttonTitle, setButtonTitle] = useState<string>('Save Checklist')
  const [buttonIsActive, setButtonIsActive] = useState<boolean>(true)
  const handleChange = (event: any, checked: any, name: keyof typeof homeBuyingChecklistValues) => {
    const currentSavingData = Object.assign({}, homeBuyingChecklistValues)
    currentSavingData[name] = checked
    setHomeBuyingChecklistValues(currentSavingData)
    setButtonTitle('Save Checklist')
    setButtonIsActive(true)
  }
  const handleSave = () => {
    setButtonIsActive(false)
    buyingChecklistValuesUpdate(homeBuyingChecklistValues)
      .then((response: any) => {
        setButtonTitle('Saved')
      })
      .catch((err: any) => {
        console.error(err)
      })
  }
  useEffect(() => {
    if (!homeBuyingChecklistValues) {
      getBuyingChecklistValues({ memberId: currentData.memberId })
        .then((response: any) => {
          setHomeBuyingChecklistValues(response.data)
        })
        .catch((err: any) => {
          console.error(err)
        })
    }
  }, [currentData.memberId])
  const checklistSteps = [
    {
      label: 'Pre-Qualify yourself',
      description: '(A few minutes)',
      steps: [
        {
          label: 'Review your HomeQual Success Plan',
          name: 'preQualifyReviewSuccessFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.preQualifyReviewSuccessFlag
        },
        {
          label: 'Resolve any issues identified',
          name: 'resolveIssuesFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.resolveIssuesFlag
        },
        {
          label: 'Determine what you can afford',
          name: 'determineAffordFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.determineAffordFlag
        },
        {
          label: 'Select your dream home features',
          name: 'dreamHomeFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.dreamHomeFlag
        }
      ]
    },
    {
      label: 'Mortgage Process',
      description: '(As soon as you pre-qualified yourself)',
      steps: [
        {
          label: 'Get Pre-Approved by your HomeQual lender',
          name: 'mortgageProcessPreApprovedFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.mortgageProcessPreApprovedFlag
        },
        {
          label: 'Submit documentation to lender',
          name: 'submitDocsFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.submitDocsFlag
        },
        {
          label: 'Determine the best loan type for you',
          name: 'determineLoanTypeFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.determineLoanTypeFlag
        }
      ]
    },
    {
      label: 'View Homes',
      description: '(Typically, 15 to 45 days)',
      steps: [
        {
          label: 'Get your HomeQual real estate agent',
          name: 'viewHomesGetAgentFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.viewHomesGetAgentFlag
        },
        {
          label: 'Tour homes',
          name: 'tourHomesFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.tourHomesFlag
        },
        {
          label: 'Have your offer accepted',
          name: 'offerAcceptedFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.offerAcceptedFlag
        },
        {
          label: 'Get a home inspection',
          name: 'homeInspectionFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.homeInspectionFlag
        },
        {
          label: 'Pay Option Fee and Earnest Money',
          name: 'optionFeeFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.optionFeeFlag
        },
        {
          label: 'Review the contract',
          name: 'reviewContractFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.reviewContractFlag
        }
      ]
    },
    {
      label: 'Pre-Closing',
      description: '(Typically, 30 days)',
      steps: [
        {
          label: 'Submit purchase contract to lender',
          name: 'preClosingPurchaseContractFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.preClosingPurchaseContractFlag
        },
        {
          label: 'Order the appraisal',
          name: 'appraisalFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.appraisalFlag
        },
        {
          label: 'Get homeowner\'s insurance',
          name: 'insuranceFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.insuranceFlag
        },
        {
          label: 'Sign Initial Closing Disclosure',
          name: 'closingDisclosureFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.closingDisclosureFlag
        },
        {
          label: 'Clame your HomeQual rebate',
          name: 'claimRebateFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.claimRebateFlag
        },
        {
          label: 'Preform final walkthrough',
          name: 'walkthroughFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.walkthroughFlag
        },
        {
          label: 'Transfer utilities',
          name: 'utilitiesFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.utilitiesFlag
        }
      ]
    },
    {
      label: 'Closing Day',
      description: '(Typically, about one hour)',
      steps: [
        {
          label: 'Make sure to bring identification',
          name: 'closingDayindentificationFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.closingDayindentificationFlag
        },
        {
          label: 'Wire funds or have cashier\'s check',
          name: 'wireFundsFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.wireFundsFlag
        },
        {
          label: 'Review and sign all contracts',
          name: 'signContractsFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.signContractsFlag
        },
        {
          label: 'After closing, put all documents to secure place',
          name: 'documentsSecureFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.documentsSecureFlag
        },
        {
          label: 'Receive your keys',
          name: 'receiveKeysFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.receiveKeysFlag
        }
      ]
    },
    {
      label: 'Moving in',
      description: '',
      steps: [
        {
          label: 'Review your HomeQual Move-In Checklist',
          name: 'movingInReviewChecklistFlag',
          value: 'Y',
          checked: homeBuyingChecklistValues.movingInReviewChecklistFlag
        }
      ]
    }
  ]
  return (
    <>
      <Box
        sx={ {
          position: 'relative'
        } }
      >
        <Box
          sx={ {
            display: 'flex',
            maxWidth: '768px',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: '#fff',
            padding: '0 20px',
            margin: '0 auto',
            borderRadius: '3px'
          } }
          color='primary'
        >
          <Typography variant='h5' marginBottom='40px' marginTop='40px' textAlign='center' color='#999'>
            This are the major steps to owning your own home and HomeQual will be there
            every step of the way!
          </Typography>
          <Box
            sx={ {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              backgroundColor: '#fff',
              padding: '5% 8%',
              margin: '0 auto 60px',
              border: '1px solid rgb(237,31,138)',
              borderRadius: '2px',
              boxShadow: '10px 5px 5px #ccc'
            } }
          >
            {
              checklistSteps.map((step) => {
                return <ChecklistStep handleChange={ handleChange } { ...step}/>
              })
            }
          </Box>
          <Box
            sx={ {
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#fff',
              position: 'relative',
              padding: '25px 10px',
              borderRadius: '5px',
              marginBottom: '40px'
            } }
            color='primary'
          >
            <Button
              onClick={ handleSave }
              type="submit"
              className={ classes.saveButton }
              size="large"
              disabled={ !buttonIsActive }
            >
              <Typography variant="h5">{ buttonTitle }</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}
