import { basicData } from '../shared'
import { FC, memo, useEffect } from 'react'
import { Wrapper } from '../../Wrapper'
import { Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material'
import { ICreateDoubledCheck_Radio_BoxQuestion } from '../../../questions'

interface IProps extends ICreateDoubledCheck_Radio_BoxQuestion, basicData {
}

export const CreateDoubledCheckBoxQuestion: FC<IProps> = memo(({
  answers,
  formik,
  co_answers,
  formName,
  co_formName,
  wrapperProps
}) => {
  const isCheckedAnswer = answers.find((one: any) => {
    return formik.values[one.value]
  })
  const isCheckedCoAnswer = co_answers && co_answers.find((one: any) => {
    return formik.values[one.value]
  })
  useEffect(() => {
    formik.setFieldValue(formName, '1')
    formik.setFieldValue(co_formName, '1')
    if (!isCheckedAnswer) {
      formik.setFieldValue(formName, '1')
      answers.forEach((item) => {
        if (item.value.indexOf('None') !== -1) {
          formik.setFieldValue(item.value, true)
        }
      })
    }
    if (!isCheckedCoAnswer) {
      co_answers.forEach((item) => {
        if (item.value.indexOf('CoNo') !== -1) {
          formik.setFieldValue(item.value, true)
        }
      })
    }
  }, [isCheckedAnswer, isCheckedCoAnswer])

  const hiddenHandler = (event: any) => {
    const isAnswer = answers.find((one: any) => {
      return event.target.name === one.value
    })
    const isCoAnswer = co_answers.find((one: any) => {
      return event.target.name === one.value
    })
    if (isAnswer) {
      if (event.target.checked) {
        formik.setFieldValue(formName, '1')
      } else {
        formik.setFieldValue(formName, [])
      }
    }
    if (isCoAnswer) {
      if (event.target.checked) {
        formik.setFieldValue(co_formName, '1')
      } else {
        formik.setFieldValue(co_formName, [])
      }
    }

    if (event.target.name.indexOf('None') !== -1) {
      answers.forEach((item) => {
        formik.values[item.value] = false
      })
    } else {
      const targetAnswer = answers.filter((item) => {
        return event.target.name === item.value
      })
      if (targetAnswer.length) {
        answers.forEach((item) => {
          if (item.value.indexOf('None') !== -1) {
            formik.values[item.value] = false
          }
        })
      }
    }
    if (event.target.name.indexOf('CoNo') !== -1) {
      co_answers.forEach((item) => {
        formik.values[item.value] = false
      })
    } else {
      const targetCoAnswer = co_answers.filter((item) => {
        return event.target.name === item.value
      })
      if (targetCoAnswer.length) {
        co_answers.forEach((item) => {
          if (item.value.indexOf('CoNo') !== -1) {
            formik.values[item.value] = false
          }
        })
      }
    }
  }
  return (
    <Wrapper { ...wrapperProps }>
      <Grid container direction="column" wrap="nowrap" sx={ {
        padding: '0 3vw 0 200px',
        '@media(max-width: 768px)': {
          paddingLeft: '20vw'
        }
      } }>
        <Grid container>
          <Typography variant="h6" sx={ {
            maxWidth: '49px',
            marginRight: '20px',
            textAlign: 'left',
            fontWeight: 'bold'
          } }>
            You
          </Typography>
          <Typography variant="h6" sx={ {
            width: 'auto',
            textAlign: 'left',
            fontWeight: 'bold'
          } }>
            { formik.values.coFirstName }`s
          </Typography>
        </Grid>

        <FormGroup onChange={ formik.handleChange }
                   onClick={ (event) => hiddenHandler(event) }>
          <TextField
            name={ formName }
            type="hidden"
            value=''
            onChange={ formik.handleChange }
          />
          <Grid container direction="column" wrap="nowrap">
            { answers.map((item, index: number) => (
                  <Grid container justifyContent="flex-start" wrap={ 'nowrap' } key={ index }>
                    <FormControlLabel control={ <Checkbox sx={ { transform: 'scale(1)' } }/> }
                                      label=""
                                      name={ item.value }
                                      value={ true }
                                      color="primary"
                                      checked={
                                        (item.value === 'noneHistoryFlag' ||
                                          item.value === 'nonePaymentFlag' ||
                                          item.value === 'employmentGapsFlagNone') && !isCheckedAnswer
                                          ? true
                                          : formik.values[item.value] || false
                                      }
                                      sx={ { margin: '1vh 0', minWidth: '50px' } }/>
                    <FormControlLabel control={ <Checkbox sx={ { transform: 'scale(1)', marginRight: '15px' } }/> }
                                      label={
                                        <Typography variant="h6" ml={ 1 }> { item.text } </Typography>
                                      }
                                      name={ co_answers[index].value }
                                      value={ true }
                                      checked={
                                        (co_answers[index].value === 'coNoneHistoryFlag' ||
                                          co_answers[index].value === 'coNonePaymentFlag' ||
                                          co_answers[index].value === 'employmentGapsFlagCoNo') && !isCheckedCoAnswer
                                          ? true
                                          : formik.values[co_answers[index].value] || false
                                      }
                                      color="primary"
                                      sx={ { margin: '1vh 0', width: 'auto' } }/>
                  </Grid>
            )) }
          </Grid>
        </FormGroup>
      </Grid>
    </Wrapper>
  )
})
