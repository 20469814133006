import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { YourSuccessTeamBlock } from './YourSuccessTeamBlock'
import avatar from '../../../../../assets/images/faces/avatar.jpg'
import lender from '../../../../../assets/images/faces/nolender.png'
import { getSuccessTeam, getFile } from '../../../../../shared/user/userQueries'
import useCurrentOtherSectionsData from '../../../../../shared/hooks/useCurrentOtherSectionsData'
import CircularProgress from '@mui/material/CircularProgress'
import { phoneFormat } from '../../../../../shared/functions'

export const YourSuccessTeam = () => {
  const { currentOtherSectionsData } = useCurrentOtherSectionsData()
  const [team, setTeam] = useState<any>(false)
  const [advisorImg, setAdvisorImg] = useState<any>(false)
  const [agentImg, setAgentImg] = useState<any>(false)
  const [lenderImg, setLenderImg] = useState<any>(false)

  useEffect(() => {
    if (!team) {
      const paramsGet = {
        memberId: currentOtherSectionsData.memberId
      }
      getSuccessTeam(paramsGet)
        .then((response: any) => {
          setTeam(response.data)
          if (response.data.advisorImage) {
            getFile(response.data.advisorImage)
              .then((response: any) => {
                setAdvisorImg(response.data)
              })
              .catch((err: any) => {
                console.error(err)
              })
          }
          if (response.data.agentImage) {
            getFile(response.data.agentImage)
              .then((response: any) => {
                setAgentImg(response.data)
              })
              .catch((err: any) => {
                console.error(err)
              })
          }
          if (response.data.lenderImage) {
            getFile(response.data.lenderImage)
              .then((response: any) => {
                setLenderImg(response.data)
              })
              .catch((err: any) => {
                console.error(err)
              })
          }
        })
        .catch((err: any) => {
          console.error(err)
        })
    }
  }, [team])
  const teamUnits = [
    {
      title: 'My HomeQual advisor',
      img: advisorImg || avatar,
      name: team.advisorName,
      phone: team.advisorPhone && phoneFormat(team.advisorPhone),
      email: team.advisorEmail
    },
    {
      title: 'My HomeQual agent',
      img: agentImg || avatar,
      name: team.agentName,
      phone: team.agentPhone && phoneFormat(team.agentPhone),
      email: team.agentEmail
    },
    {
      title: 'My HomeQual lender',
      img: lenderImg || lender,
      name: team.lenderBusinessName,
      phone: team.lenderPhone && phoneFormat(team.lenderPhone),
      email: team.lenderEmail,
      isLender: true
    }
  ]

  return (
    <>
      <Box
        sx={ {
          position: 'relative'
        } }
      >
        {
          !team
            ? <Box
              sx={ {
                display: 'flex',
                justifyContent: 'center',
                marginTop: '50px',
                width: '100%'
              } }
              color="primary"
            >
              <CircularProgress/>
            </Box>
            : <Box
              sx={ {
                display: 'flex',
                alignItems: 'center',
                maxWidth: '800px',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: '#fff',
                padding: '0 20px',
                margin: '30px auto 0',
                borderRadius: '3px'
              } }
              color="primary"
            >
              <YourSuccessTeamBlock key={ 0 } { ...teamUnits[0] }/>
              <Box
                sx={ {
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-around',
                  '@media screen and (max-width : 600px)': {
                    flexDirection: 'column'
                  }
                } }
              >
                {
                  teamUnits.map((unit, index) => {
                    if (index > 0) {
                      return <YourSuccessTeamBlock key={ index } { ...unit }/>
                    }
                    return null
                  })
                }
              </Box>
            </Box>
        }
      </Box>
    </>
  )
}
