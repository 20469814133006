type HQService =
  'api'
  | 'user'
  | 'auth'
  | 'member'
  | 'plan'
  | 'designHome'
  | 'buyingChecklist'
  | 'qualificationIssue'
  | 'systemGlobal'
  | 'memberSpeakAdvisor'
  | 'memberRefinance'
  | 'memberFindLender'
  | 'memberFindAgent'
  | 'memberBuyHome'
  | 'claimRebate'
  | 'successTeam'
  | 'file'

interface AAServiceConfig {
  id: HQService;
  name: string;
  api: string;
}

const services: Record<HQService, AAServiceConfig> = {
  api: {
    id: 'api',
    name: 'Api',
    api: '/Api'
  },
  member: {
    id: 'member',
    name: 'Member Service',
    api: '/Api/Member'
  },
  auth: {
    id: 'auth',
    name: 'Auth Service',
    api: '/Api/Auth'
  },
  user: {
    id: 'user',
    name: 'Users Service',
    api: '/Api/User'
  },
  plan: {
    id: 'plan',
    name: 'plan Service',
    api: '/Api/SuccessPlan'
  },
  designHome: {
    id: 'designHome',
    name: 'designHome Service',
    api: '/Api/DesignHome'
  },
  buyingChecklist: {
    id: 'buyingChecklist',
    name: 'buyingChecklist Service',
    api: '/Api/BuyingChecklist'
  },
  qualificationIssue: {
    id: 'qualificationIssue',
    name: 'qualificationIssue Service',
    api: '/Api/QualificationIssue'
  },
  systemGlobal: {
    id: 'systemGlobal',
    name: 'systemGlobal Service',
    api: '/Api/SystemGlobal'
  },
  memberSpeakAdvisor: {
    id: 'memberSpeakAdvisor',
    name: 'memberSpeakAdvisor Service',
    api: '/Api/MemberSpeakAdvisor'
  },
  memberRefinance: {
    id: 'memberRefinance',
    name: 'memberRefinance Service',
    api: '/Api/MemberRefinance'
  },
  memberFindLender: {
    id: 'memberFindLender',
    name: 'memberFindLender Service',
    api: '/Api/MemberFindLender'
  },
  memberFindAgent: {
    id: 'memberFindAgent',
    name: 'memberFindAgent Service',
    api: '/Api/MemberFindAgent'
  },
  memberBuyHome: {
    id: 'memberBuyHome',
    name: 'memberBuyHome Service',
    api: '/Api/MemberBuyHome'
  },
  claimRebate: {
    id: 'claimRebate',
    name: 'claimRebate Service',
    api: '/Api/ClaimRebate'
  },
  successTeam: {
    id: 'successTeam',
    name: 'successTeam Service',
    api: '/Api/SuccessTeam'
  },
  file: {
    id: 'file',
    name: 'file Service',
    api: '/Api/File'
  }
}

export default services
