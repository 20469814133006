import { Typography } from '@mui/material'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { QualButton } from '../../../../shared/styles'
import { lendingLG, lendingMD, lendingXL } from '../../../../assets/images/dashboard/lending'
import { useNavigate } from 'react-router-dom'
import useCurrentFormikValues from '../../../../shared/hooks/useCurrentFormikValues'
import useCurrentData from '../../../../shared/hooks/useCurrentData'
import axios from 'axios'
import useCurrentOtherSectionsData from '../../../../shared/hooks/useCurrentOtherSectionsData'
import useShowChat from '../../../../shared/hooks/useShowChat'
import { closeMemberChatQuery, closeVisitorChatQuery } from '../../../../shared/user/userQueries'

export const Content = styled.div`
  position        : relative;
  display         : flex;
  justify-content : space-between;
  flex-direction  : column;
  align-items     : center;
  height          : 100vh;
  padding         : 4vh 0 14vh 0;

  @media screen and (min-width : 2001px) {
    background      : url(${lendingXL}) center center no-repeat border-box;
    background-size : cover;
  }
  @media screen and (max-width : 2000px) {
    background      : url(${lendingLG}) center center no-repeat border-box;
    background-size : cover;
  }
  @media screen and (max-width : 900px) {
    background      : url(${lendingMD}) center center no-repeat border-box;
    background-size : cover;
  }
`
const TextContainer = styled.div`
  margin-top       : 10vh;
  width            : 100%;
  background-color : rgba(141, 135, 135, 0.68);
  color            : white;
  padding          : 7% 20% 7% 7%;
`

export const HelpingFirstTime: FC = () => {
  const navigate = useNavigate()
  const [buttonTitle] = useState('Start Your Journey Now')
  const { saveCurrentFormikValues } = useCurrentFormikValues()
  const { currentData, setCurrentData } = useCurrentData()
  const { setCurrentOtherSectionsData } = useCurrentOtherSectionsData()
  const { setShowChat } = useShowChat()
  const authToken = sessionStorage.getItem('authToken')

  return (
    <Content>
      <TextContainer>
        <Typography variant={ 'h4' } sx={ { textAlign: 'left', fontWeight: 'light' } }>
          Helping first-time homebuyers obtain the dream of ownership
        </Typography>
      </TextContainer>

      <QualButton onClick={ () => {
        axios.defaults.headers.common.authorization = ''
        window.sessionStorage.removeItem('authToken')
        sessionStorage.removeItem('isReopen')
        setCurrentData(false)
        setCurrentOtherSectionsData(false)
        saveCurrentFormikValues({})
        setShowChat(false)
        const identityId = sessionStorage.getItem('identityId')
        const threadId = sessionStorage.getItem('threadId')
        if (identityId && threadId) {
          if (authToken) {
            closeMemberChatQuery(currentData?.memberId, identityId, threadId)
              .then((response: any) => {
              })
              .catch((err: any) => {
                console.error(err)
              })
          } else {
            closeVisitorChatQuery(identityId, threadId)
              .then((response: any) => {
              })
              .catch((err: any) => {
                console.error(err)
              })
          }
          sessionStorage.removeItem('identityId')
          sessionStorage.removeItem('threadId')
        }
        navigate('/journey/Q1')
        /* if (!currentData) {
          navigate('/journey/Q1')
          return
        }
        if (currentData.lastQuestionCompleted === 23 || currentData.lastQuestionCompleted === 22) {
          navigate('/success')
        } else {
          navigateToCompletedQuestion(
            questionsList,
            currentData,
            currentData.lastQuestionCompleted,
            navigate
          )
        } */
      } }>
        <Typography variant="h5">{ buttonTitle }</Typography>
      </QualButton>
    </Content>
  )
}
