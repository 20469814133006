import { basicData, InputNumberField, RadioField } from '../shared'
import React, { FC, memo } from 'react'
import { Wrapper } from '../../Wrapper'
import { Grid, Typography } from '@mui/material'
import { ICreateRadioTextQuestion } from '../../../questions'

interface IProps extends ICreateRadioTextQuestion, basicData {
}

export const CreateRadioTextQuestion20: FC<IProps> = memo(({
  inputs,
  formik,
  answers,
  question1,
  formName,
  wrapperProps
}) => {
  return (
    <Wrapper { ...wrapperProps }>
      <Grid container direction="column" sx={ {
        paddingLeft: '100px',
        '@media(max-width: 768px)': {
          paddingLeft: '10vw'
        }
      } }>
        <Grid container direction="column" wrap="nowrap" sx={ { width: 'auto', alignItems: 'center' } }>
          <RadioField formik={ formik } formName={ formName } answers={ answers }/>
        </Grid>

        <Typography variant="h6" sx={ { fontWeight: 'bold', marginTop: '40px' } }>
          { question1(formik.values) }
        </Typography>

        { inputs.map((item, index: number) => (
          <InputNumberField key={ index } item={ item } formik={ formik }/>
        )) }
      </Grid>
    </Wrapper>
  )
})
