import React, { FC, memo, useEffect, useState } from 'react'
import { basicData } from '../shared'
import { Wrapper } from '../../Wrapper'
import {
  CenteredTypography
} from '../../../../../../shared/styles'
import { Box, Button } from '@mui/material'
import { basicTypes } from '../../../questions'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styled from 'styled-components'
import avatar from '../../../../../../assets/images/faces/avatar.jpg'
import useCurrentOtherSectionsData from '../../../../../../shared/hooks/useCurrentOtherSectionsData'
import { getMemberCurrentValues, phoneFormat, updateMember } from '../../../../../../shared/functions'
import { getFile } from '../../../../../../shared/user/userQueries'
import CircularProgress from '@mui/material/CircularProgress'

interface IProps extends basicTypes, basicData {
}

const useStyles = makeStyles({
  addedMargin: {
    marginBottom: '30px'
  },
  textOrEmailButton: {
    marginBottom: '15px',
    borderColor: 'rgb(237, 31, 138)',
    border: '1px solid',
    minWidth: '300px',
    width: '50vw',
    maxWidth: '600px',
    padding: '15px 10px',
    borderRadius: '30px',
    color: 'rgb(237, 31, 138)',
    '&:hover': {
      color: '#fff',
      background: 'rgb(237, 31, 138)'
    }
  },
  textOrEmailButtonActive: {
    background: 'rgb(237, 31, 138)',
    color: '#fff'
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center'
  }
})

const ImageHolder = styled(({ ...props }) => <div { ...props } />)`
  border-radius   : 50%;
  min-width       : 100px;
  min-height      : 100px;
  max-width       : 150px;
  max-height      : 150px;
  width           : 10vw;
  height          : 10vw;
  background      : url(${props => props.userimage}) 100% 100% no-repeat;
  background-size : contain;
`

export const CreateEndRefinanceQuestion: FC<IProps> = memo(({
  wrapperProps
}) => {
  const values = wrapperProps.formik.values
  const status = -1
  const classes = useStyles()
  const { currentOtherSectionsData } = useCurrentOtherSectionsData()
  const [thankText, setThankText] = useState('')
  const [activeButton, setActiveButton] = useState(0)
  const [memberData, setMemberData] = useState<any>('')
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    if (!memberData || !imageUrl) {
      getMemberCurrentValues(currentOtherSectionsData.memberId)
        .then((response: any) => {
          setMemberData(response.data)
          if (response.data.imageFile) {
            getFile(response.data.imageFile)
              .then((response: any) => {
                setImageUrl(response.data)
              })
              .catch((err: any) => {
                console.error(err)
              })
          } else {
            setImageUrl(avatar)
          }
        })
        .catch((err: any) => {
          console.error(err)
        })
    }
  }, [])

  const clickHandle = (communicationPreferenceId: number) => {
    wrapperProps.formik.setFieldValue('communicationPreferenceId', communicationPreferenceId)
    getMemberCurrentValues(currentOtherSectionsData.memberId)
      .then((response: any) => {
        const dataWithCommunicationPreferenceId = { ...response.data, communicationPreferenceId }
        setActiveButton(communicationPreferenceId)
        setThankText(`Thank you ${values.firstName}. I will be in contact with you shortly.\n 
        Just so you have it, my contact information is ${memberData.phone ? phoneFormat(memberData.phone) + ' and' : ''} \n ${memberData.emailAddress}`)
        return updateMember(wrapperProps.formik.values, dataWithCommunicationPreferenceId)
      })
      .catch((err: any) => {
        console.error(err)
      })
  }

  return (
    <>
    {
      memberData && imageUrl
        ? <Wrapper { ...wrapperProps } status={ status }>
            <CenteredTypography
              variant="h6"
              className={classes.imageWrapper}
            >
              <ImageHolder userimage={ imageUrl }/>
            </CenteredTypography>

            <CenteredTypography variant="h6">
              Hi { values.firstName }, I'm { memberData.homeQualStaffName } and I'd be glad to help.
            </CenteredTypography>

            <CenteredTypography variant="h6" className={ classes.addedMargin }>
              How would you like me to contact you?
            </CenteredTypography>

            <CenteredTypography variant="h5">
              <Button
                onClick={ () => {
                  clickHandle(3)
                }}
                type="submit"
                className={
                  `${classes.textOrEmailButton}
                ${activeButton === 3 && classes.textOrEmailButtonActive}`
                }
                size="large"
              >
                Call Me
              </Button>
            </CenteredTypography>

            <CenteredTypography variant="h5">
              <Button
                onClick={ () => {
                  clickHandle(1)
                }}
                type="submit"
                className={
                  `${classes.textOrEmailButton}
                ${activeButton === 1 && classes.textOrEmailButtonActive}`
                }
                size="large"
              >
                Email Me
              </Button>
            </CenteredTypography>

            <CenteredTypography variant="h5">
              <Button
                onClick={ () => {
                  clickHandle(2)
                }}
                type="submit"
                className={
                  `${classes.textOrEmailButton}
                ${activeButton === 2 && classes.textOrEmailButtonActive}`
                }
                size="large"
              >
                Text Me
              </Button>
            </CenteredTypography>

            <CenteredTypography variant="h6" lineHeight="33px" whiteSpace='pre-wrap'>
              { thankText }
            </CenteredTypography>
          </Wrapper>
        : <Box
            sx={ {
              display: 'flex',
              justifyContent: 'center',
              marginTop: '50px',
              width: '100%'
            } }
            color="primary"
          >
            <CircularProgress/>
          </Box>
    }
    </>
  )
})
