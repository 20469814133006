import { Grid } from '@mui/material'
import { memo, useCallback, useEffect, useState } from 'react'
import { QuestionKeyType } from './questions'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { SetCurrentQuestionHandler } from './MainRoutes'
import { GenerateQuestion } from './GenerateQuestion'
import useCurrentData from '../../../shared/hooks/useCurrentData'
import { copyField, isQaSection } from '../../../shared/functions'

interface IFormState {
  [key: string]: string | string[] | number | boolean | null
}

interface QuestionsAndAnswersProps {
  questionsList: any,
  QuestionsSchema: any
}

export const QuestionsAndAnswers = memo((props: QuestionsAndAnswersProps) => {
  const { questionsList, QuestionsSchema } = props
  const initialState: IFormState = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    cellPhone: 0,
    communicationPreferenceId: 0,
    coBorrowerFlag: null,
    co_borrower_flag_r: '',
    credit_score: '',
    address: '',
    city: '',
    state: null,
    zip_code: null,
    maritalStatus: null,
    coFirstName: '',
    coLastName: '',
    coMaritalStatus: null,
    taxLienFlag: false,
    moveRange: null,
    moveRangeValid: null,
    refiLoanTypeValid: null,
    refiPropertyValue: null,
    refiPropertyOwe: null,
    refiInterestRate: null,
    refiLoanType: null,
    refiCreditScoreRange: null,
    refiAddress: null,
    refiCity: null,
    refiStateId: null,
    refiZipCode: null,
    monthlyPaymentAmount: null,
    homeSavings: null,
    lenderDetails: null,
    coLenderDetails: null,
    taxDetails: '',
    coTaxDetails: '',
    lendersPayDetails: null,
    coLendersPayDetails: null,
    homeSavingsDetails: [],
    monthlyIncome: null,
    property_value: 0,
    currently_owe: 0,
    interest_rate: 0,
    coMonthlyIncome: null,
    autoExpense: null,
    creditCardExpense: null,
    studentLoans: null,
    moveDate: null,
    otherExpenses: null,
    coAutoExpense: null,
    coCreditCardExpense: null,
    coStudentLoans: null,
    coOtherExpenses: null,
    coMemberBuyerDetails: [],
    memberBuyerDetails: [],
    coMemberBuyerDtudentDetails: '',
    studentLoanDefault: '',
    coStudentLoanDefault: '',
    creditScoreRange: null,
    coCreditScoreRange: null,
    livingSituation: null,
    coLivingSituation: null,
    housingPaymentAmount: null,
    coHousingPaymentAmount: null,
    veteranFlag: '',
    coVeteranFlag: '',
    liveCity1: '',
    liveCity2: '',
    liveCity3: '',
    liveState1: '',
    liveState2: '',
    liveState3: '',
    live_state_3: '',
    password: '',
    memberId: 0,
    homeSavingsCheckingFlag: false,
    homeSavings401Flag: false,
    homeSavingsStocksFlag: false,
    homeSavingsGiftFlag: false,
    homeSavingsOtherFlag: false,
    latePaymentsFlag: false,
    collectionsFlag: false,
    lateStudentLoanFlag: false,
    lateHousingPaymentsFlag: false,
    taxLienJudgementForeclosureFlag: false,
    bankruptcyFlag: false,
    taxesNotCurrentFlag: false,
    lendersPayDetailsNone: false,
    employmentGapsFlag: false,
    changedJobsFlag: false,
    selfEmployedFlag: false,
    noIncomeDocumentationFlag: false,
    commissionsBonusFlag: false,
    employmentGapsFlagNone: false,
    foreclosure24MonthsFlag: false,
    foreclosure36MonthsFlag: false,
    foreclosure48MonthsFlag: false,
    judgementFlag: false,
    coEmploymentGapsFlag: false,
    coChangedJobsFlag: false,
    coSelfEmployedFlag: false,
    coNoIncomeDocumentationFlag: false,
    coCommissionsBonusFlag: false,
    employmentGapsFlagCoNo: false,
    coLatePaymentsFlag: false,
    coCollectionsFlag: false,
    coLateStudentLoanFlag: false,
    coLateHousingPaymentsFlag: false,
    coTaxLienJudgementForeclosureFlag: false,
    coBankruptcyFlag: false,
    coTaxesNotCurrentFlag: false,
    latePaymentsFlagCoNo: false,
    studentLoanDefaultFlag: false,
    studentLoanRemediationFlag: false,
    coStudentLoanDefaultFlag: false,
    coStudentLoanRemediationFlag: false,
    coTaxLienFlag: false,
    coForeclosure24MonthsFlag: false,
    coForeclosure36MonthsFlag: false,
    coForeclosure48MonthsFlag: false,
    coJudgementFlag: false
  }

  const navigate = useNavigate()
  const [currentState, setCurrentState] = useState<IFormState>(initialState)
  const [currentSchema, setCurrentSchema] = useState(QuestionsSchema.Q1)
  const { currentData } = useCurrentData()
  const formik = useFormik({
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: false,
    initialValues: isQaSection() && currentData ? currentData : currentState,
    validationSchema: currentSchema,
    onSubmit: (values: IFormState) => HandleSubmit(values)
  })
  const { pathname } = useLocation()
  useEffect(() => {
    if (
      pathname === '/find_lender/Q1' ||
      pathname === '/buy_home/Q1' ||
      pathname === '/find_agent/Q1' ||
      pathname === '/refinance/Q1' ||
      pathname === '/speak_with_advisor/Q1' ||
      pathname === '/journey/Q1'
    ) {
      formik.resetForm({
        values: initialState
      })
    }
  }, [pathname])
  useEffect(() => {
    if (currentData && isQaSection()) {
      const currentSavingData = Object.assign({}, initialState)
      for (const key in currentSavingData) {
        const keyName = key as keyof typeof currentSavingData
        if (keyName in formik.values) copyField(keyName, currentSavingData, currentData)
      }
      formik.resetForm({
        values: currentSavingData
      })
    }
  }, [currentData])

  const setSchema = (questionKey: QuestionKeyType) => {
    if (QuestionsSchema[questionKey] !== currentSchema) {
      setCurrentSchema(QuestionsSchema[questionKey] ? QuestionsSchema[questionKey] : yup.object({}))
    }
  }

  const HandleSubmit = useCallback((values: IFormState) => {
    setCurrentState(values)
  }, [])

  const questionRoutes = Object.values(questionsList).map((CurrentQuestion: typeof questionsList.index, index) =>
    <Route path={ `/${Object.keys(questionsList)[index]}` }
           element={ GenerateQuestion({ CurrentQuestion, formik, SetCurrentQuestionHandler, setSchema, navigate }) }
           key={ index }/>)

  return (
    <Grid overflow="hidden" sx={ { height: 'auto', maxWidth: '1280px', margin: '0 auto' } }>
      <Routes>
        { questionRoutes }
      </Routes>
    </Grid>

  )
})
