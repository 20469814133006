import React from 'react'
import styled from 'styled-components'
import { disclosure, lending } from '../../../../assets/images/articles'
import { Typography } from '@mui/material'
import { GreyTextContainer, HeaderDivider, QualButton, TextContainer } from '../../../../shared/styles'
import { ContactUs } from '../../Dashboard/Sections/ContactUs'
import { useNavigate } from 'react-router-dom'

const ContentImage = styled.div`
  position        : relative;
  display         : flex;
  justify-content : flex-start;
  align-items     : center;
  height          : 20vh;
  padding         : 10vh 0 14vh 3vw;
  background      : url(${disclosure}) center center no-repeat border-box;
  background-size : cover;
`

export const Lending = () => {
  const navigate = useNavigate()

  return (
    <>
      <HeaderDivider/>
      <ContentImage>
        {/* text */ }
      </ContentImage>

      <TextContainer>
        <Typography variant="h5" fontWeight="bold" mb={ 2 }>
          Connect with the right lender at the right time
        </Typography>

        <Typography sx={ {
          fontSize: '22px',
          fontWeight: 300,
          '@media(max-width: 768px)': {
            fontSize: '20px'
          }
        } } textAlign="left">
          Before you begin looking at homes, you need to be approved to buy one and choosing the right mortgage is important.
          As your trusted Advisor, we will match you with a lender who will guide you through the mortgage process.
        </Typography>
      </TextContainer>

      <GreyTextContainer color={ 'black' } style={ { padding: '30px 40px' } }>
        <Typography variant="h5" fontWeight="bold" mb={ 4 } color="#000" textAlign="center">
          Helping You Save Money
        </Typography>

        <Typography sx={ {
          fontSize: '22px',
          fontWeight: 300,
          display: 'flex',
          '@media(max-width: 768px)': {
            fontSize: '20px'
          },
          '@media(max-width: 500px)': {
            display: 'block'
          }
        } } textAlign="left">
          <div>
            As a Home<span style={ { color: 'rgb(237,31,138)' } }>Qual</span> member, you will be properly prepared before you engage a lender.
            By knowing what to ask and what to look out for, you will be in position to save lots of time and money!
          </div>
          <div
            style={ {
              textAlign: 'center'
            } }
          >
            <img
              style={ {
                width: '150px',
                textAlign: 'center'
              } }
              src={lending}
            />
          </div>
        </Typography>
        <QualButton
          sx={ { borderRadius: '30px', margin: '15px auto 0' } }
          onClick={() => {
            navigate('/find_lender/Q1')
          } }
        >
          <Typography variant="h5">Find Me A Lender</Typography>
        </QualButton>
      </GreyTextContainer>

      <ContactUs/>
    </>
  )
}
