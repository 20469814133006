import styled from 'styled-components'
import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import makeStyles from '@material-ui/core/styles/makeStyles'

const DiagramButton = styled(({ ...props }) => <div { ...props } />)`
  box-sizing   : border-box;
  height: 25%;
  min-width: 120px;
  text-align: center;
  cursor: pointer;
  padding      : 0px 5px;
  margin       : 2px 0;
  border-width : ${(props) => props.chosendiagrambutton == props.value ? '2px' : '1px'};
  border-style : solid;
  border-color : ${(props) => props.chosendiagrambutton == props.value ? props.theme.colors.primary : 'grey'};
  color        : ${(props) => props.chosendiagrambutton == props.value ? 'black' : 'grey'};

  & > * {
    font-weight : ${(props) => props.chosendiagrambutton == props.value ? '500' : 'normal'};
  }
`

const useStyles = makeStyles({
  button: {
  },
  disabled: {
    background: '#ccc',
    cursor: 'default'
  },
  column: {
    height: '80% !important',
    '@media(max-width: 660px)': {
      flexDirection: 'column',
      alignItems: 'center'
    }
  }
})

interface ILoanButtons {
  handleClick: any;
  chosenDiagramButton: number | string;
  calculationValues: any;
}

export const LoanButtons: FC<ILoanButtons> = ({ handleClick, chosenDiagramButton, calculationValues }) => {
  const classes = useStyles()

  return (
    <>
      <Typography fontSize='0.8rem' textAlign="center" color="#272932">
        SELECT LOAN TYPE
      </Typography>
      <Grid className={classes.column} direction="column" container justifyContent="center">
        <DiagramButton
          className={ classes.button }
          value='1'
          chosendiagrambutton={ chosenDiagramButton }
          onClick={ () => handleClick(1) }
        >
          <Typography fontSize='0.8rem'>
            Conventional
          </Typography>
        </DiagramButton>

        <DiagramButton
          className={ classes.button }
          value='2'
          chosendiagrambutton={ chosenDiagramButton }
          onClick={ () => handleClick(2) }
        >
          <Typography fontSize='0.8rem'>
            FHA
          </Typography>
        </DiagramButton>

        <DiagramButton
          className={ !calculationValues.vaQualifiedFlag ? classes.disabled : classes.button }
          value='3'
          chosendiagrambutton={ chosenDiagramButton }
          onClick={ () => {
            if (!calculationValues.vaQualifiedFlag) {
              return
            }
            handleClick(3)
          } }
        >
          <Typography fontSize='0.8rem'>
            VA
          </Typography>
        </DiagramButton>
      </Grid>
    </>
  )
}
