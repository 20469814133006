import { useState } from 'react'
import { Box, Radio, FormControlLabel, Typography, RadioGroup, Button } from '@mui/material'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
  saveButton: {
    marginBottom: '15px',
    fontWeight: 'bold',
    backgroundColor: 'rgb(237, 31, 138)',
    color: '#fff',
    border: '1px solid',
    minWidth: '300px',
    width: '50vw',
    maxWidth: '600px',
    padding: '15px 10px',
    borderRadius: '30px',
    textTransform: 'none',
    '&:hover, &:focus': {
      backgroundColor: '#fff',
      color: 'rgb(237, 31, 138)',
      border: '1px solid rgb(237, 31, 138)'
    }
  },
  title: {
    fontSize: '40px',
    marginBottom: '40px',
    textAlign: 'center',
    color: '#999',
    '@media(max-width: 768px)': {
      fontSize: '35px'
    }
  },
  text: {
    fontSize: '24px',
    '@media(max-width: 768px)': {
      fontSize: '18px'
    }
  }
})

export const GetLenderLetter = () => {
  const classes = useStyles()
  const [currentGetLenderValue, setCurrentGetLenderValue] = useState(0)
  const [resultValue, setResultValue] = useState(0)
  const screens = [
    {
      title: '',
      text1: `The first step in buying a home is becoming pre-approved
        by a lender. This let's you know exactly what you can afford,
        and show sellers you are qualified and a serious buyer.`,
      text2: `In order to assign you a lender, we will need your
        permission to send your contact information to the lender.`,
      variants: [
        {
          label: 'Yes, I give my authorization',
          value: 2
        },
        {
          label: 'No, I already have a lender',
          value: 1
        }
      ]
    },
    {
      title: 'Just to Confirm',
      text1: `By using a non-affiliated lender you may
            not qualified for any rebate.`,
      text2: 'You already have a lender and are not interested in a rebate?',
      variants: [
        {
          label: 'I am using my lender and decline any rebate I may be entitled to.',
          value: 3
        },
        {
          label: 'I would prefer to use a HomeQual lender.',
          value: 2
        }
      ]
    },
    {
      text1: 'We will research the ideal lender for your situation and introduce you shortly.',
      variants: []
    },
    {
      text1: 'You have selected to use your own lender and are declining any rebate you may be entitled to.',
      variants: []
    }
  ]

  return (
    <>
      <Box
        sx={ {
          position: 'relative'
        } }
      >
        <Box
          sx={ {
            display: 'flex',
            maxWidth: '800px',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: '#fff',
            padding: '0 20px',
            margin: '30px auto 0',
            borderRadius: '3px'
          } }
          color="primary"
        >
          {
            screens[resultValue].title && <Typography className={ classes.title }>
                { screens[resultValue].title }
            </Typography>
          }
          {
            screens[resultValue].text1 &&
              <Typography className={ classes.text } marginBottom='40px' textAlign='center' color='#999'>
                { screens[resultValue].text1 }
              </Typography>
          }
          {
            screens[resultValue].text2 &&
              <Typography className={ classes.text } marginBottom='40px' textAlign="center" color="#999">
                { screens[resultValue].text2 }
              </Typography>
          }
          {
            screens[resultValue].variants.length > 0 && <Box
              sx={ {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: '#fff',
                padding: '5% 8%',
                margin: '0 auto 60px',
                border: '1px solid rgb(237,31,138)',
                borderRadius: '2px',
                boxShadow: '10px 5px 5px #ccc',
                '@media screen and (max-width: 800px)': {
                  marginBottom: '30px'
                }
              } }
            >
              <RadioGroup
                name='getLender-buttons-group'
                defaultValue='Y'
                onChange={ (e, value) => {
                  setCurrentGetLenderValue(+value)
                } }
              >
                {
                  screens[resultValue].variants && screens[resultValue].variants.map((variant: any) => {
                    return (
                      <FormControlLabel
                        control={ <Radio id='getLenderY' sx={ { transform: 'scale(1.3)' } }/> }
                        label={
                          <Typography
                            fontSize='28px'
                            fontWeight='bold'
                            marginLeft='15px'
                            sx={ {
                              '@media screen and (max-width: 700px)': {
                                fontSize: '18px'
                              },
                              '@media screen and (max-width: 500px)': {
                                fontSize: '15px'
                              }
                            } }
                          >
                            { variant.label }
                          </Typography>
                        }
                        name='getLender'
                        value={ variant.value }
                        color="primary"
                        sx={ { margin: '1vh 0', minWidth: '50px', fontSize: '17px' } }
                      />
                    )
                  })
                }
              </RadioGroup>
            </Box>
          }
          {
            resultValue < 2 && <Box
              sx={ {
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#fff',
                position: 'relative',
                padding: '25px 10px',
                borderRadius: '5px',
                marginBottom: '40px'
              } }
              color='primary'
            >
              <Button
                onClick={ () => {
                  setResultValue(currentGetLenderValue)
                } }
                type="submit"
                className={ classes.saveButton }
                size="large"
              >
                <Typography variant="h5">Submit</Typography>
              </Button>
            </Box>
          }
        </Box>
      </Box>
    </>
  )
}
