import React from 'react'
import { Box, FormControlLabel, Checkbox, Typography } from '@mui/material'
import makeStyles from '@material-ui/core/styles/makeStyles'

interface IChecklistStepVariant {
  label: string;
  name: string;
  value: string;
  checked: boolean;
}

interface IChecklistStep {
  label: string;
  description: string;
  steps: IChecklistStepVariant[];
  handleChange: any;
}

const useStyles = makeStyles({
  label: {
    display: 'inline',
    fontSize: '21px',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: '#000'
  },
  radioLabel: {
    justifyContent: 'space-between',
    padding: '0 15px 0'
  },
  description: {
    display: 'inline',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStyle: 'italic',
    color: '#999'
  }
})

export const ChecklistStep = (props: IChecklistStep) => {
  const { label, description, steps, handleChange } = props
  const classes = useStyles()
  return (
    <>
      <Box
        sx={ {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: '#fff',
          position: 'relative',
          padding: '10px 15px',
          borderRadius: '5px',
          marginBottom: '20px'
        } }
        color='primary'
      >
        <Typography className={ classes.label }>
          { label }
          <span className={ classes.description }> { description } </span>
        </Typography>

        {
          steps.map((step) => {
            return <FormControlLabel control={ <Checkbox id='getLenderN'sx={ { transform: 'scale(1.3)' } }/> }
             label={
               <Typography
                 fontSize='21px'
                 fontWeight='bold'
                 sx={ {
                   '@media(max-width: 700px)': {
                     fontSize: '18px'
                   },
                   '@media(max-width: 600px)': {
                     fontSize: '15px'
                   },
                   '@media(max-width: 400px)': {
                     fontSize: '12px'
                   }
                 } }
               >
                 { step.label }
               </Typography>
             }
             onChange={ (
               event,
               checked
             ) => handleChange(event, checked, step.name)
             }
             labelPlacement='start'
             name={ step.name }
             value={ step.value }
             checked={ !!step.checked }
             color="primary"
             sx={ { margin: '1vh 0', minWidth: '50px', fontSize: '17px' } }
             className={ classes.radioLabel }
            />
          })
        }
      </Box>
    </>
  )
}
