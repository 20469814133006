import React from 'react'
import { Switch, Box, Typography, FormControlLabel } from '@mui/material'
import { patchQualificationIssue } from '../../../../../shared/user/userQueries'
import useCurrentData from '../../../../../shared/hooks/useCurrentData'
import { styled } from '@mui/material/styles'

interface ISolutionOddsBlock {
  issueDescription: string
  issueDetails: string
  issueRecommendation: string
  issueResolvedFlag : boolean
  issueId: number
  blocksValues: any
  setBlocksValues: any
}

export const SolutionOddsBlock: React.FC<ISolutionOddsBlock> = ({
  issueDescription,
  issueDetails,
  issueRecommendation,
  issueResolvedFlag,
  issueId,
  blocksValues,
  setBlocksValues
}) => {
  const SwitchStyled = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-track': {
      background: 'red'
    },
    '& .MuiSwitch-thumb': {
      background: issueResolvedFlag ? '#55bd59' : 'red'
    }
  }))
  const { currentData } = useCurrentData()
  const handleChange = () => {
    const params = {
      memberId: currentData.memberId,
      issueId,
      issueResolvedFlag: !issueResolvedFlag
    }
    patchQualificationIssue(params)
      .then((response: any) => {
        const newBlockValues = JSON.parse(JSON.stringify(blocksValues))
        newBlockValues.forEach((block: any, index: number) => {
          if (response.data.issueId === block.issueId) {
            newBlockValues[index].issueResolvedFlag = response.data.issueResolvedFlag
          }
        })
        setBlocksValues(newBlockValues)
      })
      .catch((err: any) => {
        console.error(err)
      })
  }
  return (
    <>
      <Box
        sx={ {
          display: 'flex',
          maxWidth: '800px',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: '#eee',
          padding: '20px',
          margin: '30px auto',
          borderRadius: '3px',
          '@media screen and (max-width : 850px)': {
            margin: '30px 20px'
          }
        } }
        color="primary"
      >
        <Typography marginBottom='20px' textAlign="left" color="#666"
          sx={ {
            fontSize: '24px',
            '@media screen and (max-width : 767px)': {
              fontSize: '20px'
            }
          } }
        >
         {issueDescription}
        </Typography>
        <Typography marginBottom='20px' fontStyle="italic" textAlign="left" color="#999"
          sx={ {
            fontSize: '22.4px',
            '@media screen and (max-width : 767px)': {
              fontSize: '18px'
            }
          } }
        >
          {issueDetails}
        </Typography>
        <Typography marginBottom='20px' textAlign="left" color="rgb(237,31,138)"
          sx={ {
            fontSize: '24px',
            '@media screen and (max-width : 767px)': {
              fontSize: '20px'
            }
          } }
        >
          HomeQual Recommends
        </Typography>
        <Typography marginBottom='20px' fontStyle="italic" textAlign="left" color="#999"
          sx={ {
            fontSize: '22.4px',
            '@media screen and (max-width : 767px)': {
              fontSize: '18px'
            }
          } }
        >
          {issueRecommendation}
        </Typography>
        {
          issueId > 0 && <>
            <Box
                sx={ {
                  display: 'flex',
                  justifyContent: 'right'
                } }
            >
              {
                !issueResolvedFlag && <Typography
                    maxWidth='400px'
                    lineHeight='20px'
                    fontSize='14px'
                    textAlign='left'
                    color='#999'
                    marginTop='30px'
                    marginRight='10px'
                >
                    After you have reviewed or resolved this issue,
                    you can close it and we will recalculate your odds:
                </Typography>
              }
                <FormControlLabel
                    sx={ {
                      minHeight: '80px'
                    } }
                    onChange={handleChange}
                    checked={issueResolvedFlag}
                    control={<SwitchStyled color='success' />}
                    label={ issueResolvedFlag ? 'Resolved' : 'Resolve'}
                />
            </Box>
          </>
        }
      </Box>
    </>
  )
}
