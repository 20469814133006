import React, { FC, memo } from 'react'
import { Grid, Radio, Typography } from '@mui/material'

interface IRadioFieldNewQuestions {
  question: any
  handleChange: any
  allValues: any
}

export const RadioFieldNewQuestions: FC<IRadioFieldNewQuestions> = memo(({
  question,
  handleChange,
  allValues
}) => {
  return (
    <>
      {
        !allValues.coBorrowerFlag || !question.co_answers
          ? <Grid container direction="column" wrap="nowrap" sx={ { width: 'auto' } }>
            { question.answers.map((item: any, index: number) => (
              <Grid container key={ index } sx={ { margin: '5px 0' } }>
                <Radio
                  onChange={ (event) => {
                    handleChange(event)
                  } }
                  checked={
                    allValues[question.formName] === item.value ||
                    allValues[question.formName] === +item.value ||
                    +allValues[question.formName] === +item.value || false
                  }
                  id={ item.text + index }
                  value={ item.value }
                  name={ question.formName }
                  sx={ { transform: 'scale(1)', marginRight: '15px' } }
                />
                <label htmlFor={ item.text + index }>
                  <Typography
                    sx={ {
                      fontSize: '20px',
                      lineHeight: '37px',
                      '@media screen and (max-width : 600px)': {
                        fontSize: '16px'
                      }
                    } }
                  >{ item.text }</Typography>
                </label>
              </Grid>
            )) }
          </Grid>
          : <>
            <Grid container wrap="nowrap" minWidth='250px' width={ 'auto' }>
              <Typography variant="h6" sx={ {
                maxWidth: '48px',
                marginRight: '20px',
                textAlign: 'left',
                fontWeight: 'bold'
              } }>
                You
              </Typography>
              <Typography variant="h6" sx={ { width: 'auto', textAlign: 'left', fontWeight: 'bold' } }>
                Co Borrower
              </Typography>
            </Grid>

            <Grid container direction="column" wrap="nowrap" width={ 'auto' }>
              { question.answers.map((item: any, index: any) => (
                <Grid container width={ '100%' } alignItems="center" key={ index } wrap="nowrap" my={ 1 }>

                  <Radio
                    checked={
                      allValues[question.formName] === item.value ||
                      allValues[question.formName] === +item.value ||
                      +allValues[question.formName] === +item.value || false
                    }
                    value={ item.value }
                    onChange={ (event) => {
                      handleChange(event)
                    } }
                    name={ question.formName }
                    sx={ { transform: 'scale(1)', mr: 1 } }/>

                  <Radio checked={
                    allValues[question.co_formName] === question.co_answers[index].value ||
                    allValues[question.co_formName] === +question.co_answers[index].value ||
                    +allValues[question.co_formName] === +question.co_answers[index].value || false
                  }
                         value={ question.co_answers[index].value }
                         onChange={ (event) => {
                           handleChange(event)
                         } }
                         name={ question.co_formName }
                         sx={ { transform: 'scale(1)', marginRight: '15px' } }/>
                  <Typography fontSize='20px' maxWidth='170px'>{ item.text }</Typography>
                </Grid>
              )) }

            </Grid>
          </>
      }
    </>
  )
})
