import { useEffect, useState } from 'react'
import { Box, Typography, TextareaAutosize, Button } from '@mui/material'
import { DesignHomeBlock } from './DesignHomeBlock'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  getDesignHomeValues,
  designHomeUpdate
} from '../../../../../shared/user/userQueries'
import useCurrentData from '../../../../../shared/hooks/useCurrentData'

const useStyles = makeStyles({
  label: {
    position: 'absolute',
    fontSize: '14px',
    top: '-7px',
    left: '40px',
    background: '#fff',
    padding: '0 8px',
    color: '#999'
  },
  saveButton: {
    marginBottom: '15px',
    fontWeight: 'bold',
    backgroundColor: 'rgb(237, 31, 138)',
    color: '#fff',
    border: '1px solid',
    minWidth: '300px',
    width: '50vw',
    maxWidth: '600px',
    padding: '15px 10px',
    borderRadius: '30px',
    textTransform: 'none',
    '&:hover, &:focus, &:disabled': {
      backgroundColor: '#fff',
      color: 'rgb(237, 31, 138)',
      border: '1px solid rgb(237, 31, 138)'
    }
  }
})

export const DesignHome = () => {
  const classes = useStyles()
  const { currentData } = useCurrentData()
  const [designHomeValues, setDesignHomeValues] = useState<any>(false)
  const [buttonTitle, setButtonTitle] = useState<string>('Save My Home')
  const [buttonIsActive, setButtonIsActive] = useState<boolean>(true)
  useEffect(() => {
    if (!designHomeValues) {
      getDesignHomeValues({ memberId: currentData.memberId })
        .then((response: any) => {
          setDesignHomeValues(response.data)
        })
        .catch((err: any) => {
          console.error(err)
        })
    }
  }, [currentData.memberId])
  const handleChange = (event: any, checked: any, name: string) => {
    const currentSavingData = Object.assign({}, designHomeValues)
    currentSavingData[name] = checked
    setButtonTitle('Save My Home')
    setButtonIsActive(true)
    setDesignHomeValues(currentSavingData)
  }
  const handleCommentChange = (event: any) => {
    const currentSavingData = Object.assign({}, designHomeValues)
    currentSavingData.designComments = event.target.value
    setButtonTitle('Save My Home')
    setButtonIsActive(true)
    setDesignHomeValues(currentSavingData)
  }
  const handleSave = () => {
    setButtonIsActive(false)
    designHomeUpdate(designHomeValues)
      .then((response: any) => {
        setButtonTitle('Saved')
        console.log(response)
      })
      .catch((err: any) => {
        console.error(err)
      })
  }
  const designHomeQuestions = [
    {
      label: 'Property Type',
      variants: [
        {
          label: 'Any',
          name: 'propertyTypeAnyFlag',
          value: '1',
          checked: designHomeValues.propertyTypeAnyFlag || false
        },
        {
          label: 'Condo',
          name: 'propertyTypeCondoFlag',
          value: '1',
          checked: designHomeValues.propertyTypeCondoFlag || false
        },
        {
          label: 'Single Family House',
          name: 'propertyTypeSingleFamilyFlag',
          value: '1',
          checked: designHomeValues.propertyTypeSingleFamilyFlag || false
        },
        {
          label: 'Townhome',
          name: 'propertyTypeTownhomeFlag',
          value: '1',
          checked: designHomeValues.propertyTypeTownhomeFlag || false
        }
      ]
    },
    {
      label: 'Type of Home',
      variants: [
        {
          label: 'Any',
          name: 'typeOfHomeAnyFlag',
          value: '1',
          checked: designHomeValues.typeOfHomeAnyFlag || false
        },
        {
          label: 'Existing Home',
          name: 'typeOfHomeExistingHomeFlag',
          value: '1',
          checked: designHomeValues.typeOfHomeExistingHomeFlag || false
        },
        {
          label: 'New Construction',
          name: 'typeOfHomeNewConstructionFlag',
          value: '1',
          checked: designHomeValues.typeOfHomeNewConstructionFlag || false
        }
      ]
    },
    {
      label: 'Stories',
      variants: [
        {
          label: 'Any',
          name: 'storiesAnyFlag',
          value: '1',
          checked: designHomeValues.storiesAnyFlag || false
        },
        {
          label: 'Single',
          name: 'storiesSingleFamilyFlag',
          value: '1',
          checked: designHomeValues.storiesSingleFamilyFlag || false
        },
        {
          label: 'Multi',
          name: 'storiesMultiFlag',
          value: '1',
          checked: designHomeValues.storiesMultiFlag || false
        }
      ]
    },
    {
      label: 'Bedrooms',
      variants: [
        {
          label: 'Any',
          name: 'bedroomsAnyFlag',
          value: '1',
          checked: designHomeValues.bedroomsAnyFlag || false
        },
        {
          label: '3+',
          name: 'bedrooms3Flag',
          value: '1',
          checked: designHomeValues.bedrooms3Flag || false
        },
        {
          label: '1+',
          name: 'bedrooms1Flag',
          value: '1',
          checked: designHomeValues.bedrooms1Flag || false
        },
        {
          label: '4+',
          name: 'bedrooms4Flag',
          value: '1',
          checked: designHomeValues.bedrooms4Flag || false
        },
        {
          label: '2+',
          name: 'bedrooms2Flag',
          value: '1',
          checked: designHomeValues.bedrooms2Flag || false
        },
        {
          label: '5+',
          name: 'bedrooms5Flag',
          value: '1',
          checked: designHomeValues.bedrooms5Flag || false
        }
      ]
    },
    {
      label: 'Bathrooms',
      variants: [
        {
          label: 'Any',
          name: 'bathroomsAnyFlag',
          value: '1',
          checked: designHomeValues.bathroomsAnyFlag || false
        },
        {
          label: '2+',
          name: 'bathrooms2Flag',
          value: '1',
          checked: designHomeValues.bathrooms2Flag || false
        },
        {
          label: '1+',
          name: 'bathrooms1Flag',
          value: '1',
          checked: designHomeValues.bathrooms1Flag || false
        },
        {
          label: '3+',
          name: 'bathrooms3Flag',
          value: '1',
          checked: designHomeValues.bathrooms3Flag || false
        },
        {
          label: '1 & 1/2 +',
          name: 'bathrooms1HalfFlag',
          value: '1',
          checked: designHomeValues.bathrooms1HalfFlag || false
        },
        {
          label: '4+',
          name: 'bathrooms4Flag',
          value: '1',
          checked: designHomeValues.bathrooms4Flag || false
        }
      ]
    },
    {
      label: 'Inside Features',
      variants: [
        {
          label: 'Open Floorplan',
          name: 'insideFeaturesOpenFloorplanFlag',
          value: '1',
          checked: designHomeValues.insideFeaturesOpenFloorplanFlag || false
        },
        {
          label: 'Hardwood Floors',
          name: 'insideFeaturesHardwoodFloorsFlag',
          value: '1',
          checked: designHomeValues.insideFeaturesHardwoodFloorsFlag || false
        },
        {
          label: 'Dining room',
          name: 'insideFeaturesDiningRoomFlag',
          value: '1',
          checked: designHomeValues.insideFeaturesDiningRoomFlag || false
        },
        {
          label: 'Fireplace',
          name: 'insideFeaturesFireplaceFlag',
          value: '1',
          checked: designHomeValues.insideFeaturesFireplaceFlag || false
        },
        {
          label: 'Family Room',
          name: 'insideFeaturesFamilyRoomFlag',
          value: '1',
          checked: designHomeValues.insideFeaturesFamilyRoomFlag || false
        },
        {
          label: 'Energy Efficient',
          name: 'insideFeaturesEnergyEfficientFlag',
          value: '1',
          checked: designHomeValues.insideFeaturesEnergyEfficientFlag || false
        },
        {
          label: 'Office',
          name: 'insideFeaturesOfficeFlag',
          value: '1',
          checked: designHomeValues.insideFeaturesOfficeFlag || false
        },
        {
          label: 'Disability Features',
          name: 'insideFeaturesDisabilityFeaturesFlag',
          value: '1',
          checked: designHomeValues.insideFeaturesDisabilityFeaturesFlag || false
        },
        {
          label: 'Basement',
          name: 'insideFeaturesBasementFlag',
          value: '1',
          checked: designHomeValues.insideFeaturesBasementFlag || false
        }
      ]
    },
    {
      label: 'Outside Features',
      variants: [
        {
          label: 'Deck',
          name: 'outsideFeaturesDeckFlag',
          value: '1',
          checked: designHomeValues.outsideFeaturesDeckFlag || false
        },
        {
          label: 'Spa / Hot Tab',
          name: 'outsideFeaturesSpaHotTubFlag',
          value: '1',
          checked: designHomeValues.outsideFeaturesSpaHotTubFlag || false
        },
        {
          label: 'Patio',
          name: 'outsideFeaturesPatioFlag',
          value: '1',
          checked: designHomeValues.outsideFeaturesPatioFlag || false
        },
        {
          label: 'Large Yard',
          name: 'outsideFeaturesLargeYardFlag',
          value: '1',
          checked: designHomeValues.outsideFeaturesLargeYardFlag || false
        },
        {
          label: 'Pool',
          name: 'outsideFeaturesPoolFlag',
          value: '1',
          checked: designHomeValues.outsideFeaturesPoolFlag || false
        },
        {
          label: 'Fenced Yard',
          name: 'outsideFeaturesFencedYardFlag',
          value: '1',
          checked: designHomeValues.outsideFeaturesFencedYardFlag || false
        }
      ]
    },
    {
      label: 'Garage',
      variants: [
        {
          label: 'Any',
          name: 'garageAnyFlag',
          value: '1',
          checked: designHomeValues.garageAnyFlag || false
        },
        {
          label: '3+',
          name: 'garage3Flag',
          value: '1',
          checked: designHomeValues.garage3Flag || false
        },
        {
          label: '1+',
          name: 'garage1Flag',
          value: '1',
          checked: designHomeValues.garage1Flag || false
        },
        {
          label: '4+',
          name: 'garage4Flag',
          value: '1',
          checked: designHomeValues.garage4Flag || false
        },
        {
          label: '2+',
          name: 'garage2Flag',
          value: '1',
          checked: designHomeValues.garage2Flag || false
        },
        {
          label: 'None',
          name: 'garageNoneFlag',
          value: '1',
          checked: designHomeValues.garageNoneFlag || false
        }
      ]
    }
  ]

  return (
    <>
      <Box
        sx={ {
          display: 'flex',
          maxWidth: '800px',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: '#fff',
          padding: '0 20px',
          margin: '30px auto 0',
          borderRadius: '3px'
        } }
        color='primary'
      >
        <Typography variant='h5' fontWeight='bold' marginBottom='25px' textAlign='center' color='#999'>
          Let's Begin Designing Your Dream Home
        </Typography>
        <Typography fontSize='18px' marginBottom='40px' textAlign='center' color='#999'>
          Select the options you'd like in your perfect home.
        </Typography>
        {
          designHomeQuestions.map((question, index) => {
            return <DesignHomeBlock key={ index } handleChange={ handleChange } { ...question}/>
          })
        }
        <Box
          sx={ {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#fff',
            position: 'relative',
            padding: '25px 10px',
            border: '1px solid rgb(237,31,138)',
            borderRadius: '5px',
            marginBottom: '40px'
          } }
          color='primary'
        >
          <span className={ classes.label }>Additional Comments</span>
          <TextareaAutosize
            aria-label='empty textarea'
            name='designComments'
            value={ designHomeValues.designComments }
            style={{ width: '100%', fontSize: '22px', border: 'none', minHeight: '150px' }}
            onChange={ (event) => handleCommentChange(event)
            }
          />
        </Box>
        <Box
          sx={ {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#fff',
            position: 'relative',
            padding: '25px 10px',
            borderRadius: '5px',
            marginBottom: '40px'
          } }
          color='primary'
        >
          <Button
            onClick={ handleSave }
            type="submit"
            className={ classes.saveButton }
            size="large"
            disabled={ !buttonIsActive }
          >
            <Typography variant="h5">{ buttonTitle }</Typography>
          </Button>
        </Box>
      </Box>
    </>
  )
}
