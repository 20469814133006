import { FC, useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { getSystemGlobals } from '../user/userQueries'

interface IHomeQualText {
  variant: Variant,
  fontWeight?: string
}

export const HomeQualTextTemplate: FC<IHomeQualText> = ({ variant, fontWeight }) => {
  return (
    <>
      <Typography variant={ variant } fontWeight={ fontWeight ?? 'inherit' } component="span">
        Home
      </Typography>
      <Typography variant={ variant } fontWeight={ fontWeight ?? 'initial' } color={ 'primary' } component="span">
        Qual
      </Typography>
    </>

  )
}
export const Copyrights: FC = () => {
  const [copyright, setCopyright] = useState('')

  useEffect(() => {
    if (!copyright) {
      getSystemGlobals()
        .then((response: any) => {
          setCopyright(response.data.copyright)
        })
        .catch((err: any) => {
          console.error(err)
        })
    }
  }, [copyright])

  return <Typography variant="h6" sx={ {
    fontStyle: 'italic',
    padding: '5px 20px',
    textAlign: 'center',
    fontSize: { xs: '0.9rem', md: '1rem', lg: '1.1rem' }
  } }>
    { copyright }
  </Typography>
}
