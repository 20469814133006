import { InputField } from './shared'
import React, { FC, memo } from 'react'
import { Grid } from '@mui/material'

interface IChangeCredentials {
  question: any
  handleChange: any
  allValues: any
}

export const ChangeCredentials: FC<IChangeCredentials> = memo(({
  question,
  handleChange,
  allValues
}) => {
  return (
    <Grid container direction="column" sx={ {
      paddingLeft: '100px',
      '@media screen and (max-width : 768px)': {
        paddingLeft: '10vw'
      }
    } }>
      { question.inputs.map((item: any, index: number) => <InputField
        item={ item }
        allValues={ allValues }
        key={ index }
        handleChange={ handleChange }
        index={ index }/>)
      }
    </Grid>
  )
})
