import React, { FC, memo } from 'react'
import { Grid, TextField, Typography, Radio } from '@mui/material'
import InputMask from 'react-input-mask'
import makeStyles from '@material-ui/core/styles/makeStyles'

interface ITextFieldRadioQuestions {
  question: any
  handleChange: any
  allValues: any
}

const styles = makeStyles(theme => ({
  notchedOutline: {
    borderColor: '#ccc !important'
  }
}))

export const TextFieldRadioQuestions: FC<ITextFieldRadioQuestions> = memo(({
  question,
  handleChange,
  allValues
}) => {
  const classes = styles()

  return (
    <Grid container direction="column" sx={ {
      paddingLeft: '100px',
      '@media screen and (max-width : 768px)': {
        paddingLeft: '10vw'
      }
    } }>
      { question.inputs.map((item: any, index: number) => (
        <InputMask
          autoFocus={index === 0}
          autoComplete='no'
          mask="99/99/9999"
          onChange={ (event: any) => {
            handleChange(event)
          } }
          value={ allValues[item.value] === null ? '' : allValues[item.value] }
          disabled={false}
        >
          {() =>
            <TextField
              variant="outlined"
              name={item.value}
              InputProps={{
                style: { fontSize: '1.4rem', fontWeight: 'bold' },
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
              sx={{
                minWidth: '100px',
                maxWidth: '460px',
                margin: '15px 0 5px 0',
                paddingRight: '100px',
                '@media screen and (max-width : 768px)': {
                  paddingRight: '10vw'
                }
              }}
              label={
                <Typography variant="h6" color="#999" sx={{
                  fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
                }}>
                  {item.text}
                </Typography>}
            />
          }
        </InputMask>
      )) }
      <Grid container direction="column" wrap="nowrap" sx={ { width: 'auto' } }>
        { question.answers.map((item: any, index: number) => (
          <Grid container key={ index } sx={ { margin: '5px 0' } }>
            <Radio
              onChange={ (event: any) => {
                handleChange(event)
              } }
              checked={ allValues[question.formName] === item.value || allValues[question.formName] === +item.value}
              id={ item.text + index }
              value={ item.value }
              name={ question.formName }
              sx={ { transform: 'scale(1.3)', marginRight: '15px' } }
            />
            <label htmlFor={ item.text + index }>
              <Typography variant="h6">{ item.text }</Typography>
            </label>
          </Grid>
        )) }
      </Grid>
    </Grid>
  )
})
