import { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CloseIcon from '@mui/icons-material/Close'
import {
  getMemberCurrentValues,
  getMemberDetailsValues,
  updateMember,
  updateMemberDetail
} from '../../../../../shared/functions'
import { patchCoBorrowerReRoll, userChangePasswordQuery } from '../../../../../shared/user/userQueries'
import { toast } from 'react-toastify'
import useCurrentOtherSectionsData from '../../../../../shared/hooks/useCurrentOtherSectionsData'

interface IUpdateYourInformationPopup {
  label: string
  question?: any
  constructor?: any
  popUpHandler: any
  setShowPopup: any
  setCurrentQuestionNumber: any
  isLastQuestion: boolean
  allValues: any
  setAllValues: any
}

const useStyles = makeStyles({
  label: {
    position: 'absolute',
    fontSize: '14px',
    top: '-7px',
    left: '40px',
    background: '#fff',
    padding: '0 8px',
    color: '#999'
  },
  saveButton: {
    marginTop: '25px',
    marginBottom: '40px',
    fontWeight: 'bold',
    backgroundColor: '#fff',
    color: 'rgb(237, 31, 138)',
    border: '1px solid rgb(237, 31, 138)',
    minWidth: '160px',
    maxWidth: '600px',
    padding: '10px 10px',
    borderRadius: '30px',
    textTransform: 'none',
    '&:hover, &:focus, &:disabled': {
      color: '#fff',
      backgroundColor: 'rgb(237, 31, 138)',
      border: '1px solid'
    },
    '@media(max-width: 400px)': {
      minWidth: '130px'
    }
  },
  close: {
    position: 'absolute',
    top: '20px',
    right: '25px',
    cursor: 'pointer',
    width: '30px',
    height: '30px'
  }
})

export const UpdateYourInformationPopup = (props: IUpdateYourInformationPopup) => {
  const classes = useStyles()
  const [password, setPassword] = useState('')
  const [showCoBorrowerVerification, setShowCoBorrowerVerification] = useState(false)
  const {
    label,
    question,
    constructor,
    popUpHandler,
    setShowPopup,
    isLastQuestion,
    setCurrentQuestionNumber,
    allValues,
    setAllValues
  } = props
  const { currentOtherSectionsData, setCurrentOtherSectionsData } = useCurrentOtherSectionsData()
  const saveHandler = (isCoBorrowerRemoveSure = false) => {
    let isValid = true
    if (question.validation) {
      for (const key in question.validation) {
        isValid = question.validation[key].isValidSync(allValues[key])
      }
    }
    if (!isValid) {
      return
    }
    if (question.formName === 'coBorrowerFlag' && allValues.coBorrowerFlag === false && !isCoBorrowerRemoveSure) {
      setShowCoBorrowerVerification(true)
      return
    }
    popUpHandler()
    if (!isLastQuestion) {
      return
    }
    if (question.changeCredentials) {
      if (password) {
        const changePasswordParams = {
          memberId: allValues.memberId,
          password,
          emailAddress: allValues.emailAddress,
          serviceTypeId: allValues.serviceTypeId,
          userTypeId: allValues.userTypeId
        }
        userChangePasswordQuery(changePasswordParams)
          .then((response: any) => {
            console.log(response)
          })
          .catch((err: any) => {
            toast.error('Opps! Something went wrong on our side. Please try again. If you still get an error, please contact us. Thanks.')
            console.error(err)
          })
      }
    } else {
      getMemberCurrentValues(allValues.memberId)
        .then((response: any) => {
          setCurrentOtherSectionsData(allValues)
          return updateMember(allValues, response.data)
        })
        .catch((err: any) => {
          console.error(err)
        })

      getMemberDetailsValues(allValues.memberId)
        .then((response: any) => {
          popUpHandler()
          setCurrentOtherSectionsData(allValues)
          const newCurrentData = Object.assign({}, allValues)
          const isHaveStudentLoan = allValues.lateStudentLoanFlag
          const isHaveTax = allValues.taxLienJudgementForeclosureFlag
          const isCoHaveStudentLoan = allValues.coLateStudentLoanFlag
          const isCoHaveTax = allValues.coTaxLienJudgementForeclosureFlag
          if (!isHaveStudentLoan && !isHaveTax && !isCoHaveStudentLoan && !isCoHaveTax) {
            newCurrentData.studentLoanDefault = ''
            newCurrentData.coStudentLoanDefault = ''
            newCurrentData.taxDetails = ''
            newCurrentData.coTaxDetails = ''
            newCurrentData.taxLienFlag = false
            newCurrentData.foreclosure24MonthsFlag = false
            newCurrentData.foreclosure36MonthsFlag = false
            newCurrentData.foreclosure48MonthsFlag = false
            newCurrentData.judgementFlag = false
            newCurrentData.studentLoanDefaultFlag = false
            newCurrentData.studentLoanRemediationFlag = false
            newCurrentData.studentLoanDefaultNoneFlag = false
            newCurrentData.coTaxLienFlag = false
            newCurrentData.coForeclosure24MonthsFlag = false
            newCurrentData.coForeclosure36MonthsFlag = false
            newCurrentData.coForeclosure48MonthsFlag = false
            newCurrentData.coJudgementFlag = false
            newCurrentData.coStudentLoanDefaultFlag = false
            newCurrentData.coStudentLoanRemediationFlag = false
            newCurrentData.coStudentLoanDefaultNoneFlag = false
            setCurrentOtherSectionsData(newCurrentData)
          } else if (!isHaveStudentLoan && !isCoHaveStudentLoan && (isHaveTax || isCoHaveTax)) {
            newCurrentData.studentLoanDefault = ''
            newCurrentData.coStudentLoanDefault = ''
            newCurrentData.studentLoanDefaultFlag = false
            newCurrentData.studentLoanRemediationFlag = false
            newCurrentData.studentLoanDefaultNoneFlag = false
            newCurrentData.coStudentLoanDefaultFlag = false
            newCurrentData.coStudentLoanRemediationFlag = false
            newCurrentData.coStudentLoanDefaultNoneFlag = false
            setCurrentOtherSectionsData(newCurrentData)
          } else if (!isHaveStudentLoan && !isHaveTax) {
            newCurrentData.studentLoanDefault = ''
            newCurrentData.taxDetails = ''
            newCurrentData.coTaxDetails = ''
            newCurrentData.taxLienFlag = false
            newCurrentData.foreclosure24MonthsFlag = false
            newCurrentData.foreclosure36MonthsFlag = false
            newCurrentData.foreclosure48MonthsFlag = false
            newCurrentData.judgementFlag = false
            newCurrentData.studentLoanDefaultFlag = false
            newCurrentData.studentLoanRemediationFlag = false
            newCurrentData.studentLoanDefaultNoneFlag = false
            setCurrentOtherSectionsData(newCurrentData)
          } else if (isHaveTax && !isHaveStudentLoan) {
            newCurrentData.studentLoanDefault = ''
            newCurrentData.studentLoanDefaultFlag = false
            newCurrentData.studentLoanRemediationFlag = false
            newCurrentData.studentLoanDefaultNoneFlag = false
            setCurrentOtherSectionsData(newCurrentData)
          }
          return updateMemberDetail(newCurrentData, response.data.memberId, response.data, 23)
            .then((response: any) => {
              if (question.borrowerLoans && !+allValues.coBorrowerFlag) {
                patchCoBorrowerReRoll(allValues.memberId)
                  .then((response: any) => {
                    console.log(response)
                  })
                  .catch((err: any) => {
                    console.error(err)
                  })
              }
            })
            .catch((err: any) => {
              console.error(err)
            })
        })
        .catch((err: any) => {
          console.error(err)
        })
    }
    setCurrentQuestionNumber(0)
  }
  const handleChange = (event: any) => {
    if (question.changeCredentials) {
      setPassword(event.target.value)
    } else {
      const newCurrentData = Object.assign({}, allValues)
      newCurrentData[event.target.name] = event.target.name.indexOf('Flag') !== -1
        ? event.target.checked
          ? !!event.target.value
          : !event.target.value
        : event.target.value
      if (event.target.name === 'moveDate') {
        newCurrentData.moveRange = null
      }
      if (event.target.name === 'moveRange') {
        newCurrentData.moveDate = null
      }
      if (event.target.name === 'nonePaymentFlag') {
        question.answers.forEach((item: any) => {
          if (item.value !== 'nonePaymentFlag') {
            newCurrentData[item.value] = false
          }
        })
      }
      if (
        event.target.name === 'latePaymentsFlag' ||
        event.target.name === 'collectionsFlag' ||
        event.target.name === 'lateStudentLoanFlag' ||
        event.target.name === 'lateHousingPaymentsFlag' ||
        event.target.name === 'taxLienJudgementForeclosureFlag' ||
        event.target.name === 'bankruptcyFlag' ||
        event.target.name === 'taxesNotCurrentFlag'
      ) {
        newCurrentData.nonePaymentFlag = false
      }

      if (event.target.name === 'coNonePaymentFlag') {
        question.co_answers.forEach((item: any) => {
          if (item.value !== 'coNonePaymentFlag') {
            newCurrentData[item.value] = false
          }
        })
      }
      if (
        event.target.name === 'coLatePaymentsFlag' ||
        event.target.name === 'coCollectionsFlag' ||
        event.target.name === 'coLateStudentLoanFlag' ||
        event.target.name === 'coLateHousingPaymentsFlag' ||
        event.target.name === 'coTaxLienJudgementForeclosureFlag' ||
        event.target.name === 'coBankruptcyFlag' ||
        event.target.name === 'coTaxesNotCurrentFlag'
      ) {
        newCurrentData.coNonePaymentFlag = false
      }

      if (event.target.name === 'noneHistoryFlag') {
        question.answers.forEach((item: any) => {
          if (item.value !== 'noneHistoryFlag') {
            newCurrentData[item.value] = false
          }
        })
      }
      if (
        event.target.name === 'employmentGapsFlag' ||
        event.target.name === 'changedJobsFlag' ||
        event.target.name === 'selfEmployedFlag' ||
        event.target.name === 'noIncomeDocumentationFlag' ||
        event.target.name === 'commissionsBonusFlag'
      ) {
        newCurrentData.noneHistoryFlag = false
      }

      if (event.target.name === 'coNoneHistoryFlag') {
        question.co_answers.forEach((item: any) => {
          if (item.value !== 'coNoneHistoryFlag') {
            newCurrentData[item.value] = false
          }
        })
      }
      if (
        event.target.name === 'coEmploymentGapsFlag' ||
        event.target.name === 'coChangedJobsFlag' ||
        event.target.name === 'coSelfEmployedFlag' ||
        event.target.name === 'coNoIncomeDocumentationFlag' ||
        event.target.name === 'coCommissionsBonusFlag'
      ) {
        newCurrentData.coNoneHistoryFlag = false
      }

      if (event.target.name === 'livingSituation' && +event.target.value === 2) {
        newCurrentData.housingPaymentAmount = '0'
      }

      if (event.target.name === 'housingPaymentAmount' && +newCurrentData.livingSituation === 2) {
        newCurrentData.housingPaymentAmount = '0'
      }

      if (event.target.name === 'coLivingSituation' && +event.target.value === 2) {
        newCurrentData.coHousingPaymentAmount = '0'
      }

      if (event.target.name === 'coHousingPaymentAmount' && +newCurrentData.coLivingSituation === 2) {
        newCurrentData.coHousingPaymentAmount = '0'
      }

      if (event.target.name === 'coFirstName' || event.target.name === 'coLastName') {
        newCurrentData.coBorrowerFlag = !!newCurrentData.coFirstName && !!newCurrentData.coLastName
      }

      if (event.target.name === 'studentLoanDefault') {
        newCurrentData.studentLoanDefaultFlag = event.target.value === 'studentLoanDefaultFlag'
        newCurrentData.studentLoanRemediationFlag = event.target.value === 'studentLoanRemediationFlag'
        newCurrentData.studentLoanDefaultNoneFlag = event.target.value === 'studentLoanDefaultNoneFlag'
      }

      if (event.target.name === 'coStudentLoanDefault') {
        newCurrentData.coStudentLoanDefaultFlag = event.target.value === 'coStudentLoanDefaultFlag'
        newCurrentData.coStudentLoanRemediationFlag = event.target.value === 'coStudentLoanRemediationFlag'
        newCurrentData.coStudentLoanDefaultNoneFlag = event.target.value === 'coStudentLoanDefaultNoneFlag'
      }

      if (event.target.name === 'taxDetails') {
        newCurrentData.taxLienFlag = event.target.value === 'taxLienFlag'
        newCurrentData.foreclosure24MonthsFlag = event.target.value === 'foreclosure24MonthsFlag'
        newCurrentData.foreclosure36MonthsFlag = event.target.value === 'foreclosure36MonthsFlag'
        newCurrentData.foreclosure48MonthsFlag = event.target.value === 'foreclosure48MonthsFlag'
        newCurrentData.judgementFlag = event.target.value === 'judgementFlag'
      }

      if (event.target.name === 'coTaxDetails') {
        newCurrentData.coTaxLienFlag = event.target.value === 'coTaxLienFlag'
        newCurrentData.coForeclosure24MonthsFlag = event.target.value === 'coForeclosure24MonthsFlag'
        newCurrentData.coForeclosure36MonthsFlag = event.target.value === 'coForeclosure36MonthsFlag'
        newCurrentData.coForeclosure48MonthsFlag = event.target.value === 'coForeclosure48MonthsFlag'
        newCurrentData.coJudgementFlag = event.target.value === 'coJudgementFlag'
      }

      if (event.target.name === 'veteranFlag') {
        newCurrentData.veteranFlag = !!+event.target.value
      }

      if (event.target.name === 'coVeteranFlag') {
        newCurrentData.coVeteranFlag = !!+event.target.value
      }
      if (event.target.name === 'coBorrowerFlag') {
        newCurrentData.coRelationshipId = +event.target.value
        newCurrentData.coBorrowerFlag = !!+event.target.value
        if (event.target.value === '1') {
          newCurrentData.maritalStatus = 1
        } else {
          newCurrentData.maritalStatus = 0
        }
      }

      setAllValues(newCurrentData)
    }
  }
  const Constructor = constructor
  const closeHandler = () => {
    setAllValues(currentOtherSectionsData)
    setCurrentQuestionNumber(0)
    setShowPopup(false)
  }
  return (
    <>
      <Box
        sx={ {
          position: 'fixed',
          zIndex: 9,
          height: '100%',
          width: '100%',
          top: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: '#ccc',
          opacity: '0.9',
          padding: '10px 0'
        } }
      >
      </Box>
      <Box
        onClick={
          (event) => {
            if (event.target !== event.currentTarget) {
              return
            }
            closeHandler()
          }
        }
        sx={ {
          width: '100%',
          left: 0,
          position: 'fixed',
          display: 'flex',
          zIndex: 10,
          justifyContent: 'center',
          alignItems: 'center',
          top: '5%'
        } }
      >
        <Box
          onKeyPress={ (key: any) => {
            if (key.charCode === 13 && document.getElementById('continueButton') !== null) {
              document.getElementById('continueButton')?.focus()
            }
          } }
          sx={ {
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '800px',
            width: '800px',
            maxHeight: '100vh',
            overflow: 'auto',
            backgroundColor: '#fff',
            padding: '25px 20px',
            margin: '0 auto',
            borderRadius: '3px'
          } }
          color="primary"
        >
          <CloseIcon
            className={ classes.close }
            onClick={ closeHandler }
          />
          {
            !showCoBorrowerVerification
              ? <>
                <Typography variant="h5" mb={ 2 } sx={ {
                  fontWeight: 'bold',
                  padding: '0 100px',
                  '@media screen and (max-width : 768px)': {
                    padding: '0 10vw'
                  },
                  marginTop: '25px',
                  marginBottom: '15px',
                  whiteSpace: 'pre-wrap'
                } }>
                  { question.popupLabel || label }
                </Typography>
                <Constructor
                  allValues={ allValues }
                  handleChange={ handleChange }
                  question={ question }
                />
                <Box sx={ {
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%'
                } }>
                  <Button
                    id='continueButton'
                    onClick={ () => {
                      setAllValues(currentOtherSectionsData)
                      setCurrentQuestionNumber(0)
                    } }
                    type="submit"
                    className={ classes.saveButton }
                    size="medium"
                    sx={ {
                      marginRight: '15px'
                    } }
                  >
                    <Typography
                      fontSize='24px'
                    >
                      Undo
                    </Typography>
                  </Button>
                  <Button
                    id='continueButton'
                    onClick={ () => {
                      saveHandler()
                    } }
                    type="submit"
                    className={ classes.saveButton }
                    size="medium"
                  >
                    <Typography
                      fontSize='24px'
                    >
                      { isLastQuestion ? 'Save' : 'Continue' }
                    </Typography>
                  </Button>
                </Box>
              </>
              : <>
                <Typography variant="h5" mb={ 2 } sx={ {
                  fontWeight: 'bold',
                  padding: '0 100px',
                  '@media screen and (max-width : 768px)': {
                    padding: '0 10vw'
                  },
                  marginTop: '25px',
                  marginBottom: '15px',
                  whiteSpace: 'pre-wrap'
                } }>
                  Are you sure?
                </Typography>
                <Box sx={ {
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%'
                } }>
                  <Button
                    id='continueButton'
                    onClick={ () => {
                      setShowCoBorrowerVerification(false)
                      saveHandler(true)
                    } }
                    type="submit"
                    className={ classes.saveButton }
                    size="medium"
                    sx={ {
                      marginRight: '15px'
                    } }
                  >
                    <Typography
                      fontSize='24px'
                    >
                      Yes
                    </Typography>
                  </Button>
                  <Button
                    id='continueButton'
                    onClick={ () => {
                      setShowCoBorrowerVerification(false)
                      setAllValues(currentOtherSectionsData)
                      setCurrentQuestionNumber(0)
                    } }
                    type="submit"
                    className={ classes.saveButton }
                    size="medium"
                  >
                    <Typography
                      fontSize='24px'
                    >
                      No
                    </Typography>
                  </Button>
                </Box>
              </>
          }

        </Box>
      </Box>
    </>
  )
}
