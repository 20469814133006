import React, { useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { CenteredTypography } from '../../../shared/styles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useNavigate } from 'react-router-dom'
import {
  userLoginQuery,
  getLastQuestionCompleted, closeMemberChatQuery, closeVisitorChatQuery
} from '../../../shared/user/userQueries'
import useCurrentFormikValues from '../../../shared/hooks/useCurrentFormikValues'
import { questionsList } from '../QuestionsAndAnswers/questions'
import { getMemberCurrentValues, getMemberDetailsValues, navigateToCompletedQuestion } from '../../../shared/functions'
import CircularProgress from '@mui/material/CircularProgress'
import useCurrentData from '../../../shared/hooks/useCurrentData'
import axios from 'axios'
import useCurrentOtherSectionsData from '../../../shared/hooks/useCurrentOtherSectionsData'
import useShowChat from '../../../shared/hooks/useShowChat'

const useStyles = makeStyles({
  textOrEmailButton: {
    marginBottom: '15px',
    borderColor: 'rgb(237, 31, 138)',
    border: '1px solid',
    minWidth: '300px',
    width: '50vw',
    maxWidth: '600px',
    padding: '15px 10px',
    borderRadius: '30px'
  },
  notchedOutline: {
    borderColor: '#ccc !important'
  },
  loader: {
    width: '21px !important',
    height: '21px !important'
  }
})

export const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isShowLoader, setIsShowLoader] = useState(false)
  const [resultText, setResultText] = useState('')
  const classes = useStyles()
  const navigate = useNavigate()
  const { saveCurrentFormikValues, currentFormikValues } = useCurrentFormikValues()
  const { setCurrentData } = useCurrentData()
  const { setCurrentOtherSectionsData } = useCurrentOtherSectionsData()
  const { setShowChat } = useShowChat()

  const handlerClick = (email: any, password: any) => {
    if (!email || !password) {
      return
    }
    setIsShowLoader(true)
    const params = {
      email,
      password
    }
    userLoginQuery(params)
      .then((response: any) => {
        if (response.data.errorId > 0) {
          setResultText('We cannot find a HomeQual account for this email address or password. Please try again.')
          setIsShowLoader(false)
        } else {
          const memberId = response.data.memberId
          const values = { ...currentFormikValues, memberId }
          saveCurrentFormikValues(values)
          sessionStorage.setItem('authToken', response.data.token)
          sessionStorage.setItem('memberId', memberId)
          axios.defaults.headers.common.authorization = `Bearer ${response.data.token}`
          getLastQuestionCompleted({ memberId })
            .then((response: any) => {
              if (response.data.errorId > 0) {
                setIsShowLoader(false)
              } else {
                const lastQuestionCompleted = response.data.lastQuestionCompleted
                getMemberDetailsValues(memberId)
                  .then((response: any) => {
                    const currentStateWithDetail = response.data
                    if (response.data.moveDate) {
                      const dateAr = response.data.moveDate.split('-')
                      response.data.moveDate = dateAr[1] + '/' + dateAr[2].substring(0, 2) + '/' + dateAr[0]
                    }
                    getMemberCurrentValues(memberId)
                      .then((response: any) => {
                        const currentStateWithMember = { ...currentStateWithDetail, ...response.data }
                        setCurrentData(currentStateWithMember)
                        setCurrentOtherSectionsData(currentStateWithMember)
                        setIsShowLoader(false)
                        setShowChat(false)
                        const identityId = sessionStorage.getItem('identityId')
                        const threadId = sessionStorage.getItem('threadId')
                        const authToken = sessionStorage.getItem('authToken')
                        if (identityId && threadId) {
                          if (authToken) {
                            closeMemberChatQuery(currentStateWithMember?.memberId, identityId, threadId)
                              .then((response: any) => {
                              })
                              .catch((err: any) => {
                                console.error(err)
                              })
                          } else {
                            closeVisitorChatQuery(identityId, threadId)
                              .then((response: any) => {
                              })
                              .catch((err: any) => {
                                console.error(err)
                              })
                          }
                        }
                        if (lastQuestionCompleted === 23) {
                          navigate('/success')
                        } else {
                          navigateToCompletedQuestion(
                            questionsList,
                            currentStateWithMember,
                            lastQuestionCompleted,
                            navigate
                          )
                        }
                      })
                      .catch((err: any) => {
                        console.error(err)
                      })
                  })
                  .catch((err: any) => {
                    console.error(err)
                  })
              }
            })
            .catch((err: any) => {
              console.error(err)
            })
        }
      })
      .catch((err: any) => {
        setIsShowLoader(false)
        setResultText('We cannot find a HomeQual account for this email address or password. Please try again.')
        console.error(err)
      })
  }
  const handlerEmailChange = (e: any) => {
    setEmail(e.target.value)
  }
  const handlerPasswordChange = (e: any) => {
    setPassword(e.target.value)
  }

  return (
    <>
      <Box
        sx={ {
          height: '100%',
          width: '100%',
          top: 0,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: '#fff',
          opacity: '0.9',
          padding: '10px 0'
        } }
        color="primary"
      >
        <CenteredTypography variant="h5">
          <Typography
            marginBottom='25px'
            fontSize='46px'
            fontWeight='bold'
            textAlign="center"
            color="#000"
            sx={ {
              '@media screen and (max-width: 500px)': {
                fontSize: '36px'
              }
            } }
          >
            Sign-In
          </Typography>
        </CenteredTypography>
        <CenteredTypography variant="h5">
          <TextField
            variant='outlined'
            color='primary'
            name='login'
            autoComplete='no'
            onChange={ handlerEmailChange }
            InputProps={ {
              style: { fontSize: '1.4rem', fontWeight: 'bold' },
              classes: {
                notchedOutline: classes.notchedOutline
              }
            } }
            InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
            sx={ {
              minWidth: '300px', maxWidth: '600px', width: '50vw', margin: '10px 0 5px 0'
            } }
            label={
              <Typography variant="h6" color="#ccc" sx={ {
                fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
              } }>
                E-mail
              </Typography>
            }
          />
        </CenteredTypography>
        <CenteredTypography variant="h5">
          <TextField
            variant='outlined'
            color='primary'
            name='password'
            autoComplete='no'
            onChange={ handlerPasswordChange }
            InputProps={ {
              style: { fontSize: '1.4rem', fontWeight: 'bold' },
              classes: {
                notchedOutline: classes.notchedOutline
              }
            } }
            InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
            sx={ {
              minWidth: '300px', maxWidth: '600px', width: '50vw', margin: '10px 0 5px 0'
            } }
            label={
              <Typography variant="h6" color="#ccc" sx={ {
                fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
              } }>
                Password
              </Typography>
            }
          />
        </CenteredTypography>
        <CenteredTypography variant="h5">
          <Button
            onClick={ () => handlerClick(email, password) }
            type="submit"
            disabled={ isShowLoader }
            className={ classes.textOrEmailButton }
            size="large"
          >
            {
              !isShowLoader ? 'Sign-In' : <CircularProgress className={ classes.loader } />
            }
          </Button>
        </CenteredTypography>
        <CenteredTypography onClick={() => {
          navigate('/forgot_your_password')
        }} variant="h5">
          <Typography variant="h6" color="#666" sx={ {
            minWidth: '300px',
            maxWidth: '600px',
            width: '50vw',
            fontSize: '15px !important',
            fontStyle: 'italic',
            textAlign: 'left',
            cursor: 'pointer',
            textDecoration: 'underline',
            fontWeight: 'regular'
          } }>
            Forgot your password?
          </Typography>
        </CenteredTypography>
        <CenteredTypography variant="h6" maxWidth='900px'>
          { resultText }
        </CenteredTypography>
      </Box>
    </>
  )
}
