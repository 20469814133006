import React from 'react'
import { Box, Typography } from '@mui/material'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'

interface ISuccessButton {
  title: string
  expanded?: boolean
  onClick?: any
}
export const SuccessButton: React.FC<ISuccessButton> = ({ title, expanded }) => {
  return (
    <>
      <Box
        sx={ {
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: !expanded ? 'rgb(204, 204, 204)' : 'rgb(237,31,138)',
          padding: '16px 0',
          marginBottom: !expanded ? '10px' : '0',
          width: '100%',
          alignItems: 'center',
          '@media screen and (max-width : 768px)': {
            padding: '19px 0'
          },
          '@media screen and (max-width : 400px)': {
            padding: '20px 0'
          }
        } }
        color="primary"
      >
        <Typography
          sx={ {
            padding: '0 50px',
            fontSize: '24px',
            '@media screen and (max-width : 768px)': {
              fontSize: '18px'
            },
            '@media screen and (max-width : 400px)': {
              fontSize: '16px'
            }
          } }
          textAlign="center"
          color={ !expanded ? 'primary' : '#fff' }
        >
          { title }
        </Typography>
        <ArrowForwardIosOutlinedIcon sx={ {
          color: !expanded ? 'rgb(237,31,138)' : '#fff',
          height: '34px',
          width: '46px',
          position: 'absolute',
          right: '4%',
          transform: !expanded ? '' : 'rotate(90deg)',
          '@media screen and (max-width : 768px)': {
            right: '2%',
            height: '27px'
          },
          '@media screen and (max-width : 400px)': {
            height: '22px'
          }
        } }/>
      </Box>
    </>
  )
}
