import * as yup from 'yup'

export const QuestionsSchema: any = {
  Q1: yup.object({
    firstName: yup.string().required(''),

    lastName: yup.string().required(''),

    cellPhone: yup
      .string()
      .typeError('')
      .matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        'Please enter a valid phone number'
      )
      .required(''),

    emailAddress: yup
      .string()
      .email('Please enter a valid email address')
      .required('')
  }),

  Q2: yup.object({
    communicationPreferenceId: yup
      .string()
      .default('')
      .test('communicationPreferenceId', '', (value) => {
        if (value && value !== '0' && value !== 'false') {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return false
      })
      .required('')
  }),

  Q3: yup.object({
    coBorrowerFlag: yup
      .string()
      .default('')
      .test('coBorrowerFlag', '', (value) => {
        if (value && value !== 'false') {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return false
      })
      .required('')
  }),

  Q4: yup.object({
    maritalStatus: yup
      .string()
      .default('')
      .test('maritalStatus', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required('')
  }),

  Q5: yup.object({
    coFirstName: yup.string().nullable()
      .test('coFirstName', '', (value) => {
        if (value && value !== 'false') {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return false
      })
      .required(''),

    coLastName: yup.string().nullable()
      .default('')
      .test('coLastName', '', (value) => {
        if (value && value !== 'false') {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return false
      })
      .required('')
  }),

  Q6: yup.object({
    coMaritalStatus: yup
      .string()
      .default('')
      .test('coMaritalStatus', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required('')
  }),

  Q7: yup.object({
    moveRangeValid: yup
      .string()
      .required('')
      .nullable()
  }),

  Q8: yup.object({
    monthlyPaymentAmount: yup
      .string()
      .required('')
      .nullable()
  }),

  Q9: yup.object({
    homeSavings: yup
      .string()
      .required('')
      .nullable()
  }),

  Q10: yup.object({
    homeSavingsDetails: yup
      .string()
      .required('')
  }),

  Q12: yup.object({
    monthlyIncome: yup
      .string()
      .required('')
      .nullable()
  }),

  Q13: yup.object({
    autoExpense: yup
      .string()
      .nullable()
      .when(['creditCardExpense', 'studentLoans', 'otherExpenses'], {
        is: (creditCardExpense: any, studentLoans: any, otherExpenses: any) =>
          !creditCardExpense && !studentLoans && !otherExpenses,
        then: yup.string().nullable().required('')
      })
  }),

  Q15: yup.object({
    lenderDetails: yup
      .string()
      .required('')
  }),

  Q16: yup.object({
    creditScoreRange: yup
      .string()
      .default('')
      .test('creditScoreRange', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required('')
  }),

  Q17: yup.object({
    lendersPayDetails: yup
      .string()
      .required('')
  }),

  Q18: yup.object({
    studentLoanDefault: yup
      .string()
      .default('')
      .test('studentLoanDefault', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required('')
  }),

  Q19: yup.object({
    taxDetails: yup
      .string()
      .default('')
      .test('taxDetails', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required('')
  }),

  Q20: yup.object({
    housingPaymentAmount: yup
      .string()
      .nullable()
      .when('livingSituation', {
        is: '1',
        then: yup.string().nullable().required('')
      })
      .when('livingSituation', {
        is: '3',
        then: yup.string().nullable().required('')
      }),

    livingSituation: yup
      .string()
      .nullable()
      .default('')
      .test('livingSituation', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required('')
  }),

  Q21: yup.object({
    veteranFlag: yup
      .string()
      .default('')
      .test('veteranFlag', '', (value) => {
        if (value && value !== 'false') {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required('')
  }),

  Q22: yup.object({
    liveCity1: yup.string().required('').nullable(),
    liveState1: yup.string().required('').nullable(),
    liveState2: yup
      .string()
      .nullable()
      .when(['liveCity2'], {
        is: (liveCity2: any) => liveCity2,
        then: yup.string().nullable().required('')
      }),
    liveState3: yup
      .string()
      .nullable()
      .when(['liveCity3'], {
        is: (liveCity3: any) => liveCity3,
        then: yup.string().nullable().required('')
      })
  }),

  Q12C: yup.object({
    monthlyIncome: yup
      .string()
      .nullable()
      .required(''),

    coMonthlyIncome: yup
      .string()
      .nullable()
      .required('')
  }),

  Q13C: yup.object({
    autoExpense: yup
      .string()
      .nullable()
      .when(['creditCardExpense', 'studentLoans', 'otherExpenses'], {
        is: (creditCardExpense: any, studentLoans: any, otherExpenses: any) =>
          !creditCardExpense && !studentLoans && !otherExpenses,
        then: yup.string().nullable().required('')
      })
  }),

  Q15C: yup.object({
    lenderDetails: yup
      .string()
      .required(''),
    coLenderDetails: yup
      .string()
      .required('')
  }),

  Q16C: yup.object({
    creditScoreRange: yup
      .string()
      .nullable()
      .default('')
      .test('creditScoreRange', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required(''),
    coCreditScoreRange: yup
      .string()
      .nullable()
      .default('')
      .test('coCreditScoreRange', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required('')
  }),

  Q17C: yup.object({
    lendersPayDetails: yup
      .string()
      .required(''),
    coLendersPayDetails: yup
      .string()
      .required('')
  }),

  Q18C: yup.object({
    studentLoanDefault: yup
      .string()
      .default('')
      .test('studentLoanDefault', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required(''),
    coStudentLoanDefault: yup
      .string()
      .default('')
      .test('coStudentLoanDefault', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required('')
  }),

  Q19C: yup.object({
    taxDetails: yup
      .string()
      .default('')
      .test('taxDetails', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required(''),
    coTaxDetails: yup
      .string()
      .default('')
      .test('coTaxDetails', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required('')
  }),

  Q20C: yup.object({
    housingPaymentAmount: yup
      .string()
      .nullable()
      .when('livingSituation', {
        is: '1',
        then: yup.string().nullable().required('')
      })
      .when('livingSituation', {
        is: '3',
        then: yup.string().nullable().required('')
      }),

    coHousingPaymentAmount: yup
      .string()
      .nullable()
      .when('coLivingSituation', {
        is: '3',
        then: yup.string().nullable().required('')
      })
      .when('coLivingSituation', {
        is: '1',
        then: yup.string().nullable().required('')
      }),
    livingSituation: yup
      .string()
      .nullable()
      .default('')
      .test('livingSituation', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required(''),

    coLivingSituation: yup
      .string()
      .nullable()
      .default('')
      .test('coLivingSituation', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required('')
  }),
  Q21C: yup.object({
    veteranFlag: yup
      .string()
      .default('')
      .test('veteranFlag', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required(''),
    coVeteranFlag: yup
      .string()
      .default('')
      .test('coVeteranFlag', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required('')
  })
}

export const refinanceQuestionsSchema: any = {
  Q1: yup.object({
    firstName: yup.string().required(''),

    lastName: yup.string().required(''),

    cellPhone: yup
      .string()
      .typeError('')
      .matches(
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
        'Please enter a valid phone number'
      )
      .required(''),

    emailAddress: yup
      .string()
      .email('Please enter a valid email address')
      .required('')
  }),
  Q2: yup.object({
    coBorrowerFlag: yup
      .string()
      .default('')
      .test('coBorrowerFlag', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .required('')
  }),
  Q3: yup.object({
    refiPropertyValue: yup
      .string()
      .nullable()
      .required(''),

    refiPropertyOwe: yup
      .string()
      .nullable()
      .required('')
  }),
  Q4: yup.object({
    refiInterestRate: yup
      .string()
      .nullable()
      .required(''),
    refiLoanTypeValid: yup
      .string()
      .required('')
      .nullable()
  }),
  Q5: yup.object({
    refiCreditScoreRange: yup
      .string()
      .required('')
      .nullable()
  }),
  Q6: yup.object({
    refiAddress: yup.string()
      .default('')
      .test('refiAddress', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .nullable()
      .required(''),
    refiCity: yup.string()
      .default('')
      .test('refiCity', '', (value) => {
        if (value) {
          const schema = yup.string().min(1)
          return schema.isValidSync(value)
        }
        return true
      })
      .nullable()
      .required(''),
    refiStateId: yup.string().required('').nullable(),
    refiZipCode: yup.string().required('').nullable()
  })
}
