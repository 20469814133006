import React, { FC, memo } from 'react'
import { Wrapper } from '../../Wrapper'
import { Box, Grid, Typography } from '@mui/material'
import { basicData, InputNumberField } from '../shared'
import { ICreateDoubledTextFieldQuestion } from '../../../questions'
import makeStyles from '@material-ui/core/styles/makeStyles'

interface IProps extends ICreateDoubledTextFieldQuestion, basicData {
}

export const CreateDoubledTextFieldQuestion: FC<IProps> = memo(({
  inputs,
  formik,
  coInputs,
  wrapperProps
}) => {
  const styles = makeStyles(theme => ({
    box: {
      '@media(max-width: 500px)': {
        maxWidth: '100px !important'
      }
    }
  }))
  const classes = styles()
  return (
    <Wrapper { ...wrapperProps }>
      <Grid container direction="column" wrap="nowrap" sx={ {
        paddingLeft: '100px',
        '@media(max-width: 768px)': {
          paddingLeft: '10vw'
        }
      } }>
        <Grid container>
          <Typography variant="h6" sx={ {
            width: '200px',
            margin: '2vh 1vh 2vh 0',
            textAlign: 'left',
            fontWeight: 'bold'
          } }>
            You
          </Typography>
          <Typography variant="h6" sx={ {
            width: '200px',
            margin: '2vh 0 2vh 1vw',
            textAlign: 'left',
            fontWeight: 'bold'
          } }>
            Co-Borrower
          </Typography>
        </Grid>
        { inputs.map((item, index: number) => (
          <Grid key={ index } container justifyContent="flex-start" wrap="nowrap">

            <Box
              className={classes.box}
              sx={ { width: 'auto', maxWidth: '180px', marginRight: '10px', boxSizing: 'border-box' } }
            >
              <InputNumberField
                key={ index }
                noPadding={ true }
                item={ item }
                formik={ formik }
                labelText={ 'Payments' }
                mainstyles={ { paddingRight: '0' } }/>
            </Box>
            <Box
              sx={ { width: 'auto', maxWidth: '180px', boxSizing: 'border-box' } }
              className={classes.box}
            >
              <InputNumberField key={ index + 5 }
                          noPadding={ true }
                          item={ coInputs[index] }
                          formik={ formik }
                          labelText={ 'Payments' }
                          mainstyles={ { paddingRight: '0' } }/>
            </Box>

            <Box alignSelf="baseline" ml={ 2 } pt={ '1rem' }>
              <Typography variant="h6" sx={ { fontSize: '1.3rem' } }>{ item.text }</Typography>
            </Box>
          </Grid>
        )) }
      </Grid>
    </Wrapper>
  )
})
