import { useEffect, useState } from 'react'
import { Typography, TextField, Grid } from '@mui/material'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useCurrentFormikValues from '../../../../../../shared/hooks/useCurrentFormikValues'
import { Wrapper } from '../../Wrapper'
import { basicData } from '../shared'
import { ICreateTextFieldQuestion } from '../../../questions'

interface IUserCreatePopUp extends ICreateTextFieldQuestion, basicData {
  handleClick: any
}

const useStyles = makeStyles({
  notchedOutline: {
    borderColor: '#ccc !important'
  },
  text: {
    marginBottom: '25px',
    fontSize: '20px',
    maxWidth: '600px',
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#000',
    '@media screen and (max-width: 500px)': {
      fontSize: '20px'
    }
  }
})

export const CreateUserPasswordChange = (props: IUserCreatePopUp) => {
  const classes = useStyles()
  const {
    wrapperProps,
    formik
  } = props
  const { currentFormikValues } = useCurrentFormikValues()
  const [memberId, setMemberId] = useState('')

  useEffect(() => {
    if (currentFormikValues.memberId) {
      setMemberId(currentFormikValues.memberId)
      formik.setFieldValue('password', currentFormikValues.memberId.toString())
    }
  }, [currentFormikValues])

  return (
    <>
      <Wrapper { ...wrapperProps }>
        <Grid container direction="column" sx={ {
          padding: '0 100px',
          '@media(max-width: 768px)': {
            padding: '0 10vw'
          }
        } }>
          <Typography variant="h5" mb={ 2 } sx={ {
            fontWeight: 'bold',
            marginTop: '25px',
            marginBottom: '35px'
          } }>
            Hi { currentFormikValues.firstName }. We have setup a HomeQual
            account for you. Your member id is { memberId }.
          </Typography>
          <Typography variant="h6">
            You can review your homeowner Success Plan, claim any rebates,
            or take advantage of the many HomeQual benefits simply by logging in at any time.
          </Typography>
          <Typography variant="h6" marginTop="20px">
            Your password is your member id. You can change it now if you’d like.
          </Typography>
          <TextField
            variant="outlined"
            color="primary"
            value={ formik.values.password }
            name='password'
            onChange={ formik.handleChange }
            autoComplete='no'
            InputProps={ {
              style: { fontSize: '1.4rem', marginTop: '35px', fontWeight: 'bold', width: '100%' },
              classes: {
                notchedOutline: classes.notchedOutline
              }
            } }
            InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
            sx={ {
              minWidth: '600px',
              maxWidth: '660px',
              margin: '35px auto 5px auto',
              padding: '0 50px',
              '@media(max-width: 768px)': {
                padding: '0',
                minWidth: '100%'
              }
            } }
          />
        </Grid>
      </Wrapper>
    </>
  )
}
