import React, { FC, memo } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { TextField, Typography } from '@mui/material'
import InputMask from 'react-input-mask'

interface IInputField {
  item: any
  index: number
  handleChange: any
  allValues: any
}

export const InputField: FC<IInputField> = memo(({ item, index, handleChange, allValues }) => {
  const styles = makeStyles(theme => ({
    notchedOutline: {
      borderColor: '#ccc !important'
    }
  }))
  const formatChars = {
    f: '[1-9]',
    o: '[0-9]'
  }

  const classes = styles()
  return (
    <>
      {
  !item.mask
    ? <TextField autoFocus={index === 0}
  variant="outlined"
  onChange={ (event) => {
    handleChange(event)
  } }
  color="primary"
  value={ allValues[item.value] === null ? '' : allValues[item.value] }
  name={item.value}
  autoComplete='no'
  InputProps={{
    style: { fontSize: '1.4rem', fontWeight: 'bold' },
    classes: {
      notchedOutline: classes.notchedOutline
    }
  }}
  InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
  sx={{
    minWidth: '100px',
    maxWidth: '460px',
    margin: '15px 0 5px 0',
    paddingRight: '100px',
    '@media screen and (max-width : 768px)': {
      paddingRight: '10vw'
    }
  }}
  label={
    <Typography variant="h6" color="#999" sx={{
      fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
    }}>
    { item.text }
  </Typography>}
  />
    : <InputMask
    autoFocus={index === 0}
    autoComplete='no'
    onChange={ (event) => {
      handleChange(event)
    } }
    mask="(foo) ooo-oooo"
    formatChars={ formatChars }
    value={ allValues[item.value] === null ? '' : allValues[item.value] }
    disabled={false}
    >
    {() =>
      <TextField
        variant="outlined"
      name={ item.value }
      InputProps={{
        style: { fontSize: '1.4rem', fontWeight: 'bold' },
        classes: {
          notchedOutline: classes.notchedOutline
        }
      }}
      InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
        sx={{
          minWidth: '100px',
          maxWidth: '460px',
          margin: '15px 0 5px 0',
          paddingRight: '100px',
          '@media screen and (max-width : 768px)': {
            paddingRight: '10vw'
          }
        }}
      label={
        <Typography variant="h6" color="#999" sx={{
          fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
        }}>
      { item.text }
      </Typography>}
      />
    }
  </InputMask>
}
  </>
  )
})
