import { basicData, RadioField } from '../shared'
import React, { FC, memo, useEffect } from 'react'
import { Wrapper } from '../../Wrapper'
import { Grid, TextField, Typography } from '@mui/material'
import { ICreateRadioTextQuestion } from '../../../questions'
import makeStyles from '@material-ui/core/styles/makeStyles'
import InputMask from 'react-input-mask'

interface IProps extends ICreateRadioTextQuestion, basicData {
}

const useStyles = makeStyles({
  input: {
    minWidth: '100px',
    maxWidth: '600px',
    paddingRight: '10vw',
    margin: '10px 0 5px 0'
  },
  notchedOutline: {
    borderColor: '#ccc !important'
  }
})

export const CreateTextRadioQuestion: FC<IProps> = memo(({
  inputs,
  formik,
  answers,
  question1,
  formName,
  wrapperProps
}) => {
  const classes = useStyles()
  useEffect(() => {
    hiddenHandler()
  }, [formik.values])
  const hiddenHandler = () => {
    if ((formik.values.moveDate || formik.values.moveRange) && formik.values.moveDate !== '__/__/____') {
      formik.setFieldValue('moveRangeValid', '1')
    } else {
      formik.setFieldValue('moveRangeValid', null)
    }
  }
  return (
    <Wrapper { ...wrapperProps }>
      <Grid container direction="column" sx={ {
        paddingLeft: '100px',
        '@media(max-width: 768px)': {
          paddingLeft: '10vw'
        }
      } }>
        { inputs.map((item, index: number) => (
          <InputMask
              autoFocus={index === 0}
              onChange={(event) => {
                formik.setFieldValue('moveRange', null)
                formik.setFieldValue('moveDate', event.target.value)
                hiddenHandler()
              }}
              onBlur={formik.handleBlur}
              autoComplete='no'
              mask="99/99/9999"
              value={ formik.values[item.value] === null ? '' : formik.values[item.value] }
              disabled={false}
          >
            {() =>
              <TextField
                variant="outlined"
                name={item.value}
                InputProps={{
                  style: { fontSize: '1.4rem', fontWeight: 'bold' },
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
                sx={{
                  minWidth: '100px',
                  maxWidth: '600px',
                  paddingRight: '100px',
                  margin: '10px 0 5px 0',
                  '@media(max-width: 768px)': {
                    paddingRight: '10vw'
                  }
                }}
                error={formik.touched[item.value] && Boolean(formik.errors[item.value])}
                helperText={formik.touched[item.value] &&
                <Typography component="span"
                            color="primary"
                            sx={{ fontSize: '1rem' }}>{ formik.errors[item.value] }
                </Typography>}
                label={
                  <Typography variant="h6" color="#999" sx={{
                    fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
                  }}>
                    {item.text}
                  </Typography>}
              />
            }
          </InputMask>
        )) }

        <Typography variant="h5" mb={ 2 } sx={ {
          fontWeight: 'bold',
          marginTop: '25px',
          marginBottom: '35px'
        } }>
          { question1(formik.values) }
        </Typography>

        <Grid container direction="column" wrap="nowrap" sx={ { width: 'auto', alignItems: 'center' } }>
          <RadioField
            onClick={ () => {
              hiddenHandler()
              formik.setFieldValue('moveDate', null)
            }}
            formik={ formik }
            formName={ formName }
            answers={ answers }
          />
        </Grid>
      </Grid>
    </Wrapper>
  )
})
