import React from 'react'
import styled from 'styled-components'
import titleImage from '../../../../assets/images/SuccessPlan/successTitle.jpg'
import { Box, Grid, Typography } from '@mui/material'
import { HomeQualTextTemplate } from '../../../../shared/strings/strings'
import useCurrentData from '../../../../shared/hooks/useCurrentData'
import makeStyles from '@material-ui/core/styles/makeStyles'

export const Content = styled.div`
  width           : 100%;
  position        : relative;
  display         : flex;
  align-items     : center;
  height          : auto;
  min-height: 22vw;
  padding         : 4vh 0 4vh 4vw;
  background      : url(${titleImage}) left center no-repeat border-box;
  background-size : cover;
  color           : black;
  @media screen and (min-width : 1024px) {
    object-fit: cover;
    max-height: 250px;
  }
  @media screen and (max-width : 1024px) {
   
  }
  @media screen and (max-width : 700px) {
    background-position: 100% 0;
  }
`

const useStyles = makeStyles({
  name: {
    fontSize: '2.3rem',
    '@media screen and (max-width: 1024px)': {
      fontSize: '1.9rem'
    },
    '@media screen and (max-width: 600px)': {
      fontSize: '1.3rem'
    }
  },
  id: {
    fontSize: '1.6rem',
    '@media screen and (max-width: 1024px)': {
      fontSize: '1.4rem'
    },
    '@media screen and (max-width: 600px)': {
      fontSize: '1.2rem'
    }
  }
})

export const Title = () => {
  const { currentData } = useCurrentData()
  const classes = useStyles()

  return (
    <>
      <Content>
        <Grid
          width="auto"
          container
          rowGap={ 4 }
          direction="column"
          sx={ {
            rowGap: '32px',
            '@media screen and (max-width: 600px)': {
              rowGap: '22px'
            },
            '@media screen and (max-width: 500px)': {
              rowGap: '18px'
            }
          } }
        >
          <Typography variant="h6">
            <HomeQualTextTemplate variant="h5"/> Success Plan For
          </Typography>
          <Typography className={ classes.name }>
            {currentData.firstName} {currentData.lastName}
          </Typography>

          <Box sx={ { display: 'flex' } }>
            <Typography className={ classes.id } fontWeight="bolder">Member ID:</Typography>
            <Typography className={ classes.id } width="auto" marginLeft='10px'>
               {currentData.memberId}
            </Typography>
          </Box>
        </Grid>
      </Content>

      {/* <Box sx={ { padding: '20px', textAlign: 'center', bgcolor: 'primary.main', color: 'white' } }> */ }
      {/*  <Typography variant="h5">YOUR HOME AFFORDABILITY</Typography> */ }
      {/* </Box> */ }

    </>
  )
}
