import { QuestionKeyType } from './questions'
import { NavigateFunction } from 'react-router-dom'
import { FormikProps } from 'formik'
import 'react-toastify/dist/ReactToastify.css'
import { isQaSection } from '../../../shared/functions'

export const SetCurrentQuestionHandler = (
  next: QuestionKeyType,
  current: QuestionKeyType,
  formik: FormikProps<any>,
  navigate: NavigateFunction,
  additionalHandler?: any
) => {
  const isAlone = formik.values.coBorrowerFlag === '0' || formik.values.coBorrowerFlag === false
  const isHaveStudentLoan = formik.values.lateStudentLoanFlag
  const isHaveTax = formik.values.taxLienJudgementForeclosureFlag
  const isCoHaveStudentLoan = formik.values.coLateStudentLoanFlag
  const isCoHaveTax = formik.values.taxLienJudgementForeclosureFlag
  const isSpouce = (formik.values.coBorrowerFlag === '1' || formik.values.coBorrowerFlag) &&
    formik.values.coBorrowerFlag !== '0'

  if (!isQaSection()) {
    return true
  }

  if (current === 'Q1' || current === 'QP') {
    return true
  } else if (current === 'Q3' && isSpouce) {
    formik.setFieldValue('maritalStatus', 1)
    navigate('Q5')
  } else if (current === 'Q4' && isAlone) {
    navigate('Q7')
  } else if (current === 'Q5' && isSpouce) {
    navigate('Q7')
  } else if (current === 'Q17' && !isHaveStudentLoan && !isHaveTax) {
    navigate('Q20')
  } else if (current === 'Q17' && isHaveTax && !isHaveStudentLoan) {
    navigate('Q19')
  } else if (current === 'Q18' && !isHaveTax) {
    navigate('Q20')
  } else if (current === 'Q17C' && !isHaveStudentLoan && !isHaveTax && !isCoHaveStudentLoan && !isCoHaveTax) {
    navigate('Q20C')
  } else if (current === 'Q17C' && !isHaveStudentLoan && !isCoHaveStudentLoan && (isHaveTax || isCoHaveTax)) {
    navigate('Q19C')
  } else if (current === 'Q18C' && !isHaveTax && !isCoHaveTax) {
    navigate('Q20C')
  } else if (current === 'Q11' && !isAlone) {
    navigate('Q12C')
  } else {
    navigate(`${next}`)
  }
}
