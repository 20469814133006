import { FC, memo, useState, useRef } from 'react'
import { Box, TextField, Typography } from '@mui/material'
import makeStyles from '@material-ui/core/styles/makeStyles'
import InputMask from 'react-input-mask'
import NumberFormat from 'react-number-format'

interface ITextFieldWithLink {
  label: string;
  name: string;
  validationError: string;
  value: string | number;
  readOnly?: boolean;
  mask?: boolean;
  handleChange: any;
  helper?: string;
  number?: boolean;
  isValid?: boolean;
}

export const TextFieldWithLink: FC<ITextFieldWithLink> = memo(({
  label,
  name,
  validationError,
  value,
  readOnly,
  mask,
  handleChange,
  helper,
  isValid,
  number
}) => {
  const styles = makeStyles(theme => ({
    notchedOutline: {
      borderColor: '#ccc !important'
    }
  }))
  const [readOnlyState, setReadOnlyState] = useState(readOnly)
  const classes = styles()
  const inputRef = useRef<HTMLInputElement>()
  const editHandler = () => {
    setReadOnlyState(!readOnlyState)
  }

  return (
    <Box sx={ {
      position: 'relative',
      marginBottom: '30px',
      display: 'flex'
    } }>
      {
        mask
          ? <InputMask
            onChange={ (event) => {
              handleChange(event)
            } }
            autoComplete='no'
            mask="99/99/9999"
            value={ value }
            disabled={false}
          >
            {() =>
              <TextField
                variant="outlined"
                name={ name }
                disabled={ readOnly }
                InputProps={{
                  style: { fontSize: '1.4rem', fontWeight: 'bold', width: '90%' },
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                helperText={
                  !isValid && <Typography
                    component="span"
                    color="primary"
                    sx={{ fontSize: '1rem' }}
                  >
                    { validationError }
                  </Typography>
                }
                InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
                sx={ {
                  minWidth: '100px', width: '90%', maxWidth: '500px', margin: '10px auto 5px'
                } }
                label={
                  <Typography variant="h6" color="#999" sx={{
                    fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
                  }}>
                    { label }
                  </Typography>}
              />
            }
          </InputMask>
          : !number
              ? <TextField
            onChange={ (event) => {
              handleChange(event)
            } }
            inputRef={ inputRef }
            variant="outlined"
            color="primary"
            value={ value }
            name={ name }
            disabled={ readOnlyState }
            autoComplete='no'
            helperText={
              !isValid
                ? <Typography
                  component="span"
                  color="primary"
                  sx={{ fontSize: '1rem' }}
                >
                  { validationError }
                </Typography>
                : readOnly && <Typography
                sx={ {
                  position: 'absolute',
                  fontStyle: 'italic',
                  right: '55px',
                  top: '-25px',
                  color: 'rgb(237, 31, 138)',
                  cursor: 'pointer',
                  '@media(max-width: 500px)': {
                    right: '10vw'
                  }
                } }
                onClick={ () => {
                  editHandler()
                  setTimeout(() => {
                    if (readOnlyState) {
                      inputRef.current?.focus()
                      if (inputRef.current) {
                        inputRef.current!.value = ''
                      }
                    }
                  }, 100)
                } }
              >
                { helper }
              </Typography>
            }
            InputProps={ {
              style: { fontSize: '1.4rem', fontWeight: 'bold', width: '90%' },
              classes: {
                notchedOutline: classes.notchedOutline
              }
            } }
            InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
            sx={ {
              minWidth: '100px', width: '90%', maxWidth: '500px', margin: '10px auto 5px'
            } }
            label={
              <Typography variant="h6" color="#ccc" sx={ {
                fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
              } }>
                { label }
              </Typography>
            }
          />
              : <NumberFormat
            thousandSeparator={true}
            allowNegative={false}
            customInput={TextField}
            sx={ {
              minWidth: '100px', width: '90%', maxWidth: '500px', margin: '10px auto 5px'
            } }
            variant="outlined"
            onChange={ (event: any) => {
              handleChange(event)
            } }
            color="primary"
            allowLeadingZeros={ false }
            value={ value }
            name={ name }
            autoComplete='no'
            InputProps={ {
              style: { fontSize: '1.4rem', fontWeight: 'bold', width: '90%' },
              classes: {
                notchedOutline: classes.notchedOutline
              }
            } }
            InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
            helperText={
              !isValid && <Typography
                  component="span"
                  color="primary"
                  sx={{ fontSize: '1rem' }}
              >
                { validationError }
              </Typography>
            }
            label={
              <Typography variant="h6" color="#ccc" sx={ {
                fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
              } }>
                { label }
              </Typography>
            }
          />
      }
    </Box>
  )
})
