import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Button, Grid, SvgIcon, TextField, Typography } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import makeStyles from '@material-ui/core/styles/makeStyles'
import NumberFormat from 'react-number-format'
import ReactSpeedometer, { CustomSegmentLabelPosition, Transition } from 'react-d3-speedometer'
import { numberWithCommas } from '../../../../../shared/functions'
import { updateMonthlyPayment } from '../../../../../shared/user/userQueries'
import useCurrentData from '../../../../../shared/hooks/useCurrentData'
import { useWindowSize } from '../../../../../shared/hooks/useWindowSize'
import { LoanButtons } from './LoanButtons'
import useCurrentOtherSectionsData from '../../../../../shared/hooks/useCurrentOtherSectionsData'

const PaymentButtons = styled(({ ...props }) => <SvgIcon { ...props }/>)`
  width            : 2vw;
  height           : 2vw;
  min-height       : 25px;
  min-width        : 25px;
  max-height       : 50px;
  max-width        : 50px;
  fill             : dodgerblue;
  background-color : #febb6a;
  background-image : url(${(props: any) => props.hello});
  border-radius    : 50%;
  font-size        : 50px;
  cursor        : pointer;
`

const TableItem = styled(Box)`
  position        : relative;
  border          : ${props => `1px solid ${props.theme.colors.primary}`};
  border-top      : 0;
  padding         : 5px;
  border-collapse : collapse;
  &:first-child {
    border-top : ${props => `1px solid ${props.theme.colors.primary}`};
  }
`
interface IDiagrams {
  calculationValues: any;
  monthlyPayment: any;
  setMonthlyPayment: any;
  handleDiagramButtonClick: any;
  chosenDiagramButton: any;
}

export const Diagrams: FC<IDiagrams> = ({
  calculationValues,
  monthlyPayment,
  setMonthlyPayment,
  handleDiagramButtonClick,
  chosenDiagramButton
}) => {
  const initialValues:any = calculationValues
  const { currentData } = useCurrentData()
  const { currentOtherSectionsData, setCurrentOtherSectionsData } = useCurrentOtherSectionsData()
  const handleMonthlyPaymentChange = (e: any) => {
    const value = e.target.value
    const monthlyPaymentClear = typeof value === 'string'
      ? value.replace(',', '')
      : value
    setMonthlyPayment(monthlyPaymentClear)
  }
  const paymentsIncrease = () => {
    const monthlyPaymentClear = typeof monthlyPayment === 'string'
      ? monthlyPayment.replace(',', '')
      : monthlyPayment
    setMonthlyPayment(+monthlyPaymentClear + 50)
  }
  const paymentsDecrease = () => {
    const monthlyPaymentClear = typeof monthlyPayment === 'string'
      ? monthlyPayment.replace(',', '')
      : monthlyPayment
    setMonthlyPayment(+monthlyPaymentClear > 0 ? +monthlyPaymentClear - 50 : 0)
  }
  const handleSave = () => {
    const params = {
      memberId: currentData.memberId,
      monthlyPaymentAmount: monthlyPayment
    }
    updateMonthlyPayment(params)
      .then((response: any) => {
        const dataWithNewMonthlyPayment = { ...currentOtherSectionsData, monthlyPaymentAmount: monthlyPayment }
        setCurrentOtherSectionsData(dataWithNewMonthlyPayment)
        console.error(response)
      })
      .catch((err: any) => {
        console.error(err)
      })
  }

  const size = useWindowSize()
  const [innerWidth, setInnerWidth] = useState(size.width)
  useEffect(() => {
    setInnerWidth(size.width)
  }, [size])
  const mainChartsWidth = innerWidth > 600 ? 180 : 160
  const mainChartsHeight = innerWidth > 600 ? 95 : 85
  const modif = innerWidth > 600 ? 1.1 : 1
  const styles = makeStyles(theme => ({
    textFieldStyle: {
      fontSize: '20px',
      fontWeight: 'bold',
      '@media(max-width: 768px)': {
        width: '65px'
      }
    },
    textFieldInputStyle: {
      textAlign: 'center',
      height: '25px',
      '@media(max-width: 768px)': {
        fontSize: '20px'
      }
    },
    mpStyle: {
      '@media(max-width: 768px)': {
        fontSize: '12px'
      }
    },
    columnGrid: {
      '@media(max-width: 500px)': {
        margin: '0 auto'
      }
    },
    imgStyle: {
      marginBottom: '0',
      width: '66%',
      '@media(max-width: 768px)': {
        width: '100%'
      }
    },
    tableItem: {
      paddingTop: '0px',
      border: 'none !important',
      height: mainChartsHeight * 1.3,
      '@media(max-width: 600px)': {
        height: mainChartsHeight * 1.5
      }
    },
    tableItemV: {
      height: mainChartsHeight * 1.3,
      '@media(max-width: 600px)': {
        height: mainChartsHeight * 1.5
      }
    },
    tableItemText: {
      '@media(max-width: 768px)': {
        fontSize: '14px'
      }
    },
    tableItemDiagramText: {
      fontSize: '11px',
      textAlign: 'center',
      '@media(max-width: 768px)': {
        paddingTop: '0'
      },
      '@media(max-width: 500px)': {
        fontSize: '11px'
      }
    },
    tableMargin: {
      marginTop: '0'
    },
    chartMargin: {
      marginBottom: '30px'
    },
    saveButton: {
      border: '1px solid rgb(237, 31, 138)',
      borderRadius: '5px',
      fontSize: '13px',
      boxShadow: '10px 5px 5px #ccc'
    },
    mainColor: {
      color: 'rgb(237,31,138)'
    }
  }))

  const classes = styles()
  const customSegmentLabelsValues = [
    {
      text: 'FAIR',
      fontSize: '9px',
      position: 'OUTSIDE' as CustomSegmentLabelPosition,
      color: '#000'
    },
    {
      text: '',
      fontSize: '9px',
      position: 'OUTSIDE' as CustomSegmentLabelPosition,
      color: '#000'
    },
    {
      text: 'MEDIUM',
      fontSize: '9px',
      position: 'OUTSIDE' as CustomSegmentLabelPosition,
      color: '#000'
    },
    {
      text: '',
      fontSize: '9px',
      position: 'OUTSIDE' as CustomSegmentLabelPosition,
      color: '#000'
    },
    {
      text: 'HIGH',
      fontSize: '9px',
      position: 'OUTSIDE' as CustomSegmentLabelPosition,
      color: '#000'
    }
  ]

  const customSegmentLabelsValuesEmpty = [
    {
      text: '',
      position: 'OUTSIDE' as CustomSegmentLabelPosition,
      color: '#000'
    },
    {
      text: '',
      position: 'OUTSIDE' as CustomSegmentLabelPosition,
      color: '#000'
    },
    {
      text: '',
      position: 'OUTSIDE' as CustomSegmentLabelPosition,
      color: '#000'
    }
  ]
  return (
    <>
      <Grid sx={ {
        display: 'flex',
        margin: '40px auto 0',
        maxWidth: '800px',
        justifyContent: 'center',
        '@media(min-width: 1024px)': {
          margin: '40px auto 0',
          width: '75%'
        }
      } } container overflow={ 'hidden' } px={ '2vw' }>
        <Grid
          className={classes.columnGrid}
          marginRight='40px'
          container
          direction="column"
          width={ mainChartsWidth * modif }
        >
          <Grid container className={ classes.tableMargin } direction="column">
            <TableItem textAlign="center" className={classes.tableItemV}>
              <LoanButtons
                handleClick={ handleDiagramButtonClick }
                chosenDiagramButton={ chosenDiagramButton }
                calculationValues={ calculationValues }
              />
            </TableItem>
            <TableItem paddingTop='12% !important' textAlign="center" className={classes.tableItemV}>
              <Typography className={classes.tableItemText}> MONTHLY PAYMENT </Typography>
              <Box sx={ { display: 'flex', justifyContent: 'space-around' } }>
                <PaymentButtons onClick={ paymentsDecrease } component={ RemoveIcon }/>
                <NumberFormat
                  allowNegative={false}
                  thousandSeparator={true}
                  customInput={TextField}
                  variant="standard"
                  disabled={ true }
                  onChange={ handleMonthlyPaymentChange }
                  value={ monthlyPayment }
                  prefix='$'
                  InputProps={ {
                    className: classes.textFieldStyle,
                    inputProps: {
                      className: classes.textFieldInputStyle
                    }
                  } }
                />
                <PaymentButtons onClick={ paymentsIncrease } component={ AddIcon }/>
              </Box>
              <Button
                onClick={ handleSave }
                type="submit"
                className={ classes.saveButton }
                size="small"
              >
                Save
              </Button>
            </TableItem>
            <TableItem paddingTop='17% !important' className={classes.tableItemV} textAlign="center">
              <Typography className={classes.tableItemText}> HOME ESTIMATE </Typography>
              <Typography variant="h5" fontWeight="bold">
                ${ numberWithCommas(Math.floor(initialValues.homeEstimate)) }
              </Typography>
            </TableItem>
            <TableItem paddingTop='17% !important' className={classes.tableItemV} textAlign="center">
              <Typography className={classes.tableItemText}> MONEY NEEDED </Typography>
              <Typography variant="h5" fontWeight="bold">
                ${ numberWithCommas(Math.floor(initialValues.moneyNeeded)) }
              </Typography>
            </TableItem>
            <TableItem paddingTop='17% !important' className={classes.tableItemV} textAlign="center">
              <Typography className={classes.tableItemText}> CASH REBATE ESTIMATE </Typography>
              <Typography variant="h5" fontWeight="bold">
                ${ numberWithCommas(Math.floor(initialValues.cashRebateAvg)) }
              </Typography>
            </TableItem>
          </Grid>
        </Grid>
        <Grid className={classes.columnGrid} container direction="column" width={ mainChartsWidth }>
          <Grid className={ classes.tableMargin } container direction="column">
            <TableItem className={ classes.tableItem }>
              <Typography className={classes.tableItemDiagramText}>
                Your Standard Qualification Odds
              </Typography>
              <ReactSpeedometer
                maxValue={100}
                value={ initialValues.qualificationChart > 0 ? initialValues.qualificationChart : 0 }
                needleColor="#000"
                segments={5}
                valueTextFontSize='0'
                needleTransition={ 'easePolyIn' as Transition }
                width={ mainChartsWidth }
                height={ mainChartsHeight }
                segmentColors={ ['#e21b18', '#f87100', '#fbbb00', '#c4d600', '#78bd1f'] }
                customSegmentLabels={ customSegmentLabelsValues }
              />
            </TableItem>
            <TableItem className={ classes.tableItem }>
              <Typography className={classes.tableItemDiagramText}>
                Your Home <span className={ classes.mainColor }>Qual</span>ification Odds
              </Typography>
              <ReactSpeedometer
                maxValue={100}
                value={ initialValues.hqQualificationChart > 0 ? initialValues.hqQualificationChart : 0 }
                needleColor="#000"
                segments={5}
                valueTextFontSize='0'
                needleTransition={ 'easePolyIn' as Transition }
                width={ mainChartsWidth }
                height={ mainChartsHeight }
                segmentColors={ ['#e21b18', '#f87100', '#fbbb00', '#c4d600', '#78bd1f'] }
                customSegmentLabels={ customSegmentLabelsValues }
              />
            </TableItem>
            <TableItem className={ classes.tableItem }>
              <Typography
                className={classes.tableItemDiagramText}
                position='relative'
                top='12px'
              > Debt-to-Income Ratio </Typography>
              <ReactSpeedometer
                maxValue={100}
                value={ initialValues.dtiGraph > 0 ? initialValues.dtiGraph : 0}
                needleColor="#000"
                segments={3}
                valueTextFontSize='0'
                needleTransition={ 'easePolyIn' as Transition }
                width={ mainChartsWidth }
                height={ mainChartsHeight }
                segmentColors={ ['#e21b18', '#fad81b', '#78bd1f'] }
                customSegmentStops={ [0, 25, 50, 100] }
                customSegmentLabels={ customSegmentLabelsValuesEmpty }
              />
            </TableItem>
            <TableItem className={ classes.tableItem }>
              <Typography
                className={classes.tableItemDiagramText}
                position='relative'
                top='12px'
              > Available Funds </Typography>
              <ReactSpeedometer
                maxValue={100}
                value={ initialValues.availableFunds > 0 ? initialValues.availableFunds : 0 }
                needleColor="#000"
                segments={3}
                valueTextFontSize='0'
                needleTransition={ 'easePolyIn' as Transition }
                width={ mainChartsWidth }
                height={ mainChartsHeight }
                segmentColors={ ['#e21b18', '#fad81b', '#78bd1f'] }
                customSegmentStops={ [0, 25, 50, 100] }
                customSegmentLabels={ customSegmentLabelsValuesEmpty }
              />
             </TableItem>
            <TableItem className={ classes.tableItem }>
              <Typography
                className={classes.tableItemDiagramText}
                position='relative'
                top='12px'
              > Credit Score </Typography>
              <ReactSpeedometer
                maxValue={100}
                value={ initialValues.creditScoreGraph > 0 ? initialValues.creditScoreGraph : 0}
                needleColor="#000"
                segments={3}
                valueTextFontSize='0'
                needleTransition={ 'easePolyIn' as Transition }
                width={ mainChartsWidth }
                height={ mainChartsHeight }
                segmentColors={ ['#e21b18', '#fad81b', '#78bd1f'] }
                customSegmentStops={ [0, 25, 50, 100] }
                customSegmentLabels={ customSegmentLabelsValuesEmpty }
              />
             </TableItem>
          </Grid>
        </Grid>
        <Typography sx={ {
          marginY: '4vh',
          fontStyle: 'italic',
          padding: '20px',
          lineHeight: 1.3,
          fontSize: '16px',
          fontWeight: '300'
        } } textAlign="center">
          Your qualification odds and home affordability calculator are an estimate
          of what you could afford and are provided as help-self tools for illustrative
          purposes only and are not guaranteed as to their applicability or
          accurancy in regard to your individual circumstances.
        </Typography>
      </Grid>
    </>
  )
}
