import { Box, Typography } from '@mui/material'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styled from 'styled-components'

interface IUnit {
  title: string;
  img: string;
  name: string;
  phone: string;
  email: string;
  isLender?: boolean;
}

const ImageHolder = styled(({ ...props }) => <div { ...props } />)`
  min-width       : 100px;
  min-height      : 100px;
  max-width       : 150px;
  max-height      : 150px;
  width           : 150px;
  height          : 150px;
  background      : url(${props => props.userimage}) 100% 100% no-repeat;
  background-size : contain;
  border-radius: 50%;
`

const ImageLenderHolder = styled(({ ...props }) => <div { ...props } />)`
  min-width       : 100px;
  min-height      : 100px;
  max-width       : 200px;
  max-height      : 125px;
  width           : 200px;
  height          : 125px;
  background      : url(${props => props.userimage}) 100% 50% no-repeat;
  background-size : contain;
`

const useStyles = makeStyles({
  title: {
    display: 'inline',
    fontSize: '21px',
    fontWeight: 'bold',
    marginBottom: '25px',
    textTransform: 'uppercase',
    color: 'rgb(237,31,138)'
  },
  img: {
    width: '150px',
    marginBottom: '15px'
  },
  name: {
    display: 'inline',
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '15px',
    marginBottom: '10px'
  },
  phone: {
    display: 'inline',
    fontSize: '14px',
    marginBottom: '5px',
    color: '#999'
  },
  email: {
    display: 'inline',
    fontSize: '14px',
    marginBottom: '5px',
    color: '#999'
  }
})

export const YourSuccessTeamBlock = (props: IUnit) => {
  const { title, img, name, phone, email, isLender } = props
  const classes = useStyles()
  return (
    <>
      <Box
        sx={ {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: '#fff',
          position: 'relative',
          padding: '10px 15px',
          borderRadius: '5px',
          marginBottom: '20px'
        } }
        color='primary'
      >
        <Typography className={ classes.title }>
          { title }
        </Typography>
        {
          name && img && (isLender ? <ImageLenderHolder userimage={ img } /> : <ImageHolder userimage={ img } />)
        }

        <Typography className={ classes.name }>
          { name || 'Not Assigned Yet' }
        </Typography>

        <Typography className={ classes.phone }>
          { phone }
        </Typography>

        <Typography className={ classes.email }>
          { email }
        </Typography>
      </Box>
    </>
  )
}
