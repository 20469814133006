import { AzureCommunicationTokenCredential, CommunicationUserIdentifier } from '@azure/communication-common'
import {
  ChatComposite,
  AvatarPersonaData,
  CompositeLocale,
  fromFlatCommunicationIdentifier,
  useAzureCommunicationChatAdapter
} from '@azure/communication-react'
import { PartialTheme, Theme } from '@fluentui/react'
import React, { useMemo } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import hqlighticon from '../../../assets/images/HomeQualIcon/hqlogolight.png'
import styled from '@emotion/styled'
import { closeMemberChatQuery, closeVisitorChatQuery } from '../../../shared/user/userQueries'
import useCurrentData from '../../../shared/hooks/useCurrentData'

export type ContainerProps = {
  userIdentifier: string;
  token: string;
  displayName?: string;
  endpointUrl: string;
  threadId: string;
  closeHandler: any;
  fluentTheme?: PartialTheme | Theme;
  errorBar?: boolean;
  participants?: boolean;
  topic?: boolean;
  locale?: CompositeLocale;
  formFactor?: 'desktop' | 'mobile';
};

export const ChatContainer = (props: ContainerProps): JSX.Element => {
  const credential = useMemo(() => {
    try {
      return new AzureCommunicationTokenCredential(props.token)
    } catch {
      console.error('Failed to construct token credential')
      return undefined
    }
  }, [props.token])

  const onFetchAvatarPersonaData = (userId: any): Promise<AvatarPersonaData> =>
    new Promise((resolve) => {
      return resolve({
        imageInitials: '',
        initialsColor: 'transparent'
      })
    })

  const userId = useMemo(
    () => fromFlatCommunicationIdentifier(props.userIdentifier) as CommunicationUserIdentifier,
    [props.userIdentifier]
  )

  const { currentData } = useCurrentData()

  const adapter = useAzureCommunicationChatAdapter({
    endpoint: props.endpointUrl,
    userId,
    displayName: currentData?.firstName ? `${currentData?.firstName} ${currentData?.lastName}` : ' ',
    credential,
    threadId: props.threadId
  })

  const ImageHolder = styled(({ ...props }) => <div {...props} />)`
    width: 100px;
    height: 35px;
    margin-bottom: 10px;
    background: url(${(props) => props.logo}) 100% 50% no-repeat;
    background-position-x: right;
    -webkit-background-size: contain;
    background-size: contain;
  `

  const authToken = sessionStorage.getItem('authToken')

  if (adapter) {
    return (
      <div
        className='hq-chat'
        style={{
          border: '1px solid rgb(237,31,138)',
          height: '50vh',
          width: '300px',
          position: 'fixed',
          top: '30%',
          right: '5px',
          paddingTop: '35px',
          backgroundColor: '#fff'
        }}>
        <div
          style={{
            position: 'absolute',
            top: '0px',
            fontWeight: '700',
            fontSize: '17px',
            padding: '5px',
            color: 'rgb(237,31,138)'
          }}
        >
          <ImageHolder logo={hqlighticon} />
        </div>
        <IconButton
          style={{ right: '0', position: 'absolute', top: '0', zIndex: '5' }}
          edge="start"
          color="primary"
          onClick={ () => {
            props.closeHandler(false)
            if (authToken) {
              closeMemberChatQuery(
                currentData?.memberId,
                props.userIdentifier,
                props.threadId,
                `${currentData.firstName} ${currentData.lastName}`
              )
                .then((response: any) => {
                })
                .catch((err: any) => {
                  console.error(err)
                })
            } else {
              closeVisitorChatQuery(props.userIdentifier, props.threadId)
                .then((response: any) => {
                })
                .catch((err: any) => {
                  console.error(err)
                })
            }
          } }
        >
          <CloseIcon sx={ { transform: 'scale(1.4)' } }/>
        </IconButton>
        <ChatComposite
          adapter={adapter}
          fluentTheme={props.fluentTheme}
          options={{
            errorBar: props.errorBar,
            topic: false
          }}
          onFetchAvatarPersonaData={onFetchAvatarPersonaData}
        />
      </div>
    )
  }
  if (credential === undefined) {
    return <>Failed to construct credential. Provided token is malformed.</>
  }
  return <>Initializing...</>
}
