import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { CenteredTypography } from '../../../shared/styles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircularProgress from '@mui/material/CircularProgress'
import { getSystemGlobalsPhone, userForgotYourQuery } from '../../../shared/user/userQueries'
import { phoneFormat } from '../../../shared/functions'

const useStyles = makeStyles({
  textOrEmailButton: {
    marginBottom: '15px',
    borderColor: 'rgb(237, 31, 138)',
    border: '1px solid',
    minWidth: '300px',
    width: '50vw',
    maxWidth: '600px',
    padding: '15px 10px',
    borderRadius: '30px'
  },
  notchedOutline: {
    borderColor: '#ccc !important'
  },
  loader: {
    marginLeft: '10px',
    width: '21px !important',
    height: '21px !important'
  }
})

export const ForgotYourPassword = () => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [isShowLoader, setIsShowLoader] = useState(false)
  const [resultText, setResultText] = useState('')
  const [phone, setPhone] = useState<any>(false)
  const handlerEmailChange = (e: any) => {
    setEmail(e.target.value)
  }
  const handlerClick = (email: string) => {
    setIsShowLoader(true)
    const params = {
      email
    }
    userForgotYourQuery(params)
      .then((response: any) => {
        if (response.data.errorId > 0) {
          setResultText(`We cannot find a HomeQual account for this email address. Please try again. \nIf you cannot remember the email address you used to sign up, give us a call at ${phoneFormat(phone) || '123 456-78-90'} and we can get you up and running.`)
        } else {
          setResultText('Your credentials will be sent to your email address')
        }
        setIsShowLoader(false)
      })
      .catch((err: any) => {
        setIsShowLoader(false)
        setResultText(`We cannot find a HomeQual account for this email address. Please try again. \nIf you cannot remember the email address you used to sign up, give us a call at ${phoneFormat(phone) || '123 456-78-90'} and we can get you up and running.`)
        console.error(err)
      })
  }

  useEffect(() => {
    if (!phone) {
      getSystemGlobalsPhone()
        .then((response: any) => {
          setPhone(response.data.tollFreePhone)
        })
        .catch((err: any) => {
          console.error(err)
        })
    }
  }, [phone])

  return (
    <>
      <Box
        sx={ {
          height: '100%',
          width: '100%',
          top: 0,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: '#fff',
          opacity: '0.9',
          padding: '10px 0'
        } }
        color="primary"
      >
        <CenteredTypography variant="h5">
          <Typography
            marginBottom='25px'
            fontSize='46px'
            fontWeight='bold'
            textAlign="center"
            color="#000"
            sx={ {
              '@media screen and (max-width: 500px)': {
                fontSize: '36px'
              }
            } }
          >
            Forgot Your User Information?
          </Typography>
        </CenteredTypography>
        <CenteredTypography variant="h5">
          <TextField
            onChange={ handlerEmailChange }
            variant='outlined'
            color='primary'
            name='login'
            autoComplete='no'
            InputProps={ {
              style: { fontSize: '1.4rem', fontWeight: 'bold' },
              classes: {
                notchedOutline: classes.notchedOutline
              }
            } }
            InputLabelProps={ { sx: { '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 } } } }
            sx={ {
              minWidth: '300px', maxWidth: '600px', width: '50vw', margin: '10px 0 5px 0'
            } }
            label={
              <Typography variant="h6" color="#ccc" sx={ {
                fontSize: '15px !important', fontStyle: 'italic', fontWeight: 'regular'
              } }>
                E-mail
              </Typography>
            }
          />
        </CenteredTypography>
        <CenteredTypography variant="h5">
          <Button
            disabled={ isShowLoader }
            type="submit"
            className={classes.textOrEmailButton}
            size="large"
            onClick={ () => handlerClick(email) }
          >
            send credentials
            {
              isShowLoader && <CircularProgress className={ classes.loader } />
            }
          </Button>
        </CenteredTypography>
        <CenteredTypography variant="h6" maxWidth='900px' whiteSpace='pre-wrap'>
          { resultText }
        </CenteredTypography>
      </Box>
    </>
  )
}
