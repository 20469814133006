import { basicData, InputField } from '../shared'
import React, { FC, memo, useEffect, useState } from 'react'
import { Wrapper } from '../../Wrapper'
import { Autocomplete, Grid, TextField, Typography } from '@mui/material'
import { ICreateTextFieldQuestion } from '../../../questions'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { getReferenceState } from '../../../../../../shared/user/userQueries'

interface IProps extends ICreateTextFieldQuestion, basicData {
}

interface IState {
  stateId: string
  description: string
}

export const CreateTextFieldQuestionWithState: FC<IProps> = memo(({
  inputs,
  wrapperProps,
  formik
}) => {
  const [states, setSates] = useState([])

  useEffect(() => {
    if (states.length === 0) {
      getReferenceState(formik.values.memberId)
        .then((response: any) => {
          const referenceStates = response.data.map((state: IState) => {
            return state.stateId
          })
          setSates(referenceStates)
        })
        .catch((err: any) => {
          console.error(err)
        })
    }
  }, [states])
  const styles = makeStyles(theme => ({
    notchedOutline: {
      borderColor: '#ccc !important'
    }
  }))

  const classes = styles()
  return (
        <Wrapper { ...wrapperProps }>

            <Grid container direction="column" sx={ { paddingLeft: '10vw' } }>

                { inputs.map((item, index: number) => {
                  return (
                    item.value === 'state' || item.value === 'refiStateId'
                      ? <Autocomplete id={ 'state' }
                        onChange={ (event, value) => formik.setFieldValue(item.value, value) }
                        options={ states }
                        fullWidth
                        renderOption={ (option: any) => (
                            <Typography { ...option } style={ {
                              fontSize: '1rem'
                            } }>{ option.key }</Typography>
                        ) }
                        sx={ {
                          minWidth: '100px',
                          maxWidth: '600px',
                          height: '100%',
                          marginBottom: '10px',
                          boxSizing: 'border-box',
                          paddingRight: '100px',
                          '@media(max-width: 768px)': {
                            paddingRight: '10vw'
                          }
                        } }
                        renderInput={ (params) => (
                            <TextField { ...params }
                               InputLabelProps={ {
                                 sx: {
                                   '&.MuiInputLabel-shrink': { marginTop: '-10px', zIndex: 1 }
                                 }
                               } }
                               inputProps={ {
                                 ...params.inputProps as any,
                                 style: { fontSize: '1.4rem', fontWeight: 'bold' }
                               } }
                               name={ item.value }
                               variant="outlined"
                               color="primary"
                               onBlur={ formik.handleBlur }
                               InputProps={ {
                                 ...params.InputProps as any,
                                 style: {
                                   paddingTop: 5,
                                   paddingRight: 50,
                                   fontSize: '1.4rem',
                                   fontWeight: 'bold'
                                 },
                                 classes: {
                                   notchedOutline: classes.notchedOutline
                                 }
                               } }
                               label={ <Typography variant="h6" color="#999" sx={ {
                                 fontSize: '15px !important',
                                 fontStyle: 'italic',
                                 fontWeight: 'regular'
                               } }>
                                   State
                               </Typography> }
                               sx={ { mt: 1 } }/>) }/>
                      : <InputField formik={ formik }
                          item={ item }
                          key={ index }
                          index={ index }
                        />
                  )
                })
                }
            </Grid>
        </Wrapper>
  )
})
